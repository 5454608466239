
import "bootstrap/dist/css/bootstrap.min.css";
import '../Pulley/styles.css'
import logo from '../../../assets/partner-assest/prembly-logo (1).svg'
import native from '../../../assets/partner-assest/native.svg'
import calendar from '../../../assets/partner-assest/calendar-2.png'
import percentage from '../../../assets/partner-assest/percentage-circle.png'
import close from '../../../assets/partner-assest/close.svg';




const Header = () =>{ 

  return (
    <div className="app">
      <div className="left-section "  >

        <div className="header-logo" >
          <img
            className="logo-icon1"
            loading="lazy"
            alt=""
            src={logo}
            style={{height:"32px"}}
          />
          <img
            className="close-icon"
            loading="lazy"
            alt=""
            style={{height:"24px"}}
            src={close}
          />
          <img
            className="percentagecircle-icon"
            loading="lazy"
            alt=""
            style={{height:"32px"}}
            src={native}
          />
        </div>
        <h1 className="compliance">
          Compliance and Security <br/>for Emerging Markets
        </h1>
        <h3 className="customers-enjoy">
          Native Teams customers enjoy discounted access to our background check, ID
          verification, fraud prevention, form
          building, and global compliance tools.
        </h3>

        <div className="side-by-side">


          <div className="boxss">
            <div className="box"> 
            
                 
              <img
                className="percentage-circle-icon"
                loading="lazy"
                alt=""
                src={percentage}
              />
            </div>
            <div className="off-all-prembly">
              10% off all Prembly products       
            </div>
          </div>
          
          <div className="boxss2">
            <div className="box">      
              <img
                className="percentage-circle-icon"
                loading="lazy"
                alt=""
                src={calendar}
              />
            </div>
            <div className="off-all-prembly">
              Valid for 12 months
            </div>
    
          </div>
        </div>


  </div>
<div className="new">
<a
    className=" submit-btn btn btn-sales2 signin-sales  py-3 me-lg-2 mb-lg-0 mt-4"
                  href="https://forms.prembly.com/view/85eacd2c-1f73-45df-ba38-2b8cb44e3274"
                  target="_blank"
                  rel="noreferrer"
                  id=""
                  style={{fontSize:"18px" }}
    >
       Claim your Discounts
 </a>
</div>
    </div>

  );
  
}


export default Header;