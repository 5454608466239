import React, { useState } from "react";
import NavbarArea from "../../../components/navbar";
import ReactElasticCarousel from "react-elastic-carousel";
import Footer from "../../../components/footer";
import { identitys } from "../../../components/datas";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LatestF from "../../allProduct/LatestF";
import { identityT } from "../../../components/datas";
import BlueCard from "../../allProduct/BlueCard";
import { Link } from "react-router-dom";
import allImg from "../../../assets/allLogo.png";
import { Helmet } from "react-helmet";

export default function IdentityForm(props) {
  const [arrowDirection, setArrowDirection] = useState(1);

  const arrowAngleRight = () => {
    setArrowDirection(2);
  };

  const arrowAngleLeft = () => {
    setArrowDirection(1);
  };
  return (
    <div style={{ background: "#ffffff" }}>
      <Helmet>
        <title>Prembly- IdentitfyForm</title>
        <meta
          name="description"
          content="Event check-in automation in Nigeria"
        />
      </Helmet>
      <NavbarArea colouredButton="bluelights" />
      <div className="whiteBg"></div>
      <div className="hero-section  identitypassHero">
        <div className="container-fluid py-5">
          <div className="row align-items-center justify-content-between idherocolor">
            <div className="col-md-12 col-lg-6">
              <div className="d-flex align-items-center">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/newform_logo.png"
                  alt=""
                  className="homelogosid"
                />
                <a
                  className="btn btn-lights px-md-2 px-4 py-2 mt-0 "
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  IdentityForm
                </a>
              </div>
              <h1 className="py-3">
                Create the most secure data collection forms
              </h1>
              <h5 className="mt-3">
                A drag and drop form builder, built for global business and
                designed to swiftly and accurately verify data.
              </h5>

              <div className="d-flex align-items-center">
                <a
                  className="btn btn-bluelight  me-1"
                  href="https://forms.office.com/r/DS5VzC5K3i"
                  target="_blank"
                  rel="noreferrer"
                >
                  Join the waitlist
                </a>
                <Link
                  to="/contact"
                  className="btn btn-blacklight px-md-4 py-3 mt-3"
                >
                  <span>Talk to Sales</span>
                  <i class="ri-arrow-right-line ms-2"></i>
                </Link>
              </div>
            </div>
            <div className="col-md-12 col-lg-5 ">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/id-form.png"
                alt=""
                className="w-100"
              />
            </div>
          </div>
        </div>
      </div>
      <section
        id="partners"
        className="loved-by-section pt-5  text-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="">
          <div>
            <h4>We are trusted by all top financial institutions</h4>

            <div className="marquee">
              <div className="marquee-content">
                <ul>
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>

                <ul>
                  {" "}
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>
                <ul>
                  {" "}
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="countries-section py-5">
        <div className="container">
          <div className="col-lg-12 mx-auto">
            <div className="row align-items-center mx-auto justify-content-center countries-gap code-countries">
              <div className="col-md-12 col-lg-6 countries-list ">
                <div className="global-color global-lightblue mb-4">
                  <p>Global</p>
                </div>
                <h1>Our solution cut across 100+ countries</h1>
                <h5 className="mt-3 mb-4">
                  Prembly's extensive range of over 100 APIs can be utilised by
                  businesses worldwide to enhance their identity verification
                  and authentication processes. Our dedicated teams, located
                  globally, are ready to assist you and your customers wherever
                  your operations may be
                </h5>

                <div className="countriesFlag">
                  <img
                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/countries.svg"
                    alt=""
                  />
                </div>

                <div className="d-flex align-items-center">
                  <Link
                    to="/about"
                    className="btn btn-bluelight mt-4 me-1"
                    rel="noreferrer"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
              <div className="col-md-12 col-lg-5 worlds">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/world-new.gif"
                  alt=""
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="feedback" className="feedback-prembly-area">
        <div className="container">
          <div className="col-md-12 col-lg-11 mx-auto py-lg-5 text-center">
            <h1>Our Customers Perspective</h1>
            {arrowDirection === 1 && (
              <div className=" d-flex align-items-center py-5 ">
                <div className="showflex row ">
                  {identitys.map((data) => (
                    <div className="col-md-12 col-lg-4">
                      <BlueCard
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                        allColoredCard=""
                      />
                    </div>
                  ))}
                </div>
                <img
                  onClick={arrowAngleRight}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindright.svg"
                  alt=""
                  className="ms-2"
                />
              </div>
            )}

            {arrowDirection === 2 && (
              <div className=" d-flex align-items-center py-5 ">
                <img
                  onClick={arrowAngleLeft}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindleft.svg"
                  alt=""
                  className="me-2"
                />

                <div className="showflex row ">
                  {identityT.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      <BlueCard
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                        className="newblue"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <LatestF updateBg="bluelightbg" />
      <Footer />
    </div>
  );
}
