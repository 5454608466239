import React from "react";
import { tv } from 'tailwind-variants';

const ButtonStyle = tv({
  base: 'tw-flex tw-items-center tw-justify-center tw-p-[0.75rem] tw-rounded-[0.5rem] tw-font-[600] tw-font-generalSansSenmiBold tw-text-[1rem]',
  variants: {
    color: {
      green: 'tw-bg-primary40 tw-text-[#FFF]',
      white: 'tw-text-primary40 tw-bg-white',
      grey: 'tw-text-primary40 tw-bg-[#F4F4F4]',
    }
  },
  defaultVariants: {
    color: 'green',
  }
});

const Button = ({ children, className = '', disabled = false, onClick = (e) => {}, style = {}, ...rest }) => {
  return (
    <button 
      disabled={disabled}
      onClick={onClick}
      className={ButtonStyle({ ...rest, class: className })} 
      style={style}
    >
      {children}
    </button>
  );
};

export default Button;
