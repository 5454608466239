import React from "react";
// import Footer from "../../components/footer";
import Footer from "../../components/newNavbar/Footer";
import NavbarArea from "../../components/navbar";
import LatestF from "../allProduct/LatestF";
import { useState } from "react";
import gets from "../../assets/gets.svg";
import toast, { Toaster } from "react-hot-toast";
import { emailValidate } from "../../components/emailValidator";
import axios from "axios";
import { contactUrl } from "../../components/contactUsData";
import { Helmet } from "react-helmet";
import NavBar2 from "../../components/newNavbar/NavBar";

export default function Contact() {
  const [activeFaq, setActiveFaq] = useState(3);
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [business_name, setBusinesName] = useState("");
  const [pubRef, setPubRef] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [register, setRegister] = useState

  const showFaqs = () => {
    setActiveFaq((prevValue) => prevValue + 3);
  };

  const sendContactDetails = async (data) => {
    setIsLoading(true);
    setSuccess(false);
    setBusinesName("");
    setFullName("");
    setMessage("");
    setEmail("");
    try {
      const res = await axios.post(contactUrl, data);
      // console.log(res.data);
      setSuccess(true);
    } catch (error) {
      // console.log(error);
      toast("an error occured");
    } finally {
      setIsLoading(false);
      // console.log("done");
    }
  };

  // const dispatch = useDispatch();

  let sendMessage = (e) => {
    e.preventDefault();
    let validateEmail = emailValidate(email);
    if (!fullname) {
      toast.error("please enter your Full name");
      return;
    } else if (!validateEmail.status) {
      // console.log(validateEmail);
      toast.error(validateEmail.message);
      return;
    } else if (!business_name) {
      toast.error("Work place cannot be empty");
      return;
    } else {
      let data = {
        email,
        fullname,
        message,
        publication_ref: pubRef,
        business_name,
      };
      toast.success("Email successfully");
      sendContactDetails(data);
      // console.log("Contact");
      // dispatch(dataValidate(data))
      //   .then(() => {
      //     (true);
      //   })
      //   .catch(() => {
      //     toast.error(sendPublicationState.error);
      //   });

      // setButtonClick(data);
    }
  };
  return (
    <div>
      <Helmet>
        <title>Prembly- ContactUs</title>
      </Helmet>
      {/* <NavbarArea /> */}
      <NavBar2 />
      <Toaster />
      <section className="getInTouch py-5">
        <div className="container">
          <div className="col-md-12 col-lg-10 mx-auto mb-5 py-lg-5">
            <div className="row form-contents align-items-center ">
              <div className="getinTouchHeader col-md-6 col-sm-5 px-5">
                <h1 className="mb-3">Get In Touch With Us.</h1>
                <p>
                  At Prembly, we’re making the internet secure for your business
                  with the right security and compliance tools.
                </p>
                <p>
                  Please complete the form below to connect with a member of our
                  team.
                </p>
              </div>

              <div className="getInTouchForms col-md-6 col-sm-5 px-5">
                <img src={gets} alt="" className="w-100" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="get-started-areas py-5">
        <div className="container">
          <div className="row align-items-center mx-auto  justify-content-center get-it-row">
            <div className="col-md-10 col-lg-5 contact-message">
              <div>
                <p>
                  Get in touch or create an account instantly and ensure your
                  business remains secure and compliant. You can also contact us
                  for a custom package for your business.
                </p>
              </div>
              <div className="getInTouchForms">
                <form method="post" onSubmit={sendMessage}>
                  <div className="row inputrows">
                    <input
                      type="text"
                      // required
                      placeholder="Full name"
                      className="mb-3 formholder"
                      // ref={fname}
                      value={fullname}
                      onChange={(fullname) =>
                        setFullName(fullname.target.value)
                      }
                    />
                    <input
                      type="text"
                      placeholder="Business name"
                      className="mb-3 formholder"
                      // required
                      // ref={bname}
                      value={business_name}
                      onChange={(business_name) =>
                        setBusinesName(business_name.target.value)
                      }
                    />
                    <input
                      type="email"
                      placeholder="Email"
                      className="mb-3 formholder"
                      // required
                      // ref={ename}
                      value={email}
                      onChange={(email) => setEmail(email.target.value)}
                    />
                    <textarea
                      type="text"
                      rows="12"
                      placeholder="How can we help you?"
                      className="formholder mb-5"
                      cols="50"
                      // ref={mname}
                      value={message}
                      onChange={(message) => setMessage(message.target.value)}
                    ></textarea>
                    {/*                    
                   {buttonClick === true? 
                    ( */}

                    <button type="submit" className="btn becomeateam">
                      {isLoading ? "loading..." : "Contact Us"}
                    </button>

                    <div className="messages">
                      {success && (
                        <div className="success" style={{ marginTop: "40px" }}>
                          Thanks for contacting us! Our team will reply soon.
                        </div>
                      )}

                      {/* <div className="danger">Filed Can't be Empty!</div> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="question py-5">
        <div className="container">
          <div className="col-md-10 col-lg-8 mx-auto text-center py-5">
            <h1 className="mb-5">Frequently Asked Questions.</h1>
            <div className="col-md-10 col-lg-12">
              {faqsdata.slice(0, activeFaq).map((value)=> (
              <div className="faq">
                <Accordion defaultActiveKey="0" className="">
                  <Accordion.Item eventKey="1" className="item">
                    <Accordion.Header>
                     {value.question}
                    </Accordion.Header>
                    <Accordion.Body>
                      {value.answer}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
                ))}
              
            </div>

            <div className="">
              <button
                className="btn btn-green1  me-1"
                onClick={showFaqs}
              >
               Load More
              </button>
            </div>
          </div>
        </div>
      </section> */}

      <LatestF />
      <Footer />
    </div>
  );
}
