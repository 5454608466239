import React from "react";
import Footer from "../../components/footer";
import NavbarArea from "../../components/navbar";
// import face from "../../assets/face.svg";
import insta from "../../assets/instagram.svg";
// import ins from "../../assets/ins.svg";
import twitter from "../../assets/twitter.svg"
import { Helmet } from "react-helmet";


export default function About() {
  return (
    <div>
      <Helmet>
        <title>Prembly- AboutUs</title>
        <meta
          name="description"
          content="Identity Verification Solutions for Emerging Markets"
        />
      </Helmet>
      <NavbarArea />
      <section className="about-us">
        <div className="container py-5 mt-5">
          <div className="col-md-10 col-lg-10 about-us-heading mx-auto">
            <h1 className="mb-4 text-center">
              Who we are and why it matters...
            </h1>
            <p> We’re a compliance and security infrastructure company. </p>
            <p>
              We build go-to, user-friendly AI powered Security Infrastructure
              and Compliance software for Identity Verification, Fraud
              Prevention/Detection market solutions, that helps digital
              businesses in emerging markets safely acquire, onboard customers,
              and perform seamless transactions across borders with trust &
              without restrictions.
            </p>
          </div>
        </div>
      </section>

      <section className="ourmission pb-5 ">
        <div className="container">
          <div className="col-md-9 mx-auto py-5">
            <div className="video-view-box">
              <img src='https://prembly-assets.s3.us-east-2.amazonaws.com/image%2089.svg' alt=''/>
            </div>
            <div className="viewsContainer">
              <div className="allviews">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/Badge.svg"
                  alt="badge"
                />
                <h5 className="mt-3">600+</h5> 
                <p>Daily Active businesses</p>
              </div>

              <div className="allviews">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/Badge1.svg"
                  alt="badge"
                />
                <h5 className="mt-3">2021</h5>
                <p>year established </p>
              </div>

              <div className="allviews">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/Badge2.svg"
                  alt="badge"
                />
                <h5 className="mt-3">$3M+ </h5>
                <p>Total Funding</p>
              </div>

              <div className="allviews">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/Badge3.svg"
                  alt="badge"
                />
                <h5 className="mt-3">100+</h5>
                <p>Countries</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ourmission-video py-5">
        <div className="container">
          <div className="col-md-10 col-lg-11 mx-auto ourmission-videos">
            <div className="row mx-auto justify-content-center  mission-content">
              <div className="col-md-10 col-lg-4" >
                <h1>Our Mission</h1>
                <p>
                  We are on a mission to safeguard digital businesses in emerging
                  markets to transact globally while building trust with customers.
                </p>
              </div>
              <div className="col-md-10 col-lg-4" >
                <h1>Our Vision</h1>
                <p>
                Our Vision is to build the most reliable and efficient compliance and
              security infrastructure products for emerging markets. 
                </p>
              </div>

              <div className="col-md-10 col-lg-4">
                <h1>Our Purpose</h1>
                <p>
                  To inspire an identity theft-free environment through data security &
                  improving verification processes using cutting-edge technologies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      
    
      <section className="cores mb-5 pt-5">
        <div className="container">
          <div className="col-lg-12 mx-auto">
            <div className="row align-items-center mx-auto justify-content-center coreContent ">
              <div className="col-md-10 col-lg-4">
                <h1>Our Core Values</h1>
                <p className="mt-4">Our vision is backed by values:</p>
                <div className="core-list pt-3">
                  <div className="core-items ">
                    <img
                      src="https://prembly-assets.s3.us-east-2.amazonaws.com/bulb.svg"
                      alt=""
                    />
                    <h5>Innovation</h5>
                  </div>
                  <div className="core-items">
                    <img
                      src="https://prembly-assets.s3.us-east-2.amazonaws.com/collaborate.svg"
                      alt=""
                    />
                    <h5>Respect</h5>
                  </div>

                  <div className="core-items">
                    <img
                      src="https://prembly-assets.s3.us-east-2.amazonaws.com/achievements.svg"
                      alt=""
                    />
                    <h5>Excellence</h5>
                  </div>

                  <div className="core-items">
                    <img
                      src="https://prembly-assets.s3.us-east-2.amazonaws.com/ecolight.svg"
                      alt=""
                    />
                    <h5>Sustainability</h5>
                  </div>

                  <div className="core-items">
                    <img
                      src="https://prembly-assets.s3.us-east-2.amazonaws.com/investigation.svg"
                      alt=""
                    />
                    <h5>Transparency</h5>
                  </div>

                  <div className=" mt-2 col-md-12 col-lg-12">
                    <h4 className="">Connect with Us</h4>
                    <p className=" socials mt-3 pb-5">
                      <>
                        <a
                          href="https://www.facebook.com/Premblydotmind"
                          className="link me-2"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src="https://prembly-assets.s3.us-east-2.amazonaws.com/face.svg"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://twitter.com/myidentitypass"
                          className="link me-2"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={twitter}
                            alt=""
                          />
                        </a>
                        <a
                          href="https://www.instagram.com/premblyhq/"
                          className="link me-2"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={insta} alt="" />
                        </a>
                        <a
                          href="https://www.linkedin.com/company/prembly/-"
                          className="link me-2"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src="https://prembly-assets.s3.us-east-2.amazonaws.com/ins.svg"
                            alt=""
                          />
                        </a>
                      </>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-10 col-lg-4 coreimg">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/together.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="havefun py-5">
        <div className="container">
          <div className="col-md-10 col-lg-8 align-items-center mx-auto  py-5">
            <div className="text-center funHeader">
              <h1>Our Events</h1>
            </div>

            <div className="row align-items-center mx-auto justify-content-center pt-5 gx-1">
              <div className="col-md-10 col-lg-4 align-items-center mx-auto justify-content-center funimgs">
                <div className="insta-img">
                <a
                  // href="https://www.instagram.com/p/CnNAkKEDH9e/"
                  target="_blank"
                >
                  <img
                    src='https://prembly-assets.s3.us-east-2.amazonaws.com/instae.svg'
                    className="w-100 "
                    alt=""
                  />
                  <div className="overlay"></div>
                </a>
                </div>
              
                <div className="insta-img">
                  <a
                    // href="https://www.instagram.com/p/CmoDswyMU4r/"
                    target="_blank"
                  >
                    <img
                      src='https://prembly-assets.s3.us-east-2.amazonaws.com/instaevent.svg'
                      className="w-100"
                      alt=""
                    />
                    <div className="overlay"></div>
                  </a>
                </div>
              </div>

              <div className="col-md-10 col-lg-4 align-items-center mx-auto justify-content-center funimgs">
              <div className="insta-img">
                <a
                  // href="https://www.instagram.com/p/CmwWZAnsA7D/"
                  target='_blank'
                >
                <img
                  src='https://prembly-assets.s3.us-east-2.amazonaws.com/instaa.svg'
                  alt=""
                  className="w-100"
                />
                <div className="overlay"></div>
                </a>
                </div>
                <div className="insta-img">
                <a
                  //  href="https://www.instagram.com/p/Cmt4eXMDovi/"
                   target='_blank'
                >

                <img src='https://prembly-assets.s3.us-east-2.amazonaws.com/instaev.svg'
                alt=""
                className="w-100"
                
              />
              <div className="overlay"></div>
                </a>
                </div>
              </div>


              <div className="col-md-10 col-lg-4 align-items-center mx-auto justify-content-center funimgs">
              <div className="insta-img">
                  <a
                      // href="https://www.instagram.com/p/Cm3ZAdHM819/"
                      target='_blank'
                  >
                  <img
                    // src="https://prembly-assets.s3.us-east-2.amazonaws.com/insta1.svg"
                    src='https://prembly-assets.s3.us-east-2.amazonaws.com/instaevn.svg'
                    className="w-100"
                    alt=""
                  />
                  <div className="overlay"></div>
                  </a>
                </div>
                <div className="insta-img">
                  <a
                    href="https://www.instagram.com/p/CmZMmdyDak-/"
                    target='_blank'
                  >
                  <img
                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/insta6.svg"
                    className="w-100"
                    alt=""
                  />
                  <div className="overlay"></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <LatestF /> */}
      <Footer />
    </div>
  );
}
