import React from "react";
import twitter from "../../newassets/twit.svg";
import facebook from "../../newassets/fb.svg";
import youtube from "../../newassets/yt.svg";
import instagram from "../../newassets/ins.svg";
import prembly_logo from "../../newassets/Logo (1).svg";
import copyright from "../../newassets/copyright.svg";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import cookie from "../.././assets/cookie.svg";
import { Button, Modal } from "antd";
import { useEffect, useState } from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  let location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let setAllCookieConsent = () => {
    Cookies.set("allCookieConsent", "accepted");
    handleCancel();
  };

  let setNecessaryCookieConsent = () => {
    Cookies.set("necessaryCookieConsent", "accepted");
    handleCancel();
  };

  useEffect(() => {
    const cookieConsent =
      Cookies.get("allCookieConsent") ||
      Cookies.get("necessaryCookieConsent") ||
      "";
    cookieConsent === "" ? setIsModalOpen(true) : setIsModalOpen(false);
  }, []);

  return (
    <div className="f-c my-5">
      <footer className="container footer-container">
        <div className="container p-4">
          <div className="row mt-4 justify-content-between footer">
            {/* ABOUT COMPANY */}
            <div className="col-lg-3 col-md-6 mb-4 mb-md-0 ft-links">
              <a href="/">
                {" "}
                <img src={prembly_logo} alt="prembly-logo" className="mb-2" />
              </a>
              <h5 className="mb-2">Powering Greatness</h5>
              <span>Washington DC, USA</span>
              <div className="social-icons mt-3">
                <a
                  href="https://x.com/prembly"
                  className="social-icon"
                  target="_blank"
                >
                  <img src={twitter} alt="twitter" className="social-img" />
                </a>
                <a
                  href="https://www.instagram.com/premblyinc/"
                  className="social-icon"
                  target="_blank"
                >
                  <div className="social-img">
                    <img src={instagram} alt="instagram" />
                  </div>
                </a>
                <a
                  href="https://www.facebook.com/PremblyInc/"
                  className="social-icon"
                  target="_blank"
                >
                  <img src={facebook} alt="facebook" className="social-img" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UC7g392Npuv11LEqlqQr-tXg"
                  className="social-icon"
                  target="_blank"
                >
                  <img src={youtube} alt="youtube" className="social-img" />
                </a>
              </div>
              {/* <div className="copyw mt-3">
              <img src={copyright} style={{ marginRight: '4px' }} />
                <p className=" copyright-text">
                 
                  Prembly Inc {currentYear}
                </p>
              </div> */}
            </div>

            {/* For business */}
            <div className="col-lg-2 col-md-6 mb-4 mb-md-0">
              <h5 className="mb-2">For Businesses</h5>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <a
                    className="footer-links"
                    href="https://identityforms.prembly.com"
                    target="_blank"
                  >
                    <span>IdentityForms</span>
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    className="footer-links"
                    href="https://identityradar.prembly.com/"
                    target="_blank"
                  >
                    <span>IdentityRadar</span>
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://idpass.prembly.com/"
                    target="_blank"
                    className="footer-links"
                  >
                    <span>IdentityPass</span>
                  </a>
                </li>
                {/* <li className="mb-2">
                <Link to="/backgroundCheck" className="footer-links" >
                    <span>Background Checks</span>
                  </Link>

                </li> */}
                <li className="mb-2">
                  <a
                    href="https://backgroundcheck.prembly.com/"
                    className="footer-links"
                    target="_blank"
                  >
                    <span>BackgroundChecks</span>
                  </a>
                </li>

                <li className="mb-2">
                  <a
                    href="https://compliance.prembly.com"
                    className="footer-links"
                    target="_blank"
                  >
                    <span>Compliance Tracker</span>
                  </a>
                </li>
              </ul>
            </div>
            {/* INDIVIDUALS */}
            <div className="col-lg-2 col-md-6 mb-4 mb-md-0">
              <h5 className="mb-2">For Individuals</h5>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <a className="footer-links" href="#" target="_blank">
                    <span>NISES </span>
                    <span
                      style={{ fontSize: "13px", color: "#cdcdcd" }}
                      className="me-2"
                    >
                      (coming soon)
                    </span>
                  </a>
                </li>
                <li className="mb-2">
                  <a className="footer-links" href="#" target="_blank">
                    <span>Evento </span>
                    <span
                      style={{ fontSize: "13px", color: "#cdcdcd" }}
                      className="me-2"
                    >
                      (coming soon)
                    </span>
                  </a>
                </li>
              </ul>
            </div>

            {/* RESOURCES */}
            <div className="col-lg-2 col-md-6 mb-4 mb-md-0">
              <h5 className="mb-2">Resources</h5>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <a
                    href="https://blog.prembly.com"
                    className="footer-links"
                    target="_blank"
                  >
                    <span>Blogs</span>
                  </a>
                </li>
                <li className="mb-2">
                  <Link to="/event" className="footer-links" rel="noreferrer">
                    <span>Events</span>
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/press" className="footer-links" rel="noreferrer">
                    <span>Press and Media</span>
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/faqs" className="footer-links" rel="noreferrer">
                    <span>FAQS</span>
                  </Link>
                </li>
                <li className="mb-2">
                  <a
                    href="https://docs.prembly.com/docs/welcome-to-prembly-documentation"
                    className="footer-links"
                    target="_blank"
                  >
                    <span>API Documentation</span>
                  </a>
                </li>
                <li className="mb-2">
                  <Link to="/bolt" className="footer-links">
                    <span>Bolt Verification</span>
                  </Link>
                </li>
              </ul>
            </div>

            {/* COMPANY */}
            <div className="col-lg-2 col-md-6 mb-4 mb-md-0">
              <h5 className="mb-2">Company</h5>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <Link to="/about" className="footer-links" rel="noreferrer">
                    <span>About</span>
                  </Link>
                </li>

                <li className="mb-2">
                  <Link to="/career" className="footer-links" rel="noreferrer">
                    <span>Careers</span>
                  </Link>
                </li>

                <li className="mb-2">
                  <Link to="/contact" className="footer-links" rel="noreferrer">
                    <span>Contact Us</span>
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/partnership"
                    className="footer-links"
                    rel="noreferrer"
                  >
                    <span>Partner with Us</span>
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/story" className="footer-links" rel="noreferrer">
                    <span>Our Story</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mb-4 mb-md-0 ft-link">
            <img src={prembly_logo} alt="prembly-logo" />
            <h5 className="mt-3">Powering Greatness</h5>
            <p>Washington DC, USA</p>
            <div className="social-icon-small mt-3">
              <a
                href="https://x.com/prembly"
                className="social-icon"
                target="_blank"
              >
                <img src={twitter} alt="twitter" className="social-img" />
              </a>
              <a
                href="https://www.instagram.com/premblyinc/"
                className="social-icon"
                target="_blank"
              >
                <div className="social-img">
                  <img src={instagram} alt="instagram" />
                </div>
              </a>
              <a
                href="https://www.facebook.com/PremblyInc/"
                className="social-icon"
                target="_blank"
              >
                <img src={facebook} alt="facebook" className="social-img" />
              </a>
              <a
                href="https://www.youtube.com/channel/UC7g392Npuv11LEqlqQr-tXg"
                className="social-icon"
                target="_blank"
              >
                <img src={youtube} alt="youtube" className="social-img" />
              </a>
            </div>
            <div className="footer-prembly">
              {/* <div className="copyw mt-3">
              <img src={copyright} style={{ marginRight: '4px', marginTop: '4px'}} />
                <p className=" copyright-text">
                 
                  Prembly Inc {currentYear}
                </p>
              </div> */}
            </div>
          </div>
          <br />
          <hr />
          <div className=" mb-4 mb-md-0 privacy">
            <ul className="list-unstyled privacy">
              <li className="mb-2">
                <Link to="/Policy" className="footer-links" rel="noreferrer">
                  <span>Privacy</span>
                </Link>
              </li>

              <li className="mb-2">
                <Link to="/terms" className="footer-links" rel="noreferrer">
                  <span>Terms of Use</span>
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/dataProtection"
                  className="footer-links"
                  rel="noreferrer"
                >
                  <span>Data Protection</span>
                </Link>
              </li>

              <li className="mb-2" style={{ marginTop: "-10px" }}>
                <a
                  className="privacy"
                  onClick={showModal}
                  style={{ cursor: "pointer" }}
                >
                  <span>Manage Cookies</span>
                </a>
                <>
                  <Modal
                    open={isModalOpen}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <div className="cookie-box">
                      <img src={cookie} alt="cookie" />
                      <p>
                        By clicking “Accept all cookies”, you agree Prembly can
                        store cookies on your device and disclose information in
                        accordance with our{" "}
                        <Link to="/Policy" className="cookie-link">
                          Cookie Policy
                        </Link>
                        .
                      </p>
                      <div className="cookie-btn">
                        <button
                          className="btn btn-green1"
                          onClick={setAllCookieConsent}
                        >
                          Accept all cookies
                        </button>
                        <button
                          href=""
                          className="btn btn-green2 btn-green-outline"
                          onClick={setNecessaryCookieConsent}
                        >
                          Necessary cookies only
                        </button>
                      </div>
                      <button className="btn btn-greennew btn-green-outline">
                        Customize settings
                      </button>
                    </div>
                  </Modal>
                </>
              </li>

              <li className=" mb-2">
                <span>© Prembly Inc {currentYear}</span>
              </li>
            </ul>
          </div>
          <hr />
        </div>
      </footer>
    </div>
  );
};

export default Footer;
