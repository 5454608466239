import React, { useEffect, useState } from "react";
import homepageImg from "../../newassets/homepageImg.svg";
import Slider from "./Slider";
import NavBar from "./NavBar";
import AnimatedText from "./TextAnimation";
import { FloatingWhatapp } from "./FloatingWhatapp";
import { Container, Row, Col, Button } from "react-bootstrap";
import jobber from "../../assets/pre-laandi.gif";

export const infrastructureItems = [
  "Identity Verification",
  "KYC",
  "AML",
  "Fraud Detection",
  "Biometric Authentication",
  "Document Verification",
  "Address Verification",
  "Age Verification",
  "Background Checks",
  "Compliance Management",
];

{
  /* <section className="py-4 px-3">
  <Row className="d-flex justify-content-around align-items-center">
    <Col md={7} className="text-left d-flex flex-column justify-content-center">
      <Container className="py-3">
        <h2 className="text-center">A Data Infrastructure Company for:</h2>
        <h2 className="slide text-center">{currentItem}</h2>
        <p className="lead mb-4 text-center">
          Building security, compliance, and verification tools to make the
          internet a safer place.
        </p>
        <div className="text-center">
          <a
            href="https://calendly.com/premblysalesteam/45mins"
            className="green-btn p-3"
            target="_blank"
          >
            Speak to Sales
          </a>
        </div>
      </Container>
    </Col>

    <Col md={5} className="mt-4 d-flex justify-content-center">
      <img src={jobber} alt="Prembly" className="img-fluid" />
    </Col>
  </Row>
</section>; */
}

const Header = () => {
  const [currentItem, setCurrentItem] = useState(infrastructureItems[0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentItem((prevItem) => {
        const currentIndex = infrastructureItems.indexOf(prevItem);
        const nextIndex = (currentIndex + 1) % infrastructureItems.length;
        return infrastructureItems[nextIndex];
      });
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <div className="">
        <NavBar
          isHomePage={true}
          className="tw-fixed tw-top-0 tw-z-[100] landing"
        />

        <section className="tw-px-[4.75rem] landing">
          <Row className="">
            <Col
              md={6}
              className=" leftSide text d-flex flex-column justify-content-around custom-col-width"
            >
              <Container className="py-3 mt-4 contner">
                <h2 className="">A Data Infrastructure Company for</h2>
                <h2 className="slide">{currentItem}</h2>
                <p className="mb-5">
                  Building security, compliance, and verification tools to make
                  the internet a safer place.
                </p>
                <a
                  href="https://calendly.com/premblysalesteam/45mins"
                  className="green-btn p-3 "
                  target="_blank"
                >
                  Speak to Sales
                </a>
              </Container>
            </Col>

            <Col md={5} className="mt-4 d-flex justify-content-center contner">
              <img src={jobber} alt="Prembly" />
            </Col>
          </Row>
        </section>

        <main className="container text-center">
          <section className="about-company">
            <img src={homepageImg} className="image2" alt="" />
            <h2 className="about-comapnay-text">Powering Greatness</h2>
            <div className="content-container mx-auto">
              <p>
                We Power businesses to Greatness; providing a solid foundation
                for effortless compliance with KYC, KYB, AML, CFT & Background
                Checks, all supported by our advanced data infrastructure.
              </p>
              <br />
              <a className="grey-btn" href="#" color="grey" target="_blank">
                Learn More
              </a>
              {/* <button className="green-btn">Our Solutions</button> */}
            </div>
          </section>
          <div className="trusted-by">
            <h2>Trusted by</h2>
            <Slider />
          </div>
        </main>
        {/* <Footer /> */}

        {/* Floating WhatsApp Icon */}
        {/* <a
          href="https://web.whatsapp.com/send?phone=254796111020&text=Hello%2C%20I%27m%20from%20Peleza%20website%20and%20I%20have%20an%20enquiry"
          className="whatsapp-float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="whatsapp-content">
            <img src={whatsappIcon} alt="WhatsApp" className="whatsapp-icon" />
            <span className="whatsapp-text">How can I help you?</span>
          </div>
        </a> */}
        <FloatingWhatapp />
      </div>
    </>
  );
};

export default Header;
