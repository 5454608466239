import React, { useState } from "react";
// import Footer from "../../../components/footer";
import Footer from "../../../components/newNavbar/Footer";
import NavbarArea from "../../../components/navbar";
import { Modal } from "react-bootstrap";
import { identitys } from "../../../components/datas";
import LatestF from "../../../pages/allProduct/LatestF";
import { identityT } from "../../../components/datas";
import allImg from "../../../assets/allLogo.png";
import { Link } from "react-router-dom";
import BlueCard from "../../../pages/allProduct/BlueCard";
import { Helmet } from "react-helmet";
import NavBar2 from "../../../components/newNavbar/NavBar";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "none" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "green",
        borderRadius: "30px",
        width: "50px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={onClick}
    />
  );
}

export default function BackgroundCheck() {
  const [show, setShow] = useState(false);
  const [productTab, setProductTab] = useState(1);
  const [solutionTab, setSolutionTab] = useState(1);
  const [arrowDirection, setArrowDirection] = useState(1);

  const arrowAngleRight = () => {
    setArrowDirection(2);
  };

  const arrowAngleLeft = () => {
    setArrowDirection(1);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div style={{ background: "#ffffff" }}>
      <Helmet>
        <title>Prembly- BackgroundCheck</title>
        <meta
          name="description"
          content="Background Checks and Document verification"
        />
      </Helmet>
      <>
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Identityradar </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              src="https://drive.google.com/file/d/1nYSigQg5XyKFRhd7Cfvw36imEA9RYCsj/preview"
              title="Radar-Explainer"
              className="w-100"
              height="520"
              allow="autoplay"
              allowFullScreen="allowFullScreen"
            ></iframe>
          </Modal.Body>
          {/* <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Save Changes
              </Button>
            </Modal.Footer> */}
        </Modal>
      </>
      {/* <NavbarArea colouredButton="blue" /> */}
      <NavBar2/>
      <div className="whiteBg"></div>
      <section className="hero-section identitypassHero py-5" style={{padding: '0 7rem'}}>
        <div className="container-fluid py-3">
          <div className="row align-items-center mx-100 justify-content-between idherocolor">
            <div className="col-md-12 col-lg-6 py-5 idcore">
              <div className="d-flex align-items-center">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/form.png"
                  alt=""
                />
                <a
                  className="btn btn-purple px-md-2 px-4 py-2 mt-0 "
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  Background Checks
                </a>
              </div>
              <h1 className="py-3">
                Screen Farther, from Anywhere & Anytime! 
              </h1>
              <h5 className="mt-3">
                Built for global enterprises and designed to swiftly and
                accurately deliver in-depth background, Background Checks
                curated data globally from trusted sources to deliver the
                required information. 
              </h5>

              <div className="d-flex align-items-center">
                <a
                  className="btn btn-blue  me-1"
                  href="https://account.prembly.com/signUp"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get Started
                </a>
                <Link
                  to="/contact"
                  className="btn btn-blacks px-md-4 py-3 mt-3"
                >
                  <span>Talk to Sales</span>
                  <i class="ri-arrow-right-line ms-2"></i>
                </Link>
              </div>
            </div>

            <div className="col-md-12 col-lg-5 ">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/videos/jobber.gif"
                alt=""
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section id="partners" className="loved-by-section pt-5  text-center">
        <div className="container">
          <h4>We are trusted by all top financial institutions</h4>
          <div className="row align-items-center justify-content-around py-4">
            <div className="col mt-4 logosimg">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/polaris-logo.png"
                alt="Prembly Customers"
              />
            </div>

            <div className="col mt-4 logosimg">
              {" "}
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/kuda-logo.png"
                alt="Prembly Customers"
                style={{ width: "120px" }}
              />
            </div>
            <div className="col mt-4 logosimg">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/uba-logo.png"
                alt="Prembly Customers"
              />
            </div>

            <div className="col mt-4 logosimgs">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/vbank.png"
                alt="Prembly Customers"
              />
            </div>

            <div className="col mt-4 logosimgs">
              <img src={london} alt="Prembly Customers" />
            </div>

            <div className="col mt-4 logosimgs" id="arms">
              <img
                src={arm}
                alt="Prembly Customers"
                style={{ width: "130px" }}
              />
            </div>

            <div className="col mt-4 logosimgs">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/sterling-logo.png"
                alt="Prembly Customers"
              />
            </div>
          </div>
        </div>
      </section> */}

<section
        id="partners"
        className="loved-by-section pt-5  text-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="">
          <div>
            <h4>We are trusted by all top financial institutions</h4>

            <div className="marquee">
              <div className="marquee-content">
                <ul>
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>

                <ul>
                  {" "}
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>
                <ul>
                  {" "}
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="" className="how-can-premblyhelp-area  ">
        <div className="col-lg-12 mx-auto">
          <div className="text-center col-md-11  mx-auto quickhelp py-2 ">
            <h1 className="mb-4">
              How Background Checks will help HR Managers
            </h1>
          </div>

          <div className="row align-items-center justify-content-center  verifyallapplicant seperate-contentsblue">
            -
            <div className="col-md-12 col-lg-5 text-content mx-auto verifyapplicantstoday">
              <h1>Background Verification </h1>
              <p className="mt-4 ">
                Easily access comprehensive background information on any
                applicant with just one click. Make informed hiring decisions
                with our in-depth background reports.
              </p>
            </div>
            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants" id="magic-bg">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/background-checks-herosection/background-verification.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            className="row align-items-center   justify-content-center verifyallapplicant"
            id="columforbg"
          >
            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/criminal-records.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-5  verifyapplicantstoday">
              <h1>Criminal Records</h1>
              <p className="mt-4 ">
                Effortlessly review and verify important documents with our
                user-friendly platform. Quickly access and easily review
                resumes, IDs, and other vital records.
              </p>
            </div>
          </div>

          <div className="row align-items-center  verifyallapplicant seperate-contentsblue justify-content-center">
            <div className="col-md-12 col-lg-5  verifyapplicantstoday">
              <h1>Education History </h1>
              <p className="mt-4 ">
                Easily access comprehensive background information on any
                applicant with just one click. Make informed hiring decisions
                with our in-depth background reports.
              </p>
            </div>

            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants" id="magic-bg">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/background-checks-herosection/education-history.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            className="row align-items-center  verifyallapplicant justify-content-center"
            id="columforbg"
          >
            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/address.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-5  verifyapplicantstoday">
              <h1>Address Verification  </h1>
              <p className="mt-4 ">
                Get instant results with our fast and accurate applicant
                verification process. Verify employment history, education, and
                more in just seconds.
              </p>
            </div>
          </div>

          <div className="row align-items-center   verifyallapplicant seperate-contentsblue justify-content-center">
            <div className="col-md-12 col-lg-5 verifyapplicantstoday">
              <h1>Credit History </h1>
              <p className="mt-4 ">
                Easily access comprehensive background information on any
                applicant with just one click. Make informed hiring decisions
                with our in-depth background reports.
              </p>
            </div>

            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants" id="magic-bg">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/background-checks-herosection/credit-history.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="countries-section py-5">
        <div className="container">
          <div className="col-lg-12 mx-auto">
            <div className="row align-items-center mx-auto justify-content-center countries-gap">
              <div className="col-md-12 col-lg-6 countries-list ">
                <div className="global-color global-blue mb-4">
                  <p>Global</p>
                </div>
                <h1>We are licensed to operate in 100+ countries</h1>
                <h5 className="mt-3 mb-4">
                  Prembly's extensive range of over 1000 APIs can be utilised by
                  businesses worldwide to enhance their identity verification
                  and authentication processes. Our dedicated teams, located
                  globally, are ready to assist you and your customers wherever
                  your operations may be
                </h5>

                <div className="countriesFlag">
                  <img
                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/countries.svg"
                    alt=""
                  />
                </div>

                <div className="d-flex align-items-center">
                  <Link
                    to="/about"
                    className="btn btn-blue  mt-4 me-1"
                    rel="noreferrer"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
              <div className="col-md-12 col-lg-5">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/world-new.gif"
                  alt=""
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="feedback" className="feedback-prembly-area">
        <div className="container">
          <div className="col-md-12 col-lg-11 mx-auto py-lg-5 text-center custom">
            <h1>Our Customers Perspective</h1>
            {arrowDirection === 1 && (
              <div className=" d-flex align-items-center py-5 ">
                {/* <img src={rewind1} alt="" className="me-2"/> */}
                {/* <div className=" showflex"> */}
                <div className="showflex row ">
                  {identitys.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      {/* <Slider {...settings}> */}

                      <BlueCard
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />

                      {/* </Slider> */}
                    </div>
                  ))}
                </div>
                <img
                  onClick={arrowAngleRight}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindright.svg"
                  alt=""
                  className="ms-2"
                />
              </div>
            )}

            {arrowDirection === 2 && (
              <div className=" d-flex align-items-center py-5 ">
                <img
                  onClick={arrowAngleLeft}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindleft.svg"
                  alt=""
                  className="me-2"
                />
                {/* <div className=" showflex"> */}
                <div className="showflex row ">
                  {identityT.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      {/* <Slider {...settings}> */}

                      <BlueCard
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />

                      {/* </Slider> */}
                    </div>
                  ))}
                </div>
                {/* <img src={rewindright} alt="" className="ms-2"/> */}
              </div>
            )}
          </div>
        </div>
      </section>

      <LatestF updateBg="bluebg" />
      <Footer />
    </div>
  );
}
