import React, { useState } from "react";
import NavbarArea from "../../components/navbar";
import Footer from "../../components/footer";
// import get from "../../assets/get.png";
import LatestF from "../allProduct/LatestF";
// import radarimg from "../../assets/radarimg.png";
// import countries from "../../assets/countries.svg";
// import world from "../../assets/world-new.gif";
// import rewind1 from "../../assets/rewindleft.svg";
// import rewindright from "../../assets/rewindright.svg";
import { identityT } from "../../components/datas";
import { identitys } from "../../components/datas";
import Cards from "../allProduct/Cards";
import allImg from "../../assets/allLogo.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


export default function Insurance() {
  const [arrowDirection, setArrowDirection] = useState(1);
  const arrowAngleRight = () => {
    setArrowDirection(2);
  };

  const arrowAngleLeft = () => {
    setArrowDirection(1);
  };
  return (
    <div style={{ background: "#ffffff" }}>
      <Helmet>
        <meta name="description" content="A Compliance and Security Company" />
        <meta
          property="og:title"
          content="Prembly-A Compliance and Security Company"
        />
      </Helmet>
      <NavbarArea />
      <div className="whiteBg"></div>
      <div className="hero-section sector-area py-5">
        <div className="container-fluid py-3">
          <div className="row align-items-center mx-auto justify-content-between">
            <div className="col-md-12 col-lg-6">
              <div className="d-flex ">
                <a
                  className="btn btn-graph  black-graph px-md-2 px-4 py-2 mt-0 "
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  Insurance
                </a>
              </div>
              <h1 className="py-3">
                Insurance verification solutions that suit customer needs 
              </h1>
              <h5 className="mt-2">
                Streamline remote insurance customers' digital verification
                process and authentication with quick, precise, and highly
                secure experiences across the customer lifecycle. 
              </h5>

              <div className="d-flex align-items-center">
                <a
                  className="btn btn-green1  me-1"
                  href="https://account.prembly.com/signUp"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get Started
                </a>

                <Link
                  to="/contact"
                  className="btn btn-black1 px-md-4 py-3 mt-3"
                >
                  <span>Talk to Sales</span>
                  <i class="ri-arrow-right-line ms-2"></i>
                </Link>
              </div>
            </div>

            <div className="col-md-12 col-lg-5">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/insurance.jpg"
                alt="landing"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </div>

      <section
        id="partners"
        className="loved-by-section black-sector pt-5  text-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="">
          <div>
            <h4>We are trusted by all top financial institutions</h4>

            <div className="marquee">
              <div className="marquee-content">
                <ul>
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>

                <ul>
                  {" "}
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>
                <ul>
                  {" "}
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="" className="how-can-premblyhelp-area  ">
        <div className="col-lg-12 mx-auto">
          <div className="text-center col-md-12  mx-auto sectors py-2">
            <h1 className="mb-4">
              How Prembly is simplifying verification in Insurance
            </h1>
          </div>

          <div
            className="row align-items-center verifyallapplicant justify-content-center"
            id="columforbg"
          >
            <div className="col-md-10 col-lg-5 verify-applicant">
              <div className="verify-applicants">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/accurate.jpg"
                  alt=""
                />
              </div>
            </div>

            <div className="col-md-10 col-lg-5 verifyapplicantstodaygreen">
              <h1>
                Accurate, real-time identity information of policyholders across
                their life cycle
              </h1>
              <p className="mt-4 ">
                Background checks improve identity record management with
                accurate policyholder contact data for higher contact rates,
                real-time updates, and support. 
              </p>
              <div className="d-flex align-items-center">
                <a
                  className="btn btn-green1  me-1"
                  href="https://calendly.com/premblysalesteam/45mins"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Sales
                </a>
              </div>
            </div>
          </div>

          <div className=" row align-items-center   seperate-contentsblue  justify-content-center  verifyallapplicant">
            <div className="col-md-10 col-lg-5 verifyapplicantstodaygreen">
              <h1>
                Complete identity verifications of policyholders with confidence
              </h1>
              <p className="mt-4 ">
                Identitypass enables insurance companies to confidently verify
                and authenticate new and existing policyholders, resulting in
                higher pass rates, lower fraud, and global compliance. 
              </p>
              <div className="d-flex align-items-center">
                <a
                  className="btn btn-green1  me-1"
                  href="https://calendly.com/premblysalesteam/45mins"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Sales
                </a>
              </div>
            </div>

            <div className="col-md-10 col-lg-5 verify-applicant">
              <div className="verify-applicants">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/complete.svg"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            className=" row align-items-center justify-content-center  verifyallapplicant"
            id="columforbg"
          >
            <div className="col-md-10 col-lg-5 verify-applicant">
              <div className="verify-applicants">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/passive.jpg"
                  alt=""
                />
              </div>
            </div>

            <div className="col-md-10 col-lg-5 verifyapplicantstodaygreen">
              <h1>
                Passive and active authenticators to protect against
                unauthorized access
              </h1>
              <p className="mt-4 ">
                Identityradar provides a suite of passive and active
                authenticators tailored to any customer's authentication
                scenario ranging from low to high-risk events. 
              </p>
              <div className="d-flex align-items-center">
                <a
                  className="btn btn-green1  me-1"
                  href="https://calendly.com/premblysalesteam/45mins"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="countries-section py-5">
        <div className="container">
          <div className="col-lg-12 mx-auto">
            <div className="row align-items-center mx-auto justify-content-center countries-gap">
              <div className="col-md-10 col-lg-6 countries-list ">
                <div className="global-color mb-4">
                  <p>Global</p>
                </div>
                <h1>Our solution cut across 100+ countries</h1>
                <h5 className="mt-3 mb-4">
                  Prembly's extensive range of over 100 APIs can be utilised by
                  businesses worldwide to enhance their identity verification
                  and authentication processes. Our dedicated teams, located
                  globally, are ready to assist you and your customers wherever
                  your operations may be
                </h5>
                <div className="countriesFlag">
                  <img
                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/countries.svg"
                    alt=""
                  />
                </div>

                <div className="d-flex align-items-center">
                  <Link
                    to="/about"
                    className="btn btn-green1  mt-4 me-1"
                    rel="noreferrer"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
              <div className="col-md-10 col-lg-5">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/world-new.gif"
                  alt=""
                  className="codeimg w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="feedback" className="feedback-prembly-area">
        <div className="container">
          <div className="col-md-12 col-lg-11 mx-auto py-lg-5 text-center">
            <h1>Our Customers Perspective</h1>
            {arrowDirection === 1 && (
              <div className=" d-flex align-items-center py-5 ">
                <div className="showflex row ">
                  {identitys.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      <Cards
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />
                    </div>
                  ))}
                </div>
                <img
                  onClick={arrowAngleRight}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindright.svg"
                  alt=""
                  className="ms-2"
                />
              </div>
            )}

            {arrowDirection === 2 && (
              <div className=" d-flex align-items-center py-5 ">
                <img
                  onClick={arrowAngleLeft}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindleft.svg"
                  alt=""
                  className="me-2"
                />

                <div className="showflex row ">
                  {identityT.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      <Cards
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <LatestF />
      <Footer />
    </div>
  );
}
