import { useState } from "react";
import Button from "../Button";
import Accordion from "../Accordion";
import CtaPan from "../CtaPan";

// images
// import about_dots from '../../../newassets/about/about_dots.svg'
import about_dots from "../../../newassets/about/about_dots.svg";
import mobileherosection from "../../../newassets/about/mobileherosection.png";
import image11 from "../../../newassets/about/image-11.png";
import Rectangle22 from "../../../newassets/about/Rectangle22.png";
import Rectangle25 from "../../../newassets/about/Rectangle25.png";
import Rectangle24 from "../../../newassets/about/Rectangle24.png";
import image12 from "../../../newassets/about/image12.png";
import imagebulging from "../../../newassets/about/image bulging.png";
import womanman from "../../../newassets/about/womanman.png";
import CustomerSecurity from "../../../newassets/about/CustomerSecurity.png";
import qt from "../../../newassets/quotation_top.svg";
import ql from "../../../newassets/quation_left.svg";
import Footer from "../Footer";
// import NavbarComponent from "../NavbarComponent"
// import NavBar2 from "../NavBarv2"
import NavBar2 from "../NavBar";
import { FloatingWhatapp } from "../FloatingWhatapp";

const AboutPage = () => {
  const [currentTab, setCurrentTab] = useState(
    // <'digital_identity'|'impactful_infrastructure'|'we_explore'|'customer_security'>
    "digital_identity"
  );
  const [previousTab, setPreviousTab] = useState(null);

  const handleOpen = (newTab) => {
    setPreviousTab(currentTab);
    setCurrentTab(newTab);
  };

  return (
    <div>
      {/* <NavBar2 isHomePage={false}/> */}

      {/* <NavbarComponent /> */}
      <div>
        {/* desktop hero */}
        {/* <NavBar2 isHomePage={false}/>   */}

        <div className="tw-hidden md:tw-block desktop_hero_about tw-w-[100%] tw-h-[100vh] tw-mb-[9rem]">
          <NavBar2 isHomePage={false} />
          <div className="tw-pt-[72px] tw-w-[1100px] tw-h-[100%] tw-relative tw-mx-auto tw-mt-[6px]">
            <h2 className="tw-font-epi tw-font-[600] tw-text-[3rem] tw-text-[white] tw-flex tw-flex-col">
              <span className="tw-mb-[-20px] tw-pb-[.5rem]">The Future We</span>
              <span>Are Building</span>
            </h2>

            <div className="tw-w-[381px] tw-absolute tw-bottom-[210px] tw-right-0 ">
              <p className="tw-text-[#FFFFFF] tw-font-[400] tw-font-generalSansRegular tw-text-[1.5rem] tw-leading-[32px] tw-pb-[0.75rem] tw-mb-[2rem]">
                Unlocking global access to digital products and services - one
                person at a time.
              </p>
              {/* <Button className="tw-px-[2rem] tw-font-generalSansRegular tw-font-[600]" color="white">
                Sign In
              </Button> */}

              <a
                href="https://account.prembly.com/login"
                className="white-btn tw-bottom-[50px] green-btn"
                target="_blank"
              >
                Sign In
              </a>
            </div>
            <img
              src={about_dots}
              className="tw-absolute tw-bottom-[110px]"
              alt=""
            />
          </div>
        </div>
        {/* end desktop hero */}

        {/* mobile hero */}
        <div className="md:tw-hidden">
          <NavBar2 isHomePage={false} />

          <img
            src={mobileherosection}
            className="tw-w-[100%] tw-block"
            alt=""
          />
          <div className="tw-p-[1.25rem]">
            <div>
              <h2 className="tw-font-epi tw-font-[600] tw-text-[2.5rem] tw-text-grey10 tw-leading-[48px]">
                The Future We Are Building
              </h2>
              <p className="tw-font-generalSansMedium tw-font-[400] tw-text-[1rem] tw-text-[#353535] tw-pt-[.5rem]">
                Unlocking global access to digital products and services - one
                person at a time.
              </p>
            </div>
            <br />

            <a
              href="https://account.prembly.com/login"
              target="_blank"
              className="tw-w-[100%] md:tw-w-[195px] tw-block md:tw-mx-auto green-btn tw-text-center tw-py-2"
            >
              Sign In
            </a>
            {/*             
            <a href="https://account.prembly.com/login" className="green-btn tw-w-[100%] tw-my-[2rem] green-btn" target="_blank">Sign In</a> */}
            {/* <a href="https://account.prembly.com/login" className="white-btn tw-bottom-[50px]" target="_blank">Sign In</a> */}
          </div>
        </div>
        {/* mobile hero */}

        <main>
          {/* our mission for mobile */}
          <div className="">
            <div className="tw-px-[1.25rem] tw-mt-[.5rem] tw-gap-[1rem] md:tw-flex-row md:tw-justify-between md:tw-w-[1104px] md:tw-mx-auto md:tw-relative md:tw-h-[613.8px]  md:tw-h-[308.55px] tw-mx-auto">
              <h2 className="tw-font-epi tw-font-[500] tw-text-[2.5rem] tw-text-[#0B0A0A] tw-mb-[-1rem] md:tw-text-[3.5rem] md:tw-w-[900px] ">
                Our Mission
              </h2>
              <div className="md:tw-flex">
                <img
                  className="tw-hidden md:tw-block md:tw-pt-[.5rem] tw-translate-y-[-2rem] tw-mt-[-4rem]"
                  src={qt}
                  alt=""
                />
                <p
                  className="tw-font-generalSans tw-font-[400] tw-text-[1rem] tw-text-[#0B0A0A] md:tw-text-[2rem] md:tw-font-[500] md:tw-leading-[40px]  md:tw-w-[590px] tw-mt-[1.2rem] md:tw-p-[.5rem]  
                tw-font-generalSans  tw-font-[500]  tw-text-left"
                >
                  We are on a mission to safeguard digital businesses in
                  emerging markets to transact globally while building trust
                  with customers.
                </p>
                <img
                  className="tw-hidden md:tw-block md:tw-pt-[.5rem] tw-mt-[13rem]"
                  src={ql}
                  alt=""
                />
              </div>
            </div>

            {/* action container */}
            <div className="tw-py-[2.5rem] tw-px-[1.25rem] tw-flex tw-flex-col tw-gap-[1rem] md:tw-flex-row md:tw-justify-between md:tw-w-[1104px] md:tw-mx-auto md:tw-relative md:tw-h-[613.8px] accd">
              <Accordion
                name="Digital Identity"
                title="Digital Identity For All"
                open={currentTab === "digital_identity"}
                handleOpen={(open) => {
                  setCurrentTab("digital_identity");
                }}
                content="We are building a future where everyone has a secure and dependable digital identity; especially those in the emerging markets. Together with our partners, we are making it easier than ever for people to obtain and verify digital identities, fostering a more inclusive and connected society."
              >
                <div className="tw-flex  tw-items-center tw-justify-between tw-mt-[2rem] md:tw-w-[483.83px] md:tw-translate-y-[-35px]">
                  <div className="tw-flex tw-flex-col">
                    <img
                      src={image11}
                      className="tw-block tw-w-[122.44px] tw-h-[99.57px] md:tw-w-[176.83px] md:tw-h-[143.8px]"
                      alt=""
                    />
                    <img
                      src={Rectangle22}
                      className="tw-block tw-w-[122.44px] tw-h-[99.57px] md:tw-w-[176.83px] md:tw-h-[143.8px] tw-translate-x-[60px]"
                      alt=""
                    />
                    <img
                      src={Rectangle25}
                      className="tw-block tw-w-[122.44px] tw-h-[99.57px] md:tw-w-[176.83px] md:tw-h-[143.8px]"
                      alt=""
                    />
                  </div>

                  <div className="tw-flex tw-flex-col">
                    <img
                      src={Rectangle24}
                      className="tw-block tw-w-[122.44px] tw-h-[99.57px] md:tw-w-[176.83px] md:tw-h-[143.8px] tw-pb-[0.744rem] tw-object-cover tw-translate-x-[-11px]"
                      alt=""
                    />
                    <img
                      src={image12}
                      className="tw-block tw-w-[122.44px] tw-h-[99.57px] md:tw-w-[176.83px] md:tw-h-[143.8px]"
                      alt=""
                    />
                  </div>
                </div>
              </Accordion>

              <Accordion
                open={currentTab === "impactful_infrastructure"}
                handleOpen={(open) => {
                  setCurrentTab("impactful_infrastructure");
                }}
                name="Impactful Infrastructure"
                title="Building Impactful Data Infrastructure"
                content="We work with multiple stakeholders across different jurisdictions to create data-centered infrastructures that simplify everyday living. We aim to create the right data trail that makes life easier for everyone and guarantees equal access to opportunities."
              >
                <img
                  src={imagebulging}
                  className="tw-mt-[1.5rem] md:tw-mt-[unset] tw-w-[550px] tw-h-[100%] tw-block"
                  alt=""
                />
              </Accordion>

              <Accordion
                open={currentTab === "we_explore"}
                handleOpen={(open) => {
                  setCurrentTab("we_explore");
                }}
                name="We explore"
                title="We Explore With Data"
                content="We go beyond our immediate solutions that serve millions of people monthly. We build custom data projects driven by research and innovation. We empower researchers and institutions with the tools and resources needed to unlock groundbreaking discoveries and positive societal change."
              >
                <img
                  src={womanman}
                  className="tw-mt-[1.5rem] md:tw-mt-[unset] tw-w-[550px] tw-h-[100%] tw-block"
                  alt=""
                />
              </Accordion>

              <Accordion
                open={currentTab === "customer_security"}
                handleOpen={(open) => {
                  setCurrentTab("customer_security");
                }}
                name="Customer Security"
                title="Customer Security and Consent"
                content="We take customer safety and data consent as seriously as our existence. Our products and solutions are built with global standards and with the consciousness of ever-changing technology environments."
              >
                <img
                  src={CustomerSecurity}
                  className="tw-mt-[1.5rem] md:tw-mt-[unset] tw-w-[550px] tw-h-[100%] tw-block"
                  alt=""
                />
              </Accordion>
            </div>
            {/* end action container */}
          </div>
          {/* end our mission */}
        </main>
        <CtaPan />
      </div>
      <FloatingWhatapp />
      <Footer />
    </div>
  );
};

export default AboutPage;
