import React from "react";
import { Avatar } from "@mui/material";


export default function Cards({ id, comment, name, small }, props) {
  return (
    <>
    <div  className={`allColoredCard feedbackCard${props.cardBgs ? props.cardBgs : ""}`}>
      <div className=' card mb-3'>
        <div className="text-start ">
          <i class="ri-double-quotes-l ri-xl"></i>
          <div className=" text-justify feedback-title mt-3">
            <p>{comment}</p>
          </div>
          <div className="quote-left">
             <i class="ri-double-quotes-r ri-xl"></i>
          </div>
            <h4 className="pt-5">{name}</h4>
        </div>
      </div>
    
     </div>
     </>

    

  );
}
