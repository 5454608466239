export const dataContext = [
  {
      id: 1,
      contextName:'INTRODUCTION',
  },

  {
    id: 2,
    contextName: "GENERAL SERVICE TERMS",
  },

  {
    id: 3,
    contextName: "MODIFICATIONS",
  },

  {
    id: 4,
    contextName: "DATA PROTECTION",
  },
  {
    id: 5,
    contextName: "PAYMENT AND FEES",
  },

  {
    id: 6,
    contextName: "INDEMNITY",
  },

  {
    id: 7,
    contextName: "LIMITATION OF LIABILITY ",
  },

  {
    id: 8,
    contextName: "TERMINATION ",
  },

  {
    id: 9,
    contextName: "CONFIDENTIALITY",
  },

  {
    id: 10,
    contextName: "PRIVACY",
  },

  {
    id: 11,
    contextName: "INTELLECTUAL PROPERTY",
  },

  {
    id: 12,
    contextName: "HELP DESK",
  },

  {
    id: 13,
    contextName: "GENERAL",
  },

  {
    id: 14,
    contextName: "Violation of Privacy Policy",
  },

  {
    id: 15,
    contextName: "CONTACT US",
  },
];

//<p>GENERAL SERVICE TERMS</p>
// <p>MODIFICATIONS </p>
// <p>DATA PROTECTION</p>
// <p>PAYMENT AND FEES</p>
// <p>INDEMNITY</p>
// <p>LIMITATION OF LIABILITY </p>
// <p>TERMINATION</p>
// <p>CONFIDENTIALITY</p>
// <p>PRIVACY</p>
// <p>INTELLECTUAL PROPERTY</p>
// <p>HELP DESK</p>
// <p>GENERAL</p>
// <p>Violation of Privacy Policy</p>
// <p>CONTACT US</p>



    /* {{dataContext.filter((value) => {
                   if(searchTerm = ""){
                    return value
                   }else if(value.contextName.toLowerCase().includes()){
                     return value
                   }
               })

               } */
