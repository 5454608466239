import React, { useState } from "react";
import NavbarArea from "../../../components/navbar";
import NavBar2 from "../../../components/newNavbar/NavBar";
// import Footer from "../../../components/footer";
import Footer from "../../../components/newNavbar/Footer";
import ReactElasticCarousel from "react-elastic-carousel";
import { identitys } from "../../../components/datas";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LatestF from "../../allProduct/LatestF";
import BlueCard from "../../allProduct/BlueCard";
import { identityT } from "../../../components/datas";
import { Link } from "react-router-dom";
import allImg from "../../../assets/allLogo.png";
import { Helmet } from "react-helmet";

// function SampleNextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "none" }}
//       onClick={onClick}
//     />
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "none" }}
//       onClick={onClick}
//     />
//   );
// }

export default function IdentityPa(props) {
  const [productTab, setProductTab] = useState(1);
  const [solutionTab, setSolutionTab] = useState(1);
  const [arrowDirection, setArrowDirection] = useState(1);

  const carouselBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 5 },
  ];

  const arrowAngleRight = () => {
    setArrowDirection(2);
  };

  const arrowAngleLeft = () => {
    setArrowDirection(1);
  };

  return (
    <>
     <NavBar2 colouredButton="blue" />
     <div style={{ background: "#ffffff" }}>
       <Helmet>
        <title>Prembly- IdentityPass</title>
        <meta
          name="description"
          content="Identity Verification and KYC Solution in Nigeria"
        />
      </Helmet>
     
      <div className="whiteBg"></div>
      <div className="hero-section  identitypassHero" style={{padding: '0 7rem'}}>
        <div className="container-fluid py-5">
          <div className="row align-items-center justify-content-between idherocolor">
            <div className="col-md-12 col-lg-6">
              <div className="d-flex align-items-center">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/myPassNew.svg"
                  // alt="KYC/KYB verification solutions"
                />
                <a
                  className="btn btn-purple px-md-2 px-4 py-2 mt-0 "
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  Identitypass
                </a>
              </div>
              <h1 className="py-3">
                Instantly Verify Individuals & Businesses Across Emerging Market
              </h1>
              <h5 className="mt-3">
                Identitypass is the go-to simplified KYC/KYB solution, enabling
                digital businesses acquire and onboard customers with ease
                through tools that can be accessed for instant Data
                verification, Document Verification, and Biometric
                Authentication.
              </h5>

              <div className="d-flex align-items-center">
                <a
                  className="btn btn-blue  me-1"
                  href="https://account.prembly.com/signUp"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get Started
                </a>
                <Link
                  to="/contact"
                  className="btn btn-blacks px-md-4 py-3 mt-3"
                >
                  <span>Talk to Sales</span>
                  <i class="ri-arrow-right-line ms-2"></i>
                </Link>
              </div>
            </div>
            <div className="col-md-12 col-lg-5 ">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/homepage2/idpass-hero1.gif"
                alt="Instant customers onboarding solution"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </div>

      <section
        id="partners"
        className="loved-by-section pt-5  text-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="">
          <div>
            <h4>We are trusted by all top financial institutions</h4>

            <div className="marquee">
              <div className="marquee-content">
                <ul>
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>

                <ul>
                  {" "}
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>
                <ul>
                  {" "}
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="how-to-get-started" className="how-to-start-areas">
        <div className="container">
          <div className="col-md-11  mx-auto authentic py-3 ">
            <p>
              Identitypass offers authentic identity verification solutions for
              businesses to gain deeper insights about their customers using
              issued identifiers and our AI-powered biometric system; to
              seamlessly onboard customers in record time; whilst detecting and
              flagging down identity mismatch.
            </p>

            <h1 className="mb-4">How Identitypass Verification Works</h1>
          </div>

          <div className="row align-items-center mx-auto justify-content-between code-sec">
            <div className="col-md-12 col-lg-6 imgleft">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/Imagelefts.svg"
                alt="How verification works"
              />
            </div>

            <div className="col-md-12 col-lg-5 image-right">
              <div className="d-flex align-items-center">
                <div className="longimg">
                  <img
                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/long.png"
                    alt=""
                  />
                </div>

                <div className="card-info">
                  <div className="card">
                    <div className="card-body row">
                      <div className="d-flex">
                        <div class="ticks me-3">
                          <i class="ri-check-line"></i>
                        </div>

                        <div>
                          <h5>Get the ball rolling with our SDKs</h5>
                          <p>
                            Get started quickly with our easy-to-use SDKs and
                            take your project to the next level.
                          </p>

                          <div className="d-flex align-items-center">
                            <a
                              className="btn btn-blue  me-1"
                              href="http://account.prembly.com/signUp"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Get Started
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="newLeft mt-3">
                        <img
                          src="https://prembly-assets.s3.us-east-2.amazonaws.com/Imagelefts.svg"
                          alt="newLeft"
                          className="w-100"
                          id="disables"
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      className="inactive d-flex align-items-center px-4 pt-5"
                      // onClick={() => setMoreGraphPage(2)}
                    >
                      <i className="ri-pulse-line ri-xl me-3" />
                      <h5>Integrate with our APIs</h5>
                    </div>
                    <hr />
                    <div
                      className="inactive d-flex align-items-center p-4"
                      // onClick={() => setMoreGraphPage(3)}
                    >
                      <i className="ri-qr-scan-2-line ri-xl me-3" />
                      <h5>Not a Dev? Enjoy our no-code integrations</h5>
                    </div>
                    <hr />
                    <div
                      className="inactive d-flex align-items-center p-4"
                      // onClick={() => setMoreGraphPage(4)}
                    >
                      <i className="ri-alert-line ri-xl me-3" />
                      <h5>Play with our portal directly</h5>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="" className="how-can-premblyhelp-area">
        <div className="col-lg-12 mx-auto">
          <div className="text-center col-md-11  mx-auto  quickhelp py-2 ">
            <h1 className="mb-4">How IdentityPass helps businesses</h1>
          </div>

          <div className="row align-items-center  verifyallapplicant seperate-contentsblue justify-content-center">
            <div className="col-md-12 col-lg-5 verifyapplicantstoday">
              <h1>The Magic with Identity Verification </h1>
              <p className="mt-4 ">
                Easily access comprehensive background information on any
                applicant with just one click. Make informed hiring decisions
                with our in-depth background reports.
              </p>
            </div>

            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants" id="magic-bg">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/identity-pass-hero-section/the-magic-with.jpg"
                  alt="Identity Verification"
                />
              </div>
            </div>
          </div>

          <div
            className="row align-items-center  verifyallapplicant  justify-content-center"
            id="columforbg"
          >
            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/images/biometric.svg"
                  alt="Biometric Verification"
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-5  verifyapplicantstoday ">
              <h1>Biometric Verification </h1>
              <p className="mt-4 ">
                Protect your customers’ identities and prevent fraud with our
                advanced liveness checks technology. Onboard your users in
                minutes with just a selfie and make it virtually impossible for
                fraudsters to impersonate your platform.
              </p>
            </div>
          </div>

          <div className=" row align-items-center  verifyallapplicant seperate-contentsblue justify-content-center">
            <div className="col-md-12 col-lg-5  verifyapplicantstoday">
              <h1>ID Checker Widget </h1>
              <p className="mt-4 ">
                Onboard with complete confidence. Using a simple drag-and-drop
                feature, you can welcome new customers and onboard businesses
                with trust without any technical coding knowledge.
              </p>
            </div>

            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/images/id-checker.svg"
                  alt="No Code Identity Verification"
                />
              </div>
            </div>
          </div>

          <div
            className="row align-items-center  verifyallapplicant justify-content-center"
            id="columforbg"
          >
            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/images/document.svg"
                  alt="Document Verification "
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-5  verifyapplicantstoday">
              <h1>Document Verification </h1>
              <p className="mt-4 ">
                Verify over 6000 documents across hundreds of regions worldwide.
                Ensure your customer’s data are accurate and genuine within
                minutes.
              </p>
            </div>
          </div>

          <div className=" row align-items-center   verifyallapplicant seperate-contentsblue justify-content-center">
            <div className="col-md-12 col-lg-5 verifyapplicantstoday">
              <h1>Stay Secure and Compliant </h1>
              <p className="mt-4">
                Keep up with the ever-increasing local and international
                compliance standards. Assure your customers that their data is
                secure on your platform.
              </p>
            </div>

            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/images/stay-secure.svg"
                  alt="Compliance and Security Solution "
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="customers-perspective-area">
        <div className="container">
          <div className="col-md-8 col-lg-6 mx-auto text-center py-5">
            <h1>Our Customers Perspective</h1>
          </div>

              <Carousel slides={slides} interval={1000}/>
          
          <div className="">
              <div className=" align-items-center py-5 ">
              
              <Slider {...settings}>
                {
                  identitys.map(data => (
                    <div key={data.id}>
                      <Card  comment={data.comment} name={data.name} small={data.small}/>
                    </div>
                  )
                )
                }

              </Slider>

              
               
              </div>
          </div>
        </div>
      </section> */}

      {/* <section className="customers-perspective-area">
        <div className="container">
          <div className="col-md-8 col-lg-6 mx-auto text-center py-5">
            <p>We think we are great!</p>
            <h2>Our Customers Perspective</h2>
          </div>

          <Carousel slides={slides} interval={1000} />
        </div>
      </section> */}
      <section className="countries-section py-5">
        <div className="container">
          <div className="col-lg-12 mx-auto">
            <div className="row align-items-center mx-auto justify-content-center countries-gap code-countries">
              <div className="col-md-12 col-lg-6 countries-list ">
                <div className="global-color global-blue mb-4">
                  <p>Global</p>
                </div>
                <h1>Our solution cut across 100+ countries</h1>
                <h5 className="mt-3 mb-4">
                  Prembly's extensive range of over 100 APIs can be utilised by
                  businesses worldwide to enhance their identity verification
                  and authentication processes. Our dedicated teams, located
                  globally, are ready to assist you and your customers wherever
                  your operations may be
                </h5>

                <div className="countriesFlag">
                  <img
                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/countries.svg"
                    alt="Global compliance and security infrastructure."
                  />
                </div>

                <div className="d-flex align-items-center">
                  <Link
                    to="/about"
                    className="btn btn-blue  mt-4 me-1"
                    rel="noreferrer"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
              <div className="col-md-12 col-lg-5 worlds">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/world-new.gif"
                  alt="Verify customers and prevent fraud across emerging markets"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="feedback" className="feedback-prembly-area py-3">
        <div className="container ">
          <div className="col-md-12 col-lg-11 mx-auto py-lg-5 text-center custom">
            <h1>Our Customers Perspective</h1>
            {arrowDirection === 1 && (
              <div className=" d-flex align-items-center  ">
                <div className="showflex row ">
                  {identitys.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      <BlueCard
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />
                    </div>
                  ))}
                </div>
                <img
                  onClick={arrowAngleRight}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindright.svg"
                  alt=""
                  className="ms-2"
                />
              </div>
            )}

            {arrowDirection === 2 && (
              <div className=" d-flex align-items-center py-5 ">
                <img
                  onClick={arrowAngleLeft}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindleft.svg"
                  alt=""
                  className="me-2"
                />
                {/* <div className=" showflex"> */}
                <div className="showflex row ">
                  {identityT.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      {/* <Slider {...settings}> */}

                      <BlueCard
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                        cardBgs="blueC"
                      />

                      {/* </Slider> */}
                    </div>
                  ))}
                </div>
                {/* <img src={rewindright} alt="" className="ms-2"/> */}
              </div>
            )}
          </div>
        </div>
      </section>

      {/* <section className="get-started-area get-started-areablue py-5">
        <div className="container">
          <div className="row align-items-center mx-auto justify-content-center get-it-row ">
            <div className=" col-md-10 col-lg-6">
              <h1 className="mb-4" style={{ color: "#4312C7" }}>
                Ready to Get started ?
              </h1>
              <div className="brown ">
                <img src='https://prembly-assets.s3.us-east-2.amazonaws.com/get.png' alt="" className="w-100" />
              </div>
            </div>

            <div className="col-md-10 col-lg-5">
              <div>
                <p>
                  Get in touch or create an account instantly and ensure your
                  business remains secure and compliant. You can also contact us
                  for a custom package for your business.
                </p>
              </div>
              <div className="getInTouchForms">
                <form action="">
                  <div className="row inputrows">
                    <input
                      type="text"
                      placeholder="Full name"
                      className="mb-3 formholder"
                    />
                    <input
                      type="text"
                      placeholder="Business name"
                      className="mb-3 formholder"
                    />
                    <input
                      type="email"
                      placeholder="Email"
                      className="mb-3 formholder"
                    />
                    <textarea
                      type="text"
                      rows="12"
                      placeholder="How can we help you?"
                      className="formholder mb-5"
                      cols="50"
                    ></textarea>
                    <button type="submit" className="btn becomeateamblue">
                      Send message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <LatestF updateBg="bluebg" />
      <Footer />
    </div></>
  
  );
}
