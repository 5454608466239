import React from "react";
import { Helmet } from "react-helmet";
import NavbarArea from "./navbar";
import Footer from "./footer";
import LatestF from "../pages/allProduct/LatestF";
import { Link } from "react-router-dom";
import illustration from "../assets/Illustration.png";

export default function ErrorPage() {
  return (
    <div style={{ background: "#ffffff" }}>
      <Helmet>
        <title>404</title>
        <meta name="description"  content="A Compliance and Security Company" />
        <meta property="og:title" content="Prembly- 404" />
      </Helmet>
      <NavbarArea />
      <section className="errorpage pb-5 ">
        <div className="container">
          <div className="errorContent">
            {/* <div className="errorimage"> */}
              <img src={illustration} alt="" />
            {/* </div> */}

            {/* <div> */}
              <h6 className="errorheader">404</h6>
              <p>OOOPS! THE PAGE YOU WERE LOOKING FOR COULDN'T BE FOUND.</p>
            {/* </div> */}
            

            <div className="">
              <Link
                to="/"
                className="btn btn-green1"
                target="_blank"
                rel="noreferrer"
              >
                Return to Home
              </Link>
            </div>
          </div>
        </div>
      </section>
      <LatestF />
      <Footer />
    </div>
  );
}
