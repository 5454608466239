import { useState } from "react";
import about_dots from "../../newassets/about/about_dots.svg";
import mobileherosection from "../../newassets/about/mobileherosection.png";

import Footer from "./Footer";
import storyImg from "../../newassets/storyImg.svg";
import storyHeader from "../../newassets/storyHeader.svg";
import NavBar2 from "./NavBar";
import { FloatingWhatapp } from "./FloatingWhatapp";
import "./style.css";

const StoryPage = () => {
  return (
    <div>
      {/* <NavBar2 isHomePage={false}/> */}

      {/* <NavbarComponent /> */}
      <div>
        {/* desktop hero */}
        <div className="md:tw-block desktop_hero_story tw-w-[100%] tw-h-[100vh]">
          <NavBar2 isHomePage={false} />
          <div className="tw-pt-[72px] tw-w-[100%] tw-h-[100%] tw-relative tw-mx-auto tw-flex tw-items-center tw-justify-center storyText">
            <h2
              className="tw-font-epi tw-font-[600] tw-text-[78px] tw-text-[white] tw-text-center smallText"
              style={{
                marginTop: "-180px",
              }}
            >
              <span
                className=""
                style={{
                  marginTop: "-30px",
                }}
              >
                Our Story
              </span>
            </h2>
          </div>
        </div>

        {/* end desktop hero */}

        {/* mobile hero */}
        <br />
        <br />
        <br />

        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-[1.25rem] tw-mt-[.5rem] tw-w-[100%] st">
          <div className="tw-flex tw-flex-col tw-items-center tw-text-center md:tw-w-[820px]">
            {/* <h2 className="tw-font-general-sans text-[40px] tw-font-[500] tw-text-title tw-font-medium tw-leading-title tw-text-center tw-mb-[1rem] tw-text-[3rem] bbb">
              Our Story
            </h2> */}
            <div className="tw-flex tw-flex-col tw-items-center tw-text-center tw-mb-[1rem] ">
              <p className="storyText">
                We’ve always believed that businesses shouldn’t have to navigate
                a complex maze to verify identities or conduct background
                checks. That’s why we created Prembly. Born from the combined
                expertise of Peleza and Prembly, we’re on a mission to simplify
                the world of data verification.
              </p>
              <br />
              <p className="storyText">
                Our journey began with a shared vision: to create a secure,
                efficient, and reliable platform that empowers businesses to
                make informed decisions. Today, we’re proud to be at the
                forefront of digital security and compliance, helping businesses
                like yours thrive in an increasingly interconnected world.
              </p>
            </div>
          </div>
          <br />
          {/* <img
            src={storyImg}
            alt="Story"
            className="tw-mt-[2rem] tw-max-w-full"
          /> */}

          <img
            src={storyImg}
            srcSet={`${storyImg} 320w, ${storyImg} 1024w`}
            sizes="(max-width: 600px) 480px, 800px"
            alt="Story"
            className="tw-mt-[2rem] tw-max-w-full"
            loading="lazy"
          />
        </div>
        <br />
        <br />
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-[1.25rem] tw-mt-[.5rem] tw-w-[100%]">
          <div className="tw-flex tw-flex-col tw-items-center tw-text-center md:tw-w-[820px]">
            <h2 className="tw-font-GeneralSans text-[40px] smallText tw-font-[500] tw-text-title tw-font-medium tw-leading-title tw-text-center tw-mb-[1rem] tw-text-[3rem]">
              Partnered for Success
            </h2>

            <div className="tw-flex tw-flex-col tw-items-center tw-text-center tw-mb-[1rem] ">
              <p className="storyText">
                In 2024, Peleza and Prembly united to establish the most robust
                data infrastructure serving both emerging and developed markets.
              </p>
              <br />
              <p className="storyText">
                Our merger was a natural progression from a successful
                partnership. By joining forces, Prembly’s global reach combined
                with Peleza’s extensive KYB and AML services created a powerful
                synergy, positioning us as the ideal bridge for companies
                expanding between Africa and the rest of the world.
              </p>
              <br />
              <p className="storyText">
                Learn More About Our Merger{" "}
                <span style={{ fontWeight: 600 }}>
                  <a
                    style={{ fontWeight: 600 }}
                    href="https://myidentitypass.us19.list-manage.com/track/click?u=1954fa3f95658d99efa44cd11&id=c72c26cb4b&e=0892b3522c"
                    target="_blank"
                  >
                    Here
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
        <br />
        <img src={about_dots} />
      </div>
      <FloatingWhatapp />
      <Footer />
    </div>
  );
};

export default StoryPage;
