export const faqsdata = [
 
  {
    id: 1,
    question: "Can I integrate Prembly’s products with my existing platform?",
    answer:
      "You can typically integrate Prembly products with your tech stack to provide a comprehensive security and compliance system",
  },
  {
    id: 2,
    question:
      "How do Prembly products ensure compliance with industry regulations?",
    answer:
      "The product is designed to address specific compliance requirements, and through our different certifications, we assist organizations in meeting local and international compliance requirements.",
  },

  {
    id: 3,
    question: "Does Prembly share my information with others?",
    answer:
      "We will only share certain personal information with our partners and affiliates for our service provision and to resolve any incident. We may also receive information about you from them.",
  },

  {
    id: 4,
    question: "How does Prembly use my Personal Information",
    answer:
      "We may use your personal information and data which you provide along with other information for the purposes of processing, operating, and managing your access to our Site and Services.",
  },

  {
    id: 5,
    question:
      "How do you consent to Prembly’s use of your Personal Information?",
    answer:
      "While signing up for our Service, you will be required to consent to this Privacy Policy by ticking a box.",
  },

  {
    id: 6,
    question: " How is my company data protected?",
    auswer:
      "We are GDPR certified and adhere to the highest international data protection standards. ",
  },

  {
    id: 7,
    question: "How do you handle Customer Support at Prembly?",
    auswer:
      "We offer customer support around the clock. Our customer support channels include first-tier technical support with our Engineers, second-tier support via email, and resolutions online via chat options.",
  },

  {
    id: 8,
    question:
      "How often are your products updated to address new security vulnerabilities?",
    auswer:
      "Our products are updated regularly, often with new security patches and updates released as needed to address newly discovered vulnerabilities.",
  },

  {
    id:9,
    question:
      "Can Prembly’s product be customized to meet the specific needs of my organization?",
    auswer:
      "Yes, some of our products can be configured and customized to meet the specific needs of different organizations.",
  },

  {
    id: 10,
    question: "How can I get a demo",
    answer:
      "You can request a demo of the Prembly suites of products by contacting our sales team or sending a direct email to team@prembly.com",
  },

  {
    id: 11,
    question: "What is the pricing structure for the product?",
    answer:
      " The pricing structure for the product can vary depending on the specific features and options you request. You can contact our business and sales team for more pricing information.",
  },

  {
    id: 12,
    question: "How is my company data protected?",
    answer:
      " We are GDPR certified and adhere to the highest international data protection standards",
  },
  {
    id:13,
    question:"What is the purpose of Prembly’s products?",
    answer:'Our products aim to provide identity verification, security, and compliance solutions for businesses and individuals.',
  },

  {
    id:14,
    question:" Who are your products intended for?",
    answer:'Our product is intended for businesses that need to verify the identities of their customers or employees, ensure the security of personal information, and comply with industry regulations.',
  },

  {
    id:15,
    question:" How does your website ensure the security of my Business information?",
    answer:' Our website uses industry-standard encryption and secure servers to protect personal information. We also have strict security protocols to prevent unauthorized personal information access.',
  },
  {
    id:16,
    question:":How do I sign up on your website?",
    answer:'To sign up for an account on our website, visit the "Sign Up" page and provide the required information. You will then receive a confirmation email to verify your account.',
  },
  {
    id:17,
    question:'What types of identity verification services do you offer?',
    answer:'We offer various identity verification services, including document verification, facial recognition, data verification, business verification, and biometric authentication.',
  },

  {
    id:18,
    question:'How long does the verification process take?',
    answer:' Our verification process is designed to be as efficient as possible while maintaining a high-security level.',
  },

  {
    id:19,
    question:'What documents are required for verification?',
    answer:"The specific documents required for verification will depend on the type of verification being performed and the country's or industry's regulations. Common examples include government-issued ID, passport, and utility bills'",
  },

  {
    id:20,
    question:':How does your platform verify the authenticity of documents?',
    answer:'We use a combination of manual and automated checks to verify the authenticity of documents. This can include checking for visible signs of forgery, comparing information on the document to data from government databases, and using advanced technologies like optical character recognition (OCR) to read and verify the information on the document.',
  },
  {
    id:21,
    question:'What services do you offer on your platform?',
    answer:'We offer a range of identity verification, security, and compliance services,  including data verification, document verification, facial recognition, biometric authentication, background checks, and more.',
  },
  {
    id:22, 
    question:"How can your services benefit my business?",
    answer:"Our services can help your business to verify the identities of customers and employees, ensuring security and compliance with industry regulations. This can help prevent fraud, protect personal information, and improve customer experience."
  },

  {
    id:23, 
    question:"Can I customize the services to fit my business needs?",
    answer:"Yes, we offer customizable solutions that can be tailored to fit the specific needs of your business. Our team can work with you to design and implement a solution that meets your unique requirements."
  },

  {
    id:24, 
    question:"How do I get started with using your services?",
    answer:"To get started using any of our services, visit our website and contact our sales team. They will help you understand our services and how they can benefit your business. They will also assist you in setting up an account and starting the process."
  },

  {
    id:25, 
    question:" Are your services compliant with industry regulations?",
    answer:"Yes, our services are compliant with industry regulations and standards. We stay up-to-date with the latest regulations and guidelines to ensure that our services meet the requirements of our customers and the industry."
  },


  {
    id:26, 
    question:"How do I pay for the services on your website?",
    answer:"Payment can be made through various methods, including credit cards, virtual account numbers, and bank transfers."
  },

  {
    id:27, 
    question:"Are there any hidden costs associated with using your services?",
    answer:"No hidden costs are associated with using our services. The pricing for our services is clearly outlined on our website/customer dashboard."
  },

  {
    id:28, 
    question:"How will I be charged for the services?",
    answer:"Depending on your chosen plan, you will be charged based on the endpoint you integrate and how frequently you make calls."
  },

  {
    id:29, 
    question:"What types of reports do you provide?",
    answer:"We provide different types of reports, called call logs, depending on the product you integrate with."
  },

  {
    id:30, 
    question:"How do I access my reports?",
    answer:"You can access your reports by logging into your dashboard. You will have access to all the reports generated on your account."
  },

  {
    id:31, 
    question:" Can I export my reports in different formats?",
    answer:"We permit you to export and download your call logs in PDF, CSV, and Excel formats."
  },

  {
    id:32, 
    question:"How often are reports generated?",
    answer:"You can generate your reports whenever you want to or mostly at the end of every month."
  },

  {
    id:33, 
    question:"What information do the reports contain?",
    answer:"Reports contain information related to identity verification, compliance, and security, such as user data, verification status, compliance status, and security alerts. The specific information in the report will depend on the type of product you’re integrating and the parameters set for that report."
  },

  
];

export const allFaqs = [

  {
    id: 1,
    question:"Can I integrate Prembly’s products with my existing platform?",
    answer: "You can typically integrate Prembly products with your tech stack to provide a comprehensive security and compliance system",
  },

  {
    id:2,
    question: "How do Prembly products ensure compliance with industry regulations?",
    answer: "The product is designed to address specific compliance requirements, and through our different certifications, we assist organizations in meeting local and international compliance requirements.",
  },

  {
    id: 3,
    question:"How do Prembly products ensure compliance with industry regulations?",
    answer:"The product is designed to address specific compliance requirements, and through our different certifications, we assist organizations in meeting local and international compliance requirements.",
  },
  {
    id: 4,
    question: "Does Prembly share my information with others?", 
    answer: "We will only share certain personal information with our partners and affiliates for our service provision and to resolve any incident. We may also receive information about you from them.", 
  },

  {
    id: 5,
    question: "How does Prembly use my Personal Information? ", 
    answer: "We may use your personal information and data which you provide along with other information for the purposes of processing, operating, and managing your access to our Site and Services.", 
  },
  {
    id:6,
    question:"How is my Personal Information protected?",
    answer: "We restrict access to your information to our employees, partners, affiliates, and others who require it in relation to the provision of our products and Services."
  },

  {
    id:7,
    question: "How do you consent to Prembly’s use of your Personal Information?",
    answer: "While signing up for our Service, you will be required to consent to this Privacy Policy by ticking a box."
  },

  {
    id:8,
    question: "How do you handle Customer Support at Prembly?",
    answer: "We offer customer support around the clock. Our customer support channels include first-tier technical support with our Engineers, second-tier support via email, and resolutions online via chat options.",
  },

  {
    id: 9,
    question:"How often are your products updated to address new security vulnerabilities?",
    answer: "Our products are updated regularly, often with new security patches and updates released as needed to address newly discovered vulnerabilities.",
  },

  {
    id: 10,
    question:"Can Prembly’s product be customized to meet the specific needs of my organization?",
    answer: "Yes, some of our products can be configured and customized to meet the specific needs of different organizations."
  },

  {
    id:11,
    question:"How can I get a demo?",
    answer: "You can request a demo of the Prembly suites of products by contacting our sales team or sending a direct email to team@prembly.com",
  },

  {
    id:12,
    question:"What is the pricing structure for the product?",
    answer: "The pricing structure for the product can vary depending on the specific features and options you request. You can contact our business and sales team for more pricing information.",

  },
  {
    id:13,
    question:"How is my company data protected?",
    answer: "We are GDPR certified and adhere to the highest international data protection standards.", 

  }
 ]


export const generalData = [
  {
    id:1, 
    question:"What is the purpose of Prembly’s products?",
    answer:"Our products aim to provide identity verification, security, and compliance solutions for businesses and individuals."
  },

  {
    id:2, 
    question:"Who are your products intended for?",
    answer:"Our product is intended for businesses that need to verify the identities of their customers or employees, ensure the security of personal information, and comply with industry regulations."
  },

  {
    id:3, 
    question:"How does your website ensure the security of my Business  information?",
    answer:"  Our website uses industry-standard encryption and secure servers to protect personal information. We also have strict security protocols to prevent unauthorized personal information access."
  },

  {
    id:4, 
    question:"How do I sign up on your website?",
    answer:"To sign up for an account on our website, visit the 'Sign Up' page and provide the required information. You will then receive a confirmation email to verify your account."
  },
]

export const billingData = [

  {

    id: 1,
    question:"How do I pay for the services on your website?",
    answer:"Payment can be made through various methods, including credit cards, virtual account numbers, and bank transfers.",
  },

  {

    id: 2,
    question:"  Are there any hidden costs associated with using your services?",
    answer:" No hidden costs are associated with using our services. The pricing for our services is clearly outlined on our website/customer dashboard.",
  },
  {
    id:2,
    question:' How will I be charged for the services?',
    answer: "Depending on your chosen plan, you will be charged based on the endpoint you integrate and how frequently you make calls."
  }

]

export const serviceData = [
  {
    id:1,
    question:" What services do you offer on your platform?",
    answer: "We offer a range of identity verification, security, and compliance services,  including data verification, document verification, facial recognition, biometric authentication, background checks, and more.",
  },

  {
    id:2,
    question: "How can your services benefit my business?",
    answer: "Our services can help your business to verify the identities of customers and employees, ensuring security and compliance with industry regulations. This can help to prevent fraud, protect personal information, and improve the overall customer experience.",
  },
  {
    id:3,
    question:" Can I customize the services to fit my business needs?",
    answer:"Yes, we offer customizable solutions that can be tailored to fit the specific needs of your business. Our team can work with you to design and implement a solution that meets your unique requirements."
  },
  {
    id:4,
    question:"How do I get started with using your services?",
    answer:"To get started using any of our services, visit our website and contact our sales team. They will help you understand our services and how they can benefit your business. They will also assist you in setting up an account and starting the process.",
  },
  {
    id:5,
    question:" Can Prembly’s product be customized to meet the specific needs of my organization?",
    answer:"Yes, some of our products can be configured and customized to meet the specific needs of different organizations.",
  },

  {
    id:6,
    question:"Are your services compliant with industry regulations?",
    answer:"Yes, our services are compliant with industry regulations and standards. We stay up-to-date with the latest regulations and guidelines to ensure that our services meet the requirements of our customers and the industry.",
  }
]

export const reportsData = [
  {
    id:1,
    question:" What types of reports do you provide?",
    answer:"We provide a variety of reports, called call logs, depending on the product you integrate with.",  
  },

  {
    id:2,
    question:"How do I access my reports?",
    answer:" You can access your reports by logging into your dashboard. You will have access to all the reports generated on your account.",  
  },
  {
    id:3,
    question:" Can I export my reports in different formats?",
    answer:" Yes, you can export your reports in formats such as PDF, CSV, and Excel.",
  },

  {
    id:4,
    question:"  How often are reports generated?",
    answer:"You can generate your reports at your will.",
  },

  {
    id:5,
    question:" What information do the reports contain?",
    answer:"Reports contain information related to identity verification, compliance, and security, such as user data, verification status, compliance status, and security alerts. The specific information in the report will depend on the type of product you’re integrating and the parameters set for that report.",
  }
]

export const verificationData = [
  {
    id:1,
    question:'What types of identity verification services do you offer?',
    answer:"We offer various identity verification services, including document verification, facial recognition, data verification, business verification, and biometric authentication."
  },

  {
    id:2,
    question:"How long does the verification process take?",
    answer:"Our verification process is designed to be as efficient as possible while maintaining a high-security level."
  },

  {
    id:3,
    question:" What documents are required for verification?",
    answer:"The specific documents required for verification will depend on the type of verification being performed and the country's or industry's regulations. Common examples include government-issued ID, passport, and utility bills"
  },

  {
    id:4,
    question:"How does your platform verify the authenticity of documents?",
    answer:"We use a combination of manual and automated checks to verify the authenticity of documents. This can include checking for visible signs of forgery, comparing information on the document to data from government databases, and using advanced technologies like optical character recognition (OCR) to read and verify the information on the document."
  }
]

