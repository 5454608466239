import React, { useState, useEffect } from "react";
import axios from "axios";
import NavbarArea from "../../components/navbar";
import Footer from "../../components/newNavbar/Footer";
import { identitys } from "../../components/datas";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LatestF from "../allProduct/LatestF";
import { identityT } from "../../components/datas";
import { Link } from "react-router-dom";
import Cards from "../allProduct/Cards";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import photo from "../../assets/prembly-assest/photo.png";
import photo1 from "../../assets/prembly-assest/photo1.png";
import photo2 from "../../assets/prembly-assest/photo2.png";
import photo3 from "../../assets/prembly-assest/photo3.png";
import photo4 from "../../assets/prembly-assest/photo4.png";
import photo5 from "../../assets/prembly-assest/photo5.png";
import photo6 from "../../assets/prembly-assest/photo6.png";
import gallery6 from "../../assets/events/gallery6.png";
import mention1 from "../../assets/events/mention1.png";
import mention3 from "../../assets/events/mention3.png";
import dia from "../../assets/dia1.png";
import NavBar2 from "../../components/newNavbar/NavBar";

export default function DiolougueII() {
  const [arrowDirection, setArrowDirection] = useState(1);
  const [pressData, setPressData] = useState([]);

  const arrowAngleRight = () => {
    setArrowDirection(2);
  };

  const arrowAngleLeft = () => {
    setArrowDirection(1);
  };

  useEffect(() => {
    axios
      .get(
        "https://cms-backend.myidentitypass.com/resource/press/collections/0"
      )
      .then((res) => {
        console.log(res);
        setPressData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div style={{ background: "#ffffff" }}>
      <NavBar2 />
      {/* <NavbarArea /> */}
      <div className="whiteBg"></div>
      <section className="dialogueII">
        <div className="container align-items-center ">
          <div className="event-titles ">
            <h1 className="">
              <strong style={{ color: "#37b7ab" }}>Prembly</strong> Compliance
              Industry Dialogue II
            </h1>
          </div>
        </div>
      </section>

      <section className="py-5 my-5">
        <div className="container">
          <div className="event-resvp col-md-12 mx-auto">
            <div className="dialogue-header" style={{ color: "#37b7ab" }}>
              <h1>PREMBLY COMPLIANCE INDUSTRY DIALOGUE II</h1>
            </div>

            <div className="">
              <h5 style={{ fontSize: "17px" }}>
                <strong>
                  23RD OF JULY, 2023 | FOURPOINT BY SHERATON, VICTORIA ISLAND,
                  LAGOS
                </strong>
              </h5>
              <p style={{ fontSize: "17px" }}>
                <strong style={{ fontWeight: "900", color: "#000000" }}>
                  “The Future of Financial Regulations in Nigeria”
                </strong>{" "}
                brought together key industry experts, regulators, and
                representatives from financial institutions to discuss the
                evolving landscape of regulatory frameworks and the challenges
                faced in ensuring compliance.
              </p>

              <p style={{ fontSize: "17px; " }}>
                The event hosted by Prembly, aimed to foster transparency and
                dialogue between regulators and the regulated, encouraging a
                better understanding of the rationale behind regulations and
                their impact on the financial services sector.
              </p>
              <p style={{ fontSize: "17px" }}>
                The discussion focused on the need for innovative approaches to
                regulation, cybersecurity, and the importance of localizing
                policies for the Nigerian market. The speakers included Olufemi
                Shobanjo (NGX), Ayo Adesina (Polaris Bank), Kolade Adebayo (NGX
                Group), Anthony Aggreh (Capitalfield Financial Management Ltd),
                and moderated by Adenike Oyetunde-Lawal.
              </p>
            </div>
            <div className="">
              <a
                href="https://blog.prembly.com/ourBlog"
                className="btn btn-green1 me-1 mt-3"
                target="_blank"
                rel="noreferrer"
              >
                Read More
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="animation" className="animation-area py-5 ">
        <div className="container">
          <div className="col-md-9 mx-auto py-5 video-area-dialogue">
            <video
              className="w-100"
              controls
              poster="https://prembly-assets.s3.us-east-2.amazonaws.com/Prembly+Day+1+Highlight.mp4"
            >
              <source
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/Prembly+Day+1+Highlight.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </section>

      <section className="gallery-areas pb-5 ">
        <div className="container">
          <div
            className="col-md-12 mx-auto"
            style={{ padding: "5rem 0 4rem 0" }}
          >
            <div className="text-center" style={{ padding: "0 0 3rem 0" }}>
              <h1>Photos</h1>
              <p>
                If you missed the Prembly Compliance Dialogue Event, here are
                the highlights. Check the photos below to explore!
              </p>
            </div>

            {/* <div>
              <Swiper
                effect={"coverflow"}
                grabCursor={false}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: false,
                }}
                pagination={false}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src={photo1} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={photo2} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={photo3} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={photo4} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={photo5} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={photo6} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={photo1} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={photo2} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={photo3} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={photo4} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={photo5} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={photo6} />
                </SwiperSlide>
               
              </Swiper>
            </div> */}

            <>
              <Slider {...settings}>
                <div className="swiper-slide">
                  <img src={photo1} />
                </div>
                <div className="swiper-slide">
                  <img src={photo2} />
                </div>
                <div className="swiper-slide">
                  <img src={photo3} />
                </div>
                <div className="swiper-slide">
                  <img src={photo4} />
                </div>
                <div className="swiper-slide">
                  <img src={photo5} />
                </div>
                <div className="swiper-slide">
                  <img src={photo6} />
                </div>
              </Slider>
            </>
          </div>
        </div>
      </section>

      {/* <div className="gallery">
        <div className="container py-5">
          <div className="d-flex row mx-auto">
            <div className="col-md-10 mx-auto  align-items-center mx-auto pt-5">
              <div className="text-center">
                <h1 className="">Gallery</h1>
              </div>
              <div className="row align-items-center mx-auto justify-content-center pt-2 gx-3">
                <div className="col-md-10 col-lg-4 align-items-center mx-auto justify-content-center galleryfun">
                  <div className="insta-img">
                    <img src={gallery1} className="w-100 " alt="" />
                  </div>

                  <div className="insta-img">
                    <img src={gallery2} className="w-100 " alt="" />
                  </div>
                </div>

                <div className="col-md-10 col-lg-4 align-items-center mx-auto justify-content-center galleryfun">
                  <div className="insta-img">
                    <img src={gallery4} className="w-100 " alt="" />
                  </div>
                  <div className="insta-img">
                    <img src={gallery3} className="w-100 " alt="" />
                  </div>
                </div>

                <div className="col-md-10 col-lg-4 align-items-center mx-auto justify-content-center galleryfun">
                  <div className="insta-img">
                    <img src={gallery5} className="w-100 " alt="" />
                  </div>
                  <div className="insta-img">
                    <img src={gallery6} className="w-100 " alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <section className="mentions">
        <div className="container py-5 connect">
          <div className="connects">
            <div className="adjust-content ">
              <div className="tes">
                <h5>MEDIA MENTIONS</h5>
              </div>
              <div className="newsContainer ">
                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img
                        src="https://prembly-assets.s3.us-east-2.amazonaws.com/smalls.png"
                        className="news-header w-100"
                        alt=""
                      />
                    </div>

                    <div className="new-body">
                      <a
                        href="https://techbuild.africa/prembly-compliance-tracker-financial-regulations/"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          Prembly Unveils Compliance Tracker to Enhance
                          Compliance in Financial Sector
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>22 July 2023</p>
                    </div>
                  </div>
                </div>

                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img src={photo} className="news-header w-100" alt="" />
                    </div>
                    <div className="new-body">
                      <a
                        href="https://technext24.com/2023/07/27/premblys-financial-compliance-parley/"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          Regulations must be flexible to accommodate
                          innovation- Experts at Prembly’s compliance parley
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>22 July 2023</p>
                    </div>
                  </div>
                </div>

                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img src={photo1} className="news-header w-100" alt="" />
                    </div>
                    <div className="new-body">
                      <a
                        href="https://techeconomy.ng/prembly-unveils-compliance-tracker-to-enhance-compliance-in-financial-secto"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          Prembly Unveils Compliance Tracker to Enhance
                          Compliance in Financial Sector
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>22 July 2023</p>
                    </div>
                  </div>
                </div>

                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img
                        src={mention1}
                        className="news-header w-100"
                        alt=""
                      />
                    </div>
                    <div className="new-body">
                      <a
                        href="https://businessday.ng/news/article/cbn-to-clean-up-tier-1-accounts-not-linked-to-bvn/"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          CBN to clean up Tier-1 accounts not linked to BVN
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>6 April 2023</p>
                    </div>
                  </div>
                </div>

                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img
                        src={gallery6}
                        className="news-header w-100"
                        alt=""
                      />
                    </div>
                    <div className="new-body">
                      <a
                        href="https://www.thisdaylive.com/index.php/2023/04/13/insider-driven-fraud-biggest-challenge-facing-nigerias-finance-industry-experts-say"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          Insider-Driven Fraud Biggest Challenge Facing
                          Nigeria’s Finance Industry, Experts Say
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>6 April 2023</p>
                    </div>
                  </div>
                </div>

                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img
                        src={mention3}
                        className="news-header w-100"
                        alt=""
                      />
                    </div>
                    <div className="new-body">
                      <a
                        href="https://www.linkedin.com/feed/update/urn:li:activity:7052294370208694272/"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          Issues around compliance are not negotiable in the
                          financial sector - Dr. Blaise Ijebor, Chief Risk
                          Officer, CBN
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>6 April 2023</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="adjust-content">
              <div className="tes">
                <h5>PAST EVENTS</h5>
              </div>
              <div className="newsContainer">
                <>
                  <Link
                    to="/event/dialogueII"
                    style={{
                      textDecoration: "none",
                      color: "#000000",
                    }}
                  >
                    <div className="newsCard">
                      <div className="card mb-5">
                        <div className="selectCompliance">
                          <div className="selectComTitle">
                            <p>COMPLIANCE</p>
                          </div>
                          <img
                            src="https://prembly-assets.s3.us-east-2.amazonaws.com/smalls.png"
                            className="news-header w-100"
                            alt=""
                          />
                        </div>
                        <div className="new-body">
                          <h5 className="pb-3">
                            Prembly Compliance Industry Dialogue II
                          </h5>

                          <hr style={{ width: "60px", marginTop: "-10px" }} />
                          <p>22 July 2023</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </>
                <>
                  <Link
                    to="/event/dialogue"
                    style={{
                      textDecoration: "none",
                      color: "#000000",
                    }}
                  >
                    <div className="newsCard">
                      <div className="card mb-5">
                        <div className="selectCompliance">
                          <div className="selectComTitle">
                            <p>COMPLIANCE</p>
                          </div>
                          <img src={dia} className="news-header w-100" alt="" />
                        </div>
                        <div className="new-body">
                          <h5 className="pb-3">
                            Prembly Compliance Industry Dialogue I
                          </h5>

                          <hr style={{ width: "60px", marginTop: "-10px" }} />
                          <p>22 April 2023</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="mentions">
        <div className="container pt-5 d-flex justify-content-center">
          <div className="adjust-content ">
            <div className="tes media-header-content">
              <h5>MEDIA MENTIONS</h5>
            </div>
            <div className="newsContainer ">
              <div className=" newsCard">
                <div className="card mb-5">
                  <div className="selectCompliance">
                    <div className="selectComTitle">
                      <p>COMPLIANCE</p>
                    </div>
                    <img
                      src="https://prembly-assets.s3.us-east-2.amazonaws.com/smalls.png"
                      className="news-header w-100"
                      alt=""
                    />
                  </div>

                  <div className="new-body">
                    <a
                      href="https://techbuild.africa/prembly-compliance-tracker-financial-regulations/"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        color: "#000000",
                      }}
                    >
                      <h5 className="pb-3">
                        Prembly Unveils Compliance Tracker to Enhance Compliance
                        in Financial Sector
                      </h5>
                    </a>

                   
                    <hr style={{ width: "60px", marginTop: "-10px" }} />
                    <p>22 July 2023</p>
                  </div>
                </div>
              </div>

              <div className=" newsCard">
                <div className="card mb-5">
                  <div className="selectCompliance">
                    <div className="selectComTitle">
                      <p>COMPLIANCE</p>
                    </div>
                    <img src={photo} className="news-header w-100" alt="" />
                  </div>
                  <div className="new-body">
                    <a
                      href="https://technext24.com/2023/07/27/premblys-financial-compliance-parley/"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        color: "#000000",
                      }}
                    >
                      <h5 className="pb-3">
                        Regulations must be flexible to accommodate innovation-
                        Experts at Prembly’s compliance parley
                      </h5>
                    </a>

                   
                    <hr style={{ width: "60px", marginTop: "-10px" }} />
                    <p>22 July 2023</p>
                  </div>
                </div>
              </div>

              <div className=" newsCard">
                <div className="card mb-5">
                  <div className="selectCompliance">
                    <div className="selectComTitle">
                      <p>COMPLIANCE</p>
                    </div>
                    <img src={photo1} className="news-header w-100" alt="" />
                  </div>
                  <div className="new-body">
                    <a
                      href="https://techeconomy.ng/prembly-unveils-compliance-tracker-to-enhance-compliance-in-financial-secto"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        color: "#000000",
                      }}
                    >
                      <h5 className="pb-3">
                        Prembly Unveils Compliance Tracker to Enhance Compliance
                        in Financial Sector
                      </h5>
                    </a>

                    <hr style={{ width: "60px", marginTop: "-10px" }} />
                    <p>22 July 2023</p>
                  </div>
                </div>
              </div>

              <div className=" newsCard">
                <div className="card mb-5">
                  <div className="selectCompliance">
                    <div className="selectComTitle">
                      <p>COMPLIANCE</p>
                    </div>
                    <img src={mention1} className="news-header w-100" alt="" />
                  </div>
                  <div className="new-body">
                    <a
                      href="https://businessday.ng/news/article/cbn-to-clean-up-tier-1-accounts-not-linked-to-bvn/"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        color: "#000000",
                      }}
                    >
                      <h5 className="pb-3">
                        CBN to clean up Tier-1 accounts not linked to BVN
                      </h5>
                    </a>

                  
                    <hr style={{ width: "60px", marginTop: "-10px" }} />
                    <p>6 April 2023</p>
                  </div>
                </div>
              </div>

              <div className=" newsCard">
                <div className="card mb-5">
                  <div className="selectCompliance">
                    <div className="selectComTitle">
                      <p>COMPLIANCE</p>
                    </div>
                    <img src={gallery6} className="news-header w-100" alt="" />
                  </div>
                  <div className="new-body">
                    <a
                      href="https://www.thisdaylive.com/index.php/2023/04/13/insider-driven-fraud-biggest-challenge-facing-nigerias-finance-industry-experts-say"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        color: "#000000",
                      }}
                    >
                      <h5 className="pb-3">
                        Insider-Driven Fraud Biggest Challenge Facing Nigeria’s
                        Finance Industry, Experts Say
                      </h5>
                    </a>

                   
                    <hr style={{ width: "60px", marginTop: "-10px" }} />
                    <p>6 April 2023</p>
                  </div>
                </div>
              </div>

              <div className=" newsCard">
                <div className="card mb-5">
                  <div className="selectCompliance">
                    <div className="selectComTitle">
                      <p>COMPLIANCE</p>
                    </div>
                    <img src={mention3} className="news-header w-100" alt="" />
                  </div>
                  <div className="new-body">
                    <a
                      href="https://www.linkedin.com/feed/update/urn:li:activity:7052294370208694272/"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        color: "#000000",
                      }}
                    >
                      <h5 className="pb-3">
                        Issues around compliance are not negotiable in the
                        financial sector - Dr. Blaise Ijebor, Chief Risk
                        Officer, CBN
                      </h5>
                    </a>

                    
                    <hr style={{ width: "60px", marginTop: "-10px" }} />
                    <p>6 April 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mentions">
        <div className="container pt-5 d-flex justify-content-center">
          <div className="adjust-content">
            <div className="tes">
              <h5>PAST EVENTS</h5>
            </div>
            <div className="newsContainer">
              <Link
                to="event/dialogueII"
                style={{
                  textDecoration: "none",
                  color: "#000000",
                }}
              >
                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img
                        src="https://prembly-assets.s3.us-east-2.amazonaws.com/smalls.png"
                        className="news-header w-100"
                        alt=""
                      />
                    </div>
                    <div className="new-body">
                      <Link
                        to="/event/dialogueII"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          Prembly Compliance Industry Dialogue II
                        </h5>
                      </Link>

                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>22 July 2023</p>
                    </div>
                  </div>
                </div>
              </Link>

              <Link
                to="/event/dialogue"
                style={{
                  textDecoration: "none",
                  color: "#000000",
                }}
              >
                <div className="newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img src={dia} className="news-header w-100" alt="" />
                    </div>
                    <div className="new-body">
                      <h5 className="pb-3">
                        Prembly Compliance Industry Dialogue I
                      </h5>

                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>22 April 2023</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section> */}

      <section className="countries-section py-5">
        <div className="container">
          <div className="row align-items-center mx-auto justify-content-center countries-gap">
            <div className="col-md-10 col-lg-6 countries-list ">
              <div className="global-color mb-4">
                <p>Global</p>
              </div>
              <h1>Our solution cut across 100+ countries</h1>
              <h5 className="mt-3 mb-4">
                Prembly's extensive range of over 100 APIs can be utilised by
                businesses worldwide to enhance their identity verification and
                authentication processes. Our dedicated teams, located globally,
                are ready to assist you and your customers wherever your
                operations may be
              </h5>
              <div className="countriesFlag">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/countries.svg"
                  alt=""
                />
              </div>

              <div className="d-flex align-items-center">
                <Link
                  to="/about"
                  className="btn btn-green1  mt-4 me-1"
                  rel="noreferrer"
                >
                  Learn More
                </Link>
              </div>
            </div>

            <div className="col-md-10 col-lg-5">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/world-new.gif"
                alt=""
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="feedback" className="feedback-prembly-area">
        <div className="container">
          <div className="col-md-12 col-lg-11 mx-auto py-lg-5 text-center">
            <h1>Our Customers Perspective</h1>
            {arrowDirection === 1 && (
              <div className=" d-flex align-items-center py-5 ">
                <div className="showflex row ">
                  {identitys.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      <Cards
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />
                    </div>
                  ))}
                </div>
                <img
                  onClick={arrowAngleRight}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindright.svg"
                  alt=""
                  className="ms-2"
                />
              </div>
            )}

            {arrowDirection === 2 && (
              <div className=" d-flex align-items-center py-5 ">
                <img
                  onClick={arrowAngleLeft}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindleft.svg"
                  alt=""
                  className="me-2"
                />
                {/* <div className=" showflex"> */}
                <div className="showflex row ">
                  {identityT.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      {/* <Slider {...settings}> */}

                      <Cards
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />

                      {/* </Slider> */}
                    </div>
                  ))}
                </div>
                {/* <img src={rewindright} alt="" className="ms-2"/> */}
              </div>
            )}
          </div>
        </div>
      </section>

      <LatestF />
      <Footer />
    </div>
  );
}
