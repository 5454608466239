import React from "react";
import { Accordion } from "react-bootstrap";
// import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export default function PartnershipFAQ() {
//   const [activeFaq, setActiveFaq] = useState(0);
  return (
    <div>
      <div className="faq">
        <Accordion defaultActiveKey="0" className="">
          <Accordion.Item eventKey="0" className="item">
            <Accordion.Header>
                How do I join the Partner Program? 
            </Accordion.Header>
            <Accordion.Body>
                Simply fill out the form above to express your interest in becoming 
                a partner. Our team will review your application and reach out to discuss 
                potential collaboration opportunities.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion defaultActiveKey="0" className="">
          <Accordion.Item eventKey="1" className="item">
            <Accordion.Header>
                What benefits do partners receive? 
            </Accordion.Header>
            <Accordion.Body>
                Partners enjoy a range of benefits tailored to their specific partnership 
                type, including access to resources, support from our team, maximum ROI and 
                opportunities for co-marketing and networking.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion defaultActiveKey="0" className="">
          <Accordion.Item eventKey="1" className="item">
            <Accordion.Header>
                Can I be involved in multiple partnership types? 
            </Accordion.Header>
            <Accordion.Body>
                Absolutely! We welcome partners who wish to explore multiple avenues of 
                collaboration with us. Our team will work with you to tailor a partnership 
                strategy that aligns with your business goals.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion defaultActiveKey="0" className="">
          <Accordion.Item eventKey="1" className="item">
            <Accordion.Header>
                How are commissions calculated for reseller partners? 
            </Accordion.Header>
            <Accordion.Body>
                Commissions for reseller partners are based on a percentage of the transaction 
                value generated by the customers they refer to us. Our partner team will provide 
                detailed information on commission structures during the onboarding process.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion defaultActiveKey="0" className="">
          <Accordion.Item eventKey="1" className="item">
            <Accordion.Header>
                What support is available for technology integration?
            </Accordion.Header>
            <Accordion.Body>
                Our dedicated technical team is on hand to provide support and guidance throughout 
                the integration process. We offer documentation, developer resources, and direct 
                assistance to ensure a smooth and successful integration experience.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}
