import React, { useState } from "react";
import NavbarArea from "../../components/navbar";
import Footer from "../../components/footer";
// import get from "../../assets/get.png";
import LatestF from "../allProduct/LatestF";
// import radarimg from "../../assets/radarimg.png";
// import countries from "../../assets/countries.svg";
// import rewind1 from "../../assets/rewindleft.svg";
// import rewindright from "../../assets/rewindright.svg";
import { identityT } from "../../components/datas";
import { identitys } from "../../components/datas";
import Cards from "../allProduct/Cards";
import allImg from "../../assets/allLogo.png";
import {  Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Mobility() {
  const [arrowDirection, setArrowDirection] = useState(1);
  const arrowAngleRight = () => {
    setArrowDirection(2);
  };

  const arrowAngleLeft = () => {
    setArrowDirection(1);
  };
  return (
    <div style={{ background: "#ffffff" }}>
        <Helmet>
        <meta name="description" content="A Compliance and Security Company" />
        <meta
          property="og:title"
          content="Prembly-A Compliance and Security Company"
        />
      </Helmet>
      <NavbarArea />
      <div className="whiteBg"></div>
      <div className="hero-section sector-area py-5">
        <div className="container-fluid py-3">
          <div className="row align-items-center mx-auto justify-content-between ">
            <div className="col-md-12 col-lg-6">
              <div className="d-flex ">
                <a
                  className="btn btn-graph  black-graph px-md-2 px-4 py-2 mt-0 "
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  Mobility
                </a>
              </div>
              <h1 className="py-3">
                Verification and security service for mobility providers
              </h1>
              <h5 className="mt-2">
                Vehicle rental and car-sharing spaces prioritise convenience and
                safety. Prembly's simple and fast verification solutions
                guarantee a smooth experience for end-users and mobility
                players.
              </h5>

              <div className="d-flex align-items-center">
                <a
                  className="btn btn-green1  me-1"
                  href="https://account.prembly.com/signUp"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get Started
                </a>

                <Link 
                      to="/contact"
                      className="btn btn-black1 px-md-4 py-3 mt-3"
                    >
                  <span>Talk to Sales</span>
                  <i class="ri-arrow-right-line ms-2"></i>
              </Link>
                
              </div>
            </div>

            <div className="col-md-12 col-lg-5 ">
              <img src='https://prembly-assets.s3.us-east-2.amazonaws.com/ice.jpg' alt="landing" className="w-100" />
            </div>
          </div>
        </div>
      </div>

      <section
        id="partners"
        className="loved-by-section black-sector pt-5  text-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="">
          <div>
            <h4>We are trusted by all top financial institutions</h4>

            <div className="marquee">
              <div className="marquee-content">
                <ul>
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>

                <ul>
                  {" "}
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>
                <ul>
                  {" "}
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section id="" className="how-can-premblyhelp-area  ">
        <div className="">
          <div className="text-center col-md-11  mx-auto sectors py-3 ">
            <h1 className="mb-4">How Prembly is simplifying verification in mobility</h1>
          </div>

          <div className="row align-items-center   verifyallapplicant justify-content-center" id='columforbg'>
            <div className="col-md-10 col-lg-5 verify-applicant">
              <div className="verify-applicants">
                <img src='https://prembly-assets.s3.us-east-2.amazonaws.com/driver.jpg' alt="" />
              </div>
            </div>

            <div className="col-md-10 col-lg-5 verifyapplicantstodaygreen">
              <h1>Driver’s Verification</h1>
              <p className="mt-4 ">
                Get instant access to trusted driver verification and experience
                seamless driver verification with Identitypass. 
              </p>
              <div className="d-flex align-items-center">
                <a
                  className="btn btn-green1  me-1"
                  href="http://account.prembly.com/signUp"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Sales
                </a>
              </div>
            </div>
          </div>

          <div className=" row align-items-center justify-content-center  verifyallapplicant seperate-contentsblue">
            <div className="col-md-10 col-lg-5 verifyapplicantstodaygreen">
              <h1>Plate Number Verification</h1>
              <p className="mt-4 ">
                Smoothly track and identify vehicles with just a plate number.
                Never onboard stolen vehicles on your platform. 
              </p>
              <div className="d-flex align-items-center">
                <a
                  className="btn btn-green1  me-1"
                  href="https://calendly.com/premblysalesteam/45mins"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Sales
                </a>
              </div>
            </div>

            <div className="col-md-10 col-lg-5 verify-applicant">
              <div className="verify-applicants">
                <img src='https://prembly-assets.s3.us-east-2.amazonaws.com/plate.jpg' alt="" />
              </div>
            </div>
          </div>

          <div className="row align-items-center   verifyallapplicant justify-content-center" id='columforbg'>
            <div className="col-md-10 col-lg-5 verify-applicant">
              <div className="verify-applicants">
                <img src='https://prembly-assets.s3.us-east-2.amazonaws.com/chassis.jpg' alt="" />
              </div>
            </div>

            <div className="col-md-10 col-lg-5 verifyapplicantstodaygreen">
              <h1>Chassis Number Verification</h1>
              <p className="mt-4 ">
                Get accurate, reliable vehicle information and verify vehicles’
                authenticity with our secure car chassis verification product. 
              </p>
              <div className="d-flex align-items-center">
                <a
                  className="btn btn-green1  me-1"
                  href="https://calendly.com/premblysalesteam/45mins"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Sales
                </a>
              </div>
            </div>
          </div>

          <div className=" row align-items-center justify-content-center  verifyallapplicant seperate-contentsblue">
            <div className="col-md-10 col-lg-5 verifyapplicantstodaygreen">
              <h1>Instant Age Verification</h1>
              <p className="mt-4 ">
                Verify drivers' age accurately,  protect your business from
                liability and ensure compliance with our age verification
                solution.
              </p>
              <div className="d-flex align-items-center">
                <a
                  className="btn btn-green1  me-1"
                  href="https://calendly.com/premblysalesteam/45mins"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Sales
                </a>
              </div>
            </div>

            <div className="col-md-10 col-lg-5 verify-applicant">
              <div className="verify-applicants">
                <img src='https://prembly-assets.s3.us-east-2.amazonaws.com/age.svg' alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="countries-section py-5">
        <div className="container">
          <div className="col-lg-12 mx-auto">
          <div className="row align-items-center mx-auto justify-content-center  countries-gap">
            <div className="col-md-10 col-lg-6 countries-list ">
              <div className="global-color mb-4">
                <p>Global</p>
              </div>
              <h1>Our solution cut across 100+ countries</h1>
              <h5 className="mt-3 mb-4">
                Prembly's extensive range of over 100 APIs can be utilised by
                businesses worldwide to enhance their identity verification and
                authentication processes. Our dedicated teams, located globally,
                are ready to assist you and your customers wherever your
                operations may be
              </h5>
              <div className="countriesFlag">
                <img src='https://prembly-assets.s3.us-east-2.amazonaws.com/countries.svg' alt="" />
              </div>

              <div className="d-flex align-items-center">
                <Link to="/about"
                  className="btn btn-green1  mt-4 me-1"
                  rel="noreferrer"
                >
                  Learn More
                </Link>
              </div>
              
            </div>
            <div className="col-md-10 col-lg-5">
              <img src='https://prembly-assets.s3.us-east-2.amazonaws.com/world-new.gif' alt="" className="codeimg w-100" />
            </div>
          </div>
        </div>
        </div>
      </section>

      <section id="feedback" className="feedback-prembly-area">
        <div className="container">
          <div className="col-md-12 col-lg-11 mx-auto py-lg-5 text-center">
            <h1>Our Customers Perspective</h1>
            {arrowDirection === 1 && (
              <div className=" d-flex align-items-center py-5 ">
                <div className="showflex row ">
                  {identitys.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      <Cards
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />
                    </div>
                  ))}
                </div>
                <img
                  onClick={arrowAngleRight}
                  src='https://prembly-assets.s3.us-east-2.amazonaws.com/rewindright.svg'
                  alt=""
                  className="ms-2"
                />
              </div>
            )}

            {arrowDirection === 2 && (
              <div className=" d-flex align-items-center py-5 ">
                <img
                  onClick={arrowAngleLeft}
                  src='https://prembly-assets.s3.us-east-2.amazonaws.com/rewindleft.svg'
                  alt=""
                  className="me-2"
                />

                <div className="showflex row ">
                  {identityT.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      <Cards
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

     
      <LatestF />
      <Footer />
    </div>
  );
}
