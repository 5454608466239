import React, { useState } from "react";
import NavbarArea from "../../components/navbar";
import Footer from "../../components/footer";
// import get from "../../assets/get.png";
import LatestF from "../allProduct/LatestF";
// import radarimg from "../../assets/radarimg.png";
// import world from "../../assets/world-new.gif";
// import rewind1 from "../../assets/rewindleft.svg";
// import rewindright from "../../assets/rewindright.svg";
import { identityT } from "../../components/datas";
import { identitys } from "../../components/datas";
import Cards from "../allProduct/Cards";
import { Link } from "react-router-dom";
import allImg from "../../assets/allLogo.png";
import { Helmet } from "react-helmet";


export default function Sector() {
  const [productTab, setProductTab] = useState(1);
  const [solutionTab, setSolutionTab] = useState(1);
  const [arrowDirection, setArrowDirection] = useState(1);

  const carouselBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 5 },
  ];

  const arrowAngleRight = () => {
    setArrowDirection(2);
  };

  const arrowAngleLeft = () => {
    setArrowDirection(1);
  };

  return (
    <div style={{ background: "#ffffff" }}>
       <Helmet>
        <meta name="description" content="A Compliance and Security Company" />
        <meta
          property="og:title"
          content="Prembly-A Compliance and Security Company"
        />
      </Helmet>
      <NavbarArea />
      <div className="whiteBg"></div>
      <div className="hero-section sector-area py-5">
        <div className="container-fluid py-3">
          <div className="row align-items-center mx-auto justify-content-between">
            <div className="col-md-12 col-lg-6">
              <div className="d-flex ">
                <a
                  className="btn btn-graph black-graph px-md-2 px-4 py-2 mt-0 "
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  Financial Sector
                </a>
              </div>
              <h1 className="py-3">
                Acquire more customers while ensuring fraud reduction 
              </h1>
              <h5 className="mt-2">
                Utilize various technologies such as biometrics, machine
                learning, and AI-powered authentication to precisely and quickly
                verify your user's identity, reducing the risk of fraud and
                streamlining the onboarding process.
              </h5>

              <div className="d-flex align-items-center">
                <a
                  className="btn btn-green1  me-1"
                  href="https://account.prembly.com/signUp"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get Started
                </a>

                <Link
                  to="/contact"
                  className="btn btn-black1 px-md-4 py-3 mt-3"
                >
                  <span>Talk to Sales</span>
                  <i class="ri-arrow-right-line ms-2"></i>
                </Link>
              </div>
            </div>

            <div className="col-md-12 col-lg-5 ">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/radarimg.png"
                alt="landing"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </div>

      <section
        id="partners"
        className="loved-by-section black-sector pt-5  text-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="">
          <div>
            <h4>We are trusted by all top financial institutions</h4>

            <div className="marquee">
              <div className="marquee-content">
                <ul>
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>

                <ul>
                  {" "}
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>
                <ul>
                  {" "}
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="" className="how-can-premblyhelp-area">
        <div className="col-lg-12 mx-auto">
          <div className="text-center col-md-11  mx-auto sectors py-2 ">
            <h1 className="mb-4">How Prembly is helping Fintechs</h1>
          </div>

          <div
            className="row align-items-center   verifyallapplicant justify-content-center"
            id="columforbg"
          >
            <div className="col-md-10 col-lg-5 verify-applicant">
              <div className="verify-applicants">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/radarimg1.png"
                  alt=""
                />
              </div>
            </div>

            <div className="col-md-10 col-lg-5 verifyapplicantstodaygreen">
              <h1>
                Reduce onboarding friction and fraud by including extensive KYC
                checks 
              </h1>
              <p className="mt-4 ">
                Simplify your onboarding process, enhance security, and minimize
                potential fraud with our trusted verification system. 
              </p>
              <div className="d-flex align-items-center">
                <a
                  className="btn btn-green1  me-1"
                  href="https://calendly.com/premblysalesteam/45mins"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Sales
                </a>
              </div>
            </div>
          </div>

          <div className=" row align-items-center justify-content-center  verifyallapplicant seperate-contentsblue">
            <div className="col-md-10 col-lg-5  verifyapplicantstodaygreen">
              <h1>
                Strike a balance between customer conversion and strong fraud
                controls 
              </h1>
              <p className="mt-4 ">
                Stabilize the need for quick and seamless transactions with
                robust fraud controls for a secure and trusted customer
                experience. 
              </p>
              <div className="d-flex align-items-center">
                <a
                  className="btn btn-green1  me-1"
                  href="https://calendly.com/premblysalesteam/45mins"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Sales
                </a>
              </div>
            </div>

            <div className="col-md-10 col-lg-5 verify-applicant">
              <div className="verify-applicants">
                {/* <img src={radarimg4} alt="" /> */}
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/radarimg2.png"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            className=" row align-items-center  justify-content-center  verifyallapplicant "
            id="columforbg"
          >
            <div className="col-md-10 col-lg-5 verify-applicant">
              <div className="verify-applicants">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/radarimg4.png"
                  alt=""
                />
              </div>
            </div>

            <div className="col-md-10 col-lg-5 verifyapplicantstodaygreen">
              <h1>Conduct smooth ongoing customer monitoring   </h1>
              <p className="mt-4 ">
                Stay ahead of potential issues with ongoing customer monitoring
                designed to keep your business running smoothly. 
              </p>

              <div className="d-flex align-items-center">
                <a
                  className="btn btn-green1  me-1"
                  href="https://calendly.com/premblysalesteam/45mins"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Sales
                </a>
              </div>
            </div>
          </div>

          <div className=" row align-items-center  justify-content-center  verifyallapplicant seperate-contentsblue">
            <div className="col-md-10 col-lg-5  verifyapplicantstodaygreen">
              <h1>Improve Customer transaction procedure </h1>
              <p className="mt-4 ">
                Elevate your customer satisfaction and experience with a
                transaction process that's efficient and hassle-free. 
              </p>
              <div className="d-flex align-items-center">
                <a
                  className="btn btn-green1  me-1"
                  href="https://calendly.com/premblysalesteam/45mins"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Sales
                </a>
              </div>
            </div>

            <div className="col-md-10 col-lg-5 verify-applicant">
              <div className="verify-applicants">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/compute.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="countries-section py-5">
        <div className="container">
          <div className="col-lg-12 mx-auto">
            <div className="row align-items-center mx-auto justify-content-center countries-gap ">
              <div className="col-md-10 col-lg-6 countries-list ">
                <div className="global-color mb-4">
                  <p>Global</p>
                </div>
                <h1>Our solution cut across 100+ countries</h1>
                <h5 className="mt-3 mb-4">
                  Prembly's extensive range of over 100 APIs can be utilised by
                  businesses worldwide to enhance their identity verification
                  and authentication processes. Our dedicated teams, located
                  globally, are ready to assist you and your customers wherever
                  your operations may be
                </h5>
                <div className="countriesFlag">
                  <img
                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/countries.svg"
                    alt=""
                  />
                </div>

                <div className="d-flex align-items-center">
                  <Link
                    to="/about"
                    className="btn btn-green1  mt-4 me-1"
                    rel="noreferrer"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
              <div className="col-md-10 col-lg-5">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/world-new.gif"
                  alt=""
                  className="codeimg w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="feedback" className="feedback-prembly-area">
        <div className="container">
          <div className="col-md-12 col-lg-11 mx-auto py-lg-5 text-center">
            <h1>Our Customers Perspective</h1>
            {arrowDirection === 1 && (
              <div className=" d-flex align-items-center py-5 ">
                {/* <img src={rewind1} alt="" className="me-2"/> */}
                {/* <div className=" showflex"> */}
                <div className="showflex row ">
                  {identitys.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      {/* <Slider {...settings}> */}

                      <Cards
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />

                      {/* </Slider> */}
                    </div>
                  ))}
                </div>
                <img
                  onClick={arrowAngleRight}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindright.svg"
                  alt=""
                  className="ms-2"
                />
              </div>
            )}

            {arrowDirection === 2 && (
              <div className=" d-flex align-items-center py-5 ">
                <img
                  onClick={arrowAngleLeft}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindleft.svg"
                  alt=""
                  className="me-2"
                />
                {/* <div className=" showflex"> */}
                <div className="showflex row ">
                  {identityT.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      {/* <Slider {...settings}> */}

                      <Cards
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />

                      {/* </Slider> */}
                    </div>
                  ))}
                </div>
                {/* <img src={rewindright} alt="" className="ms-2"/> */}
              </div>
            )}
          </div>
        </div>
      </section>

      <LatestF />
      <Footer />
    </div>
  );
}
