//  import React from "react";

export const contactUrl = 'https://oyvub2dwme.execute-api.us-east-2.amazonaws.com/prembly-backend-dev/api/v1/account/contact-us';

// export const meetTeams = 'https://cms-backend.myidentitypass.com/resource/picture/collections'

// export const newsData = 'https://cms-backend.myidentitypass.com/resource/press/collections/0'
// export const newsData = 'https://blog.prembly.com/wp-json/wp/v2/posts'
export const newsData = 'https://blog.prembly.com/wp-json/wp/v2/posts?categories=58'


export const meetTeams = [
    //for the sake of this data:
    
    // adult_media = adult picture of the individual
    // featured_media = childhood picture of the individual
    // wanted_tagline = childhood future ambition of the individual
    // tagline_story = adult picture of the individual
    // title = Individuals name
    // subtitle = Individual's department
    // linkedin = Individual's linkedin accout url


    {
        adult_image: "Lanre.jpg",
        featured_media: "Lanre.jpg",
        wanted_tagline: "I wanted to become a mechanical Engineer",
        tagline_story: `As a kid, Lanre believed Jesus would return in the year 2000. 
            Lanre is a curious and imaginative individual with a reflective nature. He approaches challenges with a can-do mindset and inspires those around him. 
            When he's not exploring new ideas, he is in the kitchen cooking up a storm and experimenting with different cuisines. 
            With his creativity and passion for learning, Lanre is a valuable member of our team. 
        `,
        title: "Lanre Ogungbe",
        subtitle: "Management Team",
        linkedin: "https://www.linkedin.com/in/ogungbelanre/",
    },
    {
        adult_image: "Niyi2.jpg",
        featured_media: "Niyi2.jpg",
        wanted_tagline: "I wanted to become a medical doctor and a pilot",
        tagline_story: `Growing up, Niyi believed the phrase "Heaven helps those who help themselves" was a Bible passage. 
            Niyi is a cheerful and attentive individual who is always ready to help others bring out their best. 
            When he's not busy with work, you can find him indulging in his hobbies of gaming, cooking, music, photography, and sightseeing. His positive energy, organizational skills, and supportive nature make him a pleasure to work with and a valuable member of our team. 
        `,
        title: "Niyi Adegboye",
        subtitle: "Management Team",
        linkedin: "https://www.linkedin.com/in/adegboyehenry/",
    },
    {
        adult_image: "Tolu.png",
        featured_media: "Tolu.png",
        wanted_tagline: "I wanted to become a mechanical Engineer”",
        tagline_story: `Growing up, Tolu believed that The United States of America is in the sky. 
            Tolu is a goal-oriented individual with a unique personality that combines elements of both extroversion and introversion.
            In his free time, Tolu enjoys watching short video skits and surfing the internet. His ability to adapt to changing situations and drive change makes Tolu a valuable member of our team. 
        `,
        title: "Tolu Adetuyi",
        subtitle: "Management Team",
        linkedin: "https://www.linkedin.com/in/adetuyitolu/",
    },
    {
        adult_image: "Uzochi.jpg",
        featured_media: "Uzochi.jpg",
        wanted_tagline: "I wanted to become an Engineer",
        tagline_story: `Growing up. Uzochi was made to believe that ingesting a mixture of Garri (Cassava flakes) and mango would lead to immediate death. 
            Uzochi is a passionate and empathetic team member with high enthusiasm for her work. She approaches life with an open mind, making her a valuable asset to the team. 
            In her free time, she enjoys indulging in her hobbies, which include cooking, watching movies, and listening to music. 
        `,
        title: "Uzochi Agomuo",
        subtitle: "Sales Team",
        linkedin: "",
    },
    {
        adult_image: "Segun.jpg",
        featured_media: "Segun.jpg",
        wanted_tagline: "I have always wanted to be Great",
        tagline_story: `Growing up, Ajisafe  was convinced to believe that drinking coconut water would make one dull-witted. 
            Ajisafe is a calm and cool headed individual wih a massive love for playing chess.  When not at work, he is found indulging in his hobby, Playing games.
            His strategic thinking and solution-oriented mindset make him an essential member of our team. 
        `,
        title: "Segun Ajisafe",
        subtitle: "Engineering Team",
        linkedin: "",
    },
    {
        adult_image: "Ileri.jpg",
        featured_media: "Ileri.jpg",
        wanted_tagline: "I had no particular ambition in mind",
        tagline_story: ` Ileri is a diligent researcher with a massive love for watching football. He approaches each project with a thorough and analytical mindset and is committed to delivering results backed by data and facts. 
            His curiosity and diligent research skills make him such a valuable member of our team.        
        `,
        title: "Ilerioluwa Fadeyi",
        subtitle: "Engineering Team",
        linkedin: "",
    },
    {
        adult_image: "imekan.jpeg",
        featured_media: "",
        wanted_tagline: "I wanted to become a Pharmacist",
        tagline_story: `As a kid, Imekan was made to that children are the leaders to tomorrow, growing up, he realized the reverse seems to be the case.
            Imekan is a tenacious problem-solver who is passionate about helping others grow and is always looking for ways to give back to those in need.
            He is a skilled software tester who loves finding bugs in software with the aim of giving developers sleepless nights.       
        `,
        title: "Imekan Enang",
        subtitle: "Engineering Team",
        linkedin: "",
    },
    {
        adult_image: "Robiat.jpg",
        featured_media: "Robiat.jpg",
        wanted_tagline: "I wanted to become a Medical Doctor",
        tagline_story: `As a kid, Robiat believed too much consumption of noodles would make noodles grow on her head.
            Robiat is an emotional, highly optimistic, empathetic, and loving team member who always brings a positive attitude to the table. 
            With her great communication skills and collaborative spirit, she is a valuable member of our team who excels in collaborating and adapting to different situations.       
        `,
        title: "Robiat Azeez",
        subtitle: "Product Team",
        linkedin: "",
    },
    {
        adult_image: "Ifeanyi.jpg",
        featured_media: "Ifeanyi.jpg",
        wanted_tagline: "I wanted to become a Petrochemical Engineer",
        tagline_story: `Growing up, Ifeanyi believed that a simultaneous occurrence of rain and sunshine signified the birth of an elephant.
            Ifeanyi is an extrovert who loves meeting new people. His outgoing personality makes him natural at striking up conversations and forming connections. 
            When he's not busy socializing, Ifeanyi enjoys playing football and exploring new destinations.    
        `,
        title: "Ifeanyi Ibe",
        subtitle: "Sales Team",
        linkedin: "https://www.linkedin.com/in/ibeifeanyi/",
    },
    {
        adult_image: "Kolade.jpg",
        featured_media: "Kolade.jpg",
        wanted_tagline: "I wanted to become a Medical Doctor",
        tagline_story: ` Growing up, Kolade believed If one eats while standing up, the food will go to straight to the legs. 
            Kolade is a laid-back and easy-going team member who always is on the lookout for the best interests of others. 
            Kolade's excellence in problem-solving and his strong work ethic makes him a great asset to our team. While he's not busy with work, he can be found indulging in his hobby - watching Nat Geo Wild.
        `,
        title: "Kolade Kola-Akinola",
        subtitle: "Engineering Team",
        linkedin: "",
    },
    {
        adult_image: "Anthony.jpg",
        featured_media: "Anthony.jpg",
        wanted_tagline: "I wanted to become an Aeronautic Engineer",
        tagline_story: `Growing up, Anthony held the belief that Jesus was going to come in the year 2000.
            Anthony is our resident foodie and information junkie. When he's not exploring the latest culinary delights, he can often be found surfing the internet, indulging his passion for knowledge and information. 
            His highly analytical and strategic mind makes him a key player on our team.    
        `,
        title: "Anthony Edekobi",
        subtitle: "Partnership Team",
        linkedin: "https://www.linkedin.com/in/anthonyedekobi/",
    },
    {
        adult_image: "",
        featured_media: "",
        wanted_tagline: "I wanted to become a Teacher",
        tagline_story: `As a kid, Moyin believed that drinking Maltina would result in the human character from the Maltina advertisement entering her stomach.
            Despite her shyness, Moyin is a determined team member who enjoys dancing and trying out new food on dates. Her perseverance and determination makes her a valuable member of the team. 
        `,
        title: "Moyinoluwa Koyejo",
        subtitle: "Data Partnership Team",
        linkedin: "",
    },
    {
        adult_image: "Rotimi.jpg",
        featured_media: "Rotimi.jpg",
        wanted_tagline: "I wanted to be a Super Eagle's player",
        tagline_story: `Growing up, Rotimi believed the misconception that the best year of one's life begins at 40. 
            Rotimi is an introverted yet enthusiastic team member with a keen sense of responsibility. He is a gamer at heart, often spending his free time immersed in virtual worlds. 
            His calm approach and measured demeanour to every situation makes him an asset to our team.   
        `,
        title: "Rotimi Olaosebikan",
        subtitle: "Engineering Team",
        linkedin: "https://www.linkedin.com/in/olaosebikanrotimi",
    },
    {
        adult_image: "Fikayo.jpg",
        featured_media: "Fikayo.jpg",
        wanted_tagline: "I wanted to become a Navy officer",
        tagline_story: `Growing up, Fikayo believed the old myth that If you loose a milk tooth and it get seen by lizard, it would never grow back 
            Emmanuel is a calm and collected team member who loves watching football match. He brings a sense of stability and level-headedness to our team.
            His calm demeanor makes him a pleasurable team member to work with.
        `,
        title: "Olufikayo Adebosin",
        subtitle: "Engineering Team",
        linkedin: "", 
    },
    {
        adult_image: "nonye (1).png",
        featured_media: "nonye (1).png",
        wanted_tagline: "I had just wanted to be rich",
        tagline_story: `Nonye had a humorous childhood belief that having a combination of sprite and mango would lead to instant death.
            Nonye is our "enjoyment minister" with a love for jazz, soul, and all things feline. Her flexible and creative approach to problem-solving as well as her passion for delivering results makes Nonye a valuable member of our team.  
        `,
        title: "Nonyelichukwu Ojei",
        subtitle: "Operations Team",
        linkedin: "",
    },
    {
        adult_image: "tosin adisa.png",
        featured_media: "tosin adisa.png",
        wanted_tagline: "I wanted to become a Civil Engineer",
        tagline_story: `Growing up, Tosin harbored a belief as a child that "aunties" were akin to mini-Gods.
            Tosin is an introverted individual who she excels in communication and possesses a natural gift for intuition and creativity, all of these makes her an asset to our team.  
            In her free time, she enjoys socializing with friends and exploring new travel destinations. 
        `,
        title: "Tosin Adisa",
        subtitle: "Marketing Team",
        linkedin: "https://www.linkedin.com/in/oluwatosinadisa",
    },
    {
        adult_image: "ore.png",
        featured_media: "ore.png",
        wanted_tagline: "I wanted to become a badass Lawyer!",
        tagline_story: `Growing up, she had a strong believe of the saying “always say what's on your mind and people will treat you the same way you treat them... na lie!”
            Oreoluwa is a spontaneous, audacious, and spicy team member who brings a unique perspective to any situation.
            Her confidence, ability to network, collaborate and diplomatic approach to situation makes her a valuable asset to the team. 
        `,
        title: "Oreoluwa Ajayi",
        subtitle: "Sales Team",
        linkedin: "",
    },
    {
        adult_image: "Samuel.jpg",
        featured_media: "Samuel.jpg",
        wanted_tagline: "Didn't have a particular ambition in mind",
        tagline_story: `Growing up, Samuel had a superstitious belief that swallowing a seed would make it germinate in your stomach.
            Samuel is a well-rounded individual with hobbies that include playing games, reading, working on the farm, traveling, and even writing poetry.
            His strong ability to empathize with others and consistently deliver tasks on time makes him a valuable asset to our team.
        `,
        title: "Samuel Alabi",
        subtitle: "Marketing Team",
        linkedin: "",
    },
    {
        adult_image: "Kayode.jpg",
        featured_media: "Kayode.jpg",
        wanted_tagline: "I wanted to become a Chemical Engineer",
        tagline_story: `Growing up, Kayode beleived the india (99) VS nigeria(1) football match..
            Kayode is a calm and collected individual whose strengths lie in his patience, consistency, and eagerness to learn. He brings a positive and reliable presence to our team and we are lucky to have him on board!
            He is a fan of horror movies and loves a good scare!
        `,
        title: "Kayode Olayiwola",
        subtitle: "Engineering Team",
        linkedin: "",
    },
    {
        adult_image: "mayowa.png",
        featured_media: "mayowa.png",
        wanted_tagline: "I wanted to become an Astronaut",
        tagline_story: `As a kid Mayowa was convinced to believe that Nigeria played against India and the ball magically transformed into a lion.
            He is a big sports fan who also possess strong analytical skills making him an asset to any project. 
            Mayowa is a confident and outgoing team member who loves trying new things and tackling challenges. 
        `,
        title: "Mayowa David",
        subtitle: "Engineering Team",
        linkedin: "",
    },
    {
        adult_image: "Seun 1.jpg",
        featured_media: "Seun 1.jpg",
        wanted_tagline: "I wanted to become a Medical Doctor",
        tagline_story: `Growing up, Seun had a wild imagination and believed that if you eat while standing, it goes straight to your legs.
            Seun is an introverted and intuitive individual who possesses a unique blend of originality, authenticity, honesty, and spirituality
            She enjoys indulging in her hobbies, which include watching movies, listening to music, and trying out different foods. 
        `,
        title: "Seun Alabi",
        subtitle: "Data Partnership Team",
        linkedin: "https://www.linkedin.com/in/seun-alabi/",
    },
    {
        adult_image: "",
        featured_media: "",
        wanted_tagline: "I have always wanted to be a Software Engineer",
        tagline_story: `Growing up, Samson had a humorous childhood belief that Nigeria lost to India with India 99 goals.
            Samson is an easygoing guy who prefers to let his actions speak louder than words. With a natural curiosity and love for knowledge, Samson's strong suit is his ability to absorb and apply new information quickly.
        `,
        title: "Samson Ilemobayo",
        subtitle: "Engineering Team",
        linkedin: "",
    },
    {
        adult_image: "michael.jpg",
        featured_media: "michael.jpg",
        wanted_tagline: "I wanted to become a Carpenter",
        tagline_story: `Growing up, Michael believed that swallowing a chewing gum would take seven years to digest.
            Micheal, the “Smiley man of the team” is a diligent team member known for his timely delivery of tasks. His commitment to accuracy and factual knowledge, makes him a reliable member of our team.
        `,
        title: "Michael Oredunni",
        subtitle: "Marketing Team",
        linkedin: "",
    },
    {
        adult_image: "Mary.jpg",
        featured_media: "Mary.jpg",
        wanted_tagline: "I wanted to become a Dentist",
        tagline_story: `
            Mary loves going out, watching movies and considers her leadership skills to be one of her greatest strengths.
        `,
        title: "Mary Alawode",
        subtitle: "Product Team",
        linkedin: "https://www.linkedin.com/in/maryalawode/",
    },
    {
        adult_image: "",
        featured_media: "",
        wanted_tagline: "I wanted to become a Footballer",
        tagline_story: `As a child, Abdul believed that wearing clothes inside out or backwards could unleash a wave of good fortune throughout the day.
            Abdul is a creative problem-solver who finds joy in soccer and technology. He believes in creativity as a catalyst for problem-solving and embraces challenges with enthusiasm. 
            With adaptability and time management, Abdul brings innovative thinking and a competitive spirit to our team.        
        `,
        title: "Abdulkabir Olanrewaju",
        subtitle: "Engineering Team",
        linkedin: "https://www.linkedin.com/in/olanrewaju-abdulkabeer/",
    },
    {
        adult_image: "davidMichael.png",
        featured_media: "davidMichael.png",
        wanted_tagline: "I wanted to become an Aeronautic Engineer.",
        tagline_story: ` As a child, David held a fascinating belief that crossing the point of no return bridge at Badagry would erase all memories of his own identity. 
            David is a fascinating team member who tackles boredom by indulging in his love for eating. He enjoys swimming and playing video games as hobbies, while their strong suit lies in writing captivating interview questions.  
        `,
        title: "David Micheal",
        subtitle: "Engineering Team",
        linkedin: "",
    },
    {
        adult_image: "Nelson.png",
        featured_media: "Nelson.png",
        wanted_tagline: "I wanted to become a Mechanical Engineer",
        tagline_story: ` As a child, he believed swallowing orange seeds would grow trees in their stomach.
            Nelson is a goal-oriented and easy-going team member who combines an easy-going nature with a passion for knowledge-sharing. Outside of work, he enjoys cycling and console gaming. With his creative thinking and problem-solving skills, he is dedicated to building for the next billion users.
        `,
        title: "Nelson Chinedu",
        subtitle: "Engineering Team",
        linkedin: "https://linkedin.com/in/nelson-chinedu",
    },
    {
        adult_image: "",
        featured_media: "",
        wanted_tagline: "I wanted to become a Professional Footballer",
        tagline_story: `He had a humorous childhood belief that having a combination of garri and mango is a recipe for disaster.
            Gabriel is a calm and reserved team member who used to be a piano player and can impressively hold his breath for 10 seconds underwater.
            Outside work, he's a football enthusiast and a dedicated gamer. His strong suits include objective reasoning and creative thinking making him an invaluable asset to the team.
        
        `,
        title: "Gabriel Oso",
        subtitle: "Marketing Team",
        linkedin: "https://linkedin.com/in/gabriel-oso-ab38b5213",
    },
    {
        adult_image: "",
        featured_media: "",
        wanted_tagline: "I wanted to become a Medical doctor",
        tagline_story: `Growing up, Joseph firmly believed that throwing a lost milk tooth on a zinc roof would make them grow faster.
            Joseph is our dedicated team member with an inherent love for coding. When he's not immersed in programming, he can be found unwinding with Netflix
            His admirable patience and dedication serve as a strong suit, making him an essential asset to our collaborative projects.
        `,
        title: "Joseph Ogbu",
        subtitle: "Engineering Team",
        linkedin: "",
    },
    {
        adult_image: "",
        featured_media: "",
        wanted_tagline: "I wanted to become a Soldier",
        tagline_story: `A little secret from his childhood is that he once believed power bikes could fly due to their dual exhaust.
            Olamide is a a unique blend of adaptability and strategic prowess. He approaches every task with a balanced effort, reserving energy for essential tasks
            Outside work, he finds joy in leisurely walks and console gaming         
        `,
        title: "Olamide Ajayi",
        subtitle: "Engineering Team",
        linkedin: "",
    },
    {
        adult_image: "",
        featured_media: "",
        wanted_tagline: "I wanted to become a Computer Engineer (Hardware)",
        tagline_story: `Growing up, he used to believe that adulthood was a carefree haven.
            Adnan embraces his introverted nature and finds solace in quiet moments, but don't be surprised when he unleashes his Banter expertise!
            His strengths lie in analytical thinking, unwavering persistence, consistency, and unflinching composure.            
        `,
        title: "Adnan Adetunji",
        subtitle: "Engineering Team",
        linkedin: "",
    },
]