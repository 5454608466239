import React from 'react'
import whatsappIcon from  "../../newassets/whatapp.svg";

export const FloatingWhatapp = () => {
  return (
    <a
    href="https://api.whatsapp.com/send?phone=254796111020&text=Hello%2C%20I%27m%20from%20the%20Prembly%20website%20and%20I%20have%20an%20enquiry"
    className="whatsapp-float"
    target="_blank"
    rel="noopener noreferrer"
  >
    <div className="whatsapp-content">
      <img src={whatsappIcon} alt="WhatsApp" className="whatsapp-icon" />
      <span className="whatsapp-text">How can I help you?</span>
    </div>
  </a>
  )
}
