import { padding } from "@mui/system";
import React from "react";
// import Footer from "../../components/footer";
import Footer from "../../components/newNavbar/Footer";
import NavbarArea from "../../components/navbar";
import LatestF from "../allProduct/LatestF";
import NavBar2 from "../../components/newNavbar/NavBar";

export default function PrivacyPage() {
  return (
    <div>
      <NavBar2 />
      {/* <section className="privacy-hero text-md-center">
            <div className="container">
                <div className="col-md-9 mx-auto">
                    <h1>Terms and Privacy statement </h1>
                    <p>
                        We’re a compliance and security infrastructure company.
                    </p>
                    <a href="#statement" className='link'>
                        <i className="ri-arrow-down-line" />
                    </a>
                </div>
            </div>
        </section> */}

      <section className="frequentQs-area">
        <div className="container">
          <div className="col-lg-12 mx-auto">
            <div className="firstlayers row col-md-12 mx-auto justify-content-center text-center">
              <h1>Prembly Limited Privacy Policy</h1>
            </div>

            <div className="searchelp mt-5 col-md-5 mx-auto">
              <i class="ri-search-line"></i>
              <input
                type="search"
                placeholder="Enter a keyword"
                className="helpsearchbar"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="statement py-5" id="statement">
        <div className="container">
          <div className="row  justify-content-center privacy-gap">
            <div className="col-md-10 col-lg-3 privacy-boxs">
              <h5>CONTENT OUTLINE</h5>
              <div style={{ paddingTop: "20px" }}>
                <h3>General Definations</h3>
                <div className="outlines">
                  <p>Modifications</p>
                  <p>What Information Do we Collect?</p>
                  <p>How do we use your Personal Information?</p>
                  <p>
                    How do you consent to our use of your Personal Information? 
                  </p>
                  <p>Does Prembly share my information with others?</p>
                  <p>How long will your Personal Information be kept?</p>
                  <p>How is your Personal Information protected?</p>
                  <p>
                    How can you prevent the use of your Personal Information?
                  </p>
                  <p>
                    What are your rights in relation to your Personal
                    Information?
                  </p>
                  <p>Use of Cookies</p>
                  <p>Telephone Calls</p>
                  <p>Violation of Privacy Policy</p>
                  <p>Contact us </p>
                </div>
              </div>
            </div>

            <div class="col-md-10 col-lg-9 info-policy">
              <p>
                At Prembly Limited (“Prembly”, “we”, or “us”), we prioritize our
                relationship with our customers/clients (“you” or “your”). It is
                important to us that we are transparent about the way we handle
                your personal information. Accordingly, our privacy policy
                (“Privacy Policy”) describes how we collect, use and safeguard
                the information you provide to us and to assist you in making
                informed decisions when using our Service as defined below.
                <br />
                <br />
                For the purpose of this Privacy Policy, “Site” refers to
                Prembly’s website which can be accessed at https://prembly.com,
                “Service(s)” includes but is not limited to Prembly’s
                verification and compliance services, and the IdentityPass,
                IdentityRadar and IdentityForm products accessed via the Site,
                our software and APIs, and any other platform we may use.
                <br />
                <br />
                We recommend that you read our Privacy Policy carefully to
                understand our views and practices regarding your personal data
                and information, its collection and usage. By clicking the
                “Accept” icon or any other icon that indicates agreement with
                this Privacy Policy, accessing our Sites, creating an account
                with us, becoming integrated on our platform, and accessing our
                Services, you agree and consent to our terms and conditions, and
                to the collection, use, storage and sharing of your information
                as described in this Policy.   
              </p>
              <br />

              <h4>Modifications</h4>
              <p>
                As our business evolves and we improve on our Services, we may
                modify this Privacy Policy to conform with regulatory
                requirements. Any modification will be indicated by changing the
                date at the top of this page. If we make any material changes,
                we will notify you by email (sent to the email address specified
                in your account), by means of a notice on our Services prior to
                the change becoming effective, or as otherwise required by law.
                In any event, by continuing to use our Services or maintaining
                your account with us after the posting of any changes, you
                confirm your continuing acceptance of this Privacy Policy
                together with such changes, and your consent to the terms set
                out therein. Questions, comments and requests regarding this
                Privacy Policy are welcomed and should be addressed to
                teams@prembly.com
              </p>
              <br />

              <h4>1. What Information do we collect?  </h4>
              {/* <p>
                This is the Privacy Statement of Mainline Digitech Innovations 
                Limited, a company incorporated in Nigeria, whose registered 
                number is 1577301, with a registered office located at 15,
                Ramat  Cresent Ogudu GRA, Lagos Nigeria. It applies to all
                subsidiaries  and branches of My Identity Pay to the extent that
                they process  personal data. My Identity Pay is supervised by
                the ​National  Information Technology Development Agency of
                Nigeria​.  My Identity Pay treats personal data which it
                receives through its  websites, portals and any other means with
                due care and is  dedicated to safeguarding any personal data it
                receives. My  Identity Pay is bound by Nigerian Data Protection
                Regulation​. This  Privacy Statement is designed to inform you
                about the type of  information that Identitypass collects when
                using our website  and our application and the purposes for
                which this information is  being processed, used, maintained and
                disclosed (together the  “Services”).  This Privacy Statement
                aims to explain in a simple and transparent  way what personal
                data we gather about you and how we process  it. It applies to
                the following persons: 
                <br />
                <br />
                ● The legal representatives and ultimate beneficial owners of 
                all past, present and prospective commercial contracting 
                parties. We are legally obliged to retain personal data of 
                these persons, also for a certain period after the relationship 
                has ended, in compliance with ‘know your customer’ (“KYC”) 
                regulations. 
                <br />
                <br />● Anyone visiting the Identitypass website. We may amend 
                this Privacy Statement to remain compliant with any changes in
                law and/or to reflect how our business processes personal 
                data. 
              </p> */}

              <p>
                1.1 Submitted Information
                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                    paddingTop: "3px",
                  }}
                >
                  <p> 1.1.1</p>
                  <span>
                    Information we may collect and process from you include
                    those you provide to us when: filling in forms on our
                    Site(s); corresponding with us; registering to use our
                    Services; subscribing to any of our Services; when you
                    became integrated with our security and compliance
                    functionalities; and reporting a problem with any of our
                    products, our Services, our Site or any of our platforms.
                  </span>
                </p>
                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                    paddingTop: "3px",
                  }}
                >
                  <p> 1.1.2</p>
                  <span>
                    This information may include your name, address, email
                    address and phone number, age, gender, marital status,
                    username, password and other registration information,
                    financial information where applicable. By the use of any of
                    the Prembly Services, you consent to the collection, use and
                    storage of your information in the manner provided herein.
                  </span>
                </p>
              </p>

              <p>
                1.2 Collected Information
                <p
                  style={{
                    paddingLeft: "20px",
                    paddingTop: "3px",
                  }}
                >
                  <p>
                    Information we may collect from you automatically are
                    information including but not limited to your, technical
                    information, including the type of software you use for
                    integration with us, unique device identifiers (for example,
                    your device’s IMEI or serial number), information software
                    development kit (SDK) you use, privacy status of your APIs
                    (whether publicly shared or private), or your device’s
                    location and time zone setting (Device Information); and
                    details of your use of and visits to any of our Sites and/or
                    platforms. 
                  </p>
                </p>
              </p>

              <p>
                1.3 Business Information
                <p
                  style={{
                    paddingLeft: "20px",
                    paddingTop: "3px",
                  }}
                >
                  <p>
                    This includes but is not limited to the following; the full
                    name of your business with registration details,
                    incorporation documents, country of operations, details of
                    directors and shareholders with valid means of
                    identification. This information may be required to be
                    provided to us by filing in the forms on the Site or any
                    other information which you submit to us via the website or
                    email.
                    <br />
                    <br />
                    To maintain and improve the quality of our Service to you,
                    we track information provided to us by your browser when you
                    view or use the Service, such as the type of browser you
                    use, the device from which you connected to the Service, the
                    time and date of access, and other information that does not
                    personally identify you. We track this information using
                    cookies, or small text files which include an anonymous
                    unique identifier. Other information and data about you
                    which we may collect, use, and process include the
                    following: 
                  </p>
                </p>
                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                  }}
                  className="alpha-section"
                >
                  <span>a.</span>
                  <span>
                    Records of correspondence received via the Site, email and
                    telephone.
                  </span>
                </p>
                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                  }}
                  className="alpha-section"
                >
                  <span>b.</span>
                  <span>
                    Your responses to surveys or customer research that we carry
                    out from time to time
                  </span>
                </p>
                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                  }}
                  className="alpha-section"
                >
                  <span>c.</span>
                  <span>
                    Details of transactions or API calls made to us via the
                    Site, platforms, telephone or other means, including details
                    of payment methods used.
                  </span>
                </p>
              </p>
              <br />

              <h4>2. How do we use your Personal Information? </h4>
              <p style={{ paddingLeft: "25px" }}>
                Prembly uses the information collected from you for the
                following purposes: 
              </p>
              <p
                style={{
                  display: "flex",
                }}
              >
                <p>2.1</p>
                <span style={{ paddingLeft: "15px" }}>
                  We may use your personal information and data which you
                  provide along with other information for the purposes of
                  processing, operating and managing your access to our Site and
                  Services. 
                </span>
              </p>
              <p
                style={{
                  display: "flex",
                }}
              >
                <p>2.2</p>
                <span style={{ paddingLeft: "15px" }}>
                  To compile statistics relating to our user base, and we may
                  disclose such information to any third party for such
                  purposes, provided that such information will always be
                  anonymous. 
                </span>
              </p>

              <p
                style={{
                  display: "flex",
                }}
              >
                <p>2.3</p>
                <span style={{ paddingLeft: "15px" }}>
                  We may require your personal information to comply with
                  regulatory provisions to which we are bound. Our Services are
                  subject to laws and regulations requiring us to collect, use,
                  and store your personal information in certain ways. 
                </span>
              </p>

              <p
                style={{
                  display: "flex",
                }}
              >
                <p>2.4</p>
                <span style={{ paddingLeft: "15px" }}>
                  To detect, prevent and contain data breaches. 
                </span>
              </p>

              <p
                style={{
                  display: "flex",
                }}
              >
                <p>2.5</p>
                <span style={{ paddingLeft: "15px" }}>
                  To regularly provide you with information about promotional
                  offers, new products and any improvement on our Services. 
                </span>
              </p>

              <p
                style={{
                  display: "flex",
                }}
              >
                <p>2.6</p>
                <span style={{ paddingLeft: "15px" }}>
                  To provide customer service; we process your information when
                  you contact us to resolve any questions, disputes, upgrade
                  your subscriptions, etc. Without processing your information
                  for such purposes, we cannot respond to your requests and
                  ensure your uninterrupted use of the Services. 
                </span>
              </p>

              <p
                style={{
                  display: "flex",
                }}
              >
                <p>2.7</p>
                <span style={{ paddingLeft: "15px" }}>
                  To ensure network and information security; We process your
                  personal information in order to enhance security, monitor and
                  verify identity or service access, combat other malware or
                  security risks, and to comply with applicable security laws
                  and regulations in our jurisdiction. 
                </span>
              </p>
              <p
                style={{
                  display: "flex",
                }}
              >
                <p>2.8</p>
                <span style={{ paddingLeft: "15px" }}>
                  You may withdraw your consent from this Privacy Policy at any
                  time upon your request and subject to the terms of this
                  Privacy Policy, however, all accrued obligations prior to such
                  withdrawal shall remain subsisting and valid until such
                  obligations are fulfilled.
                </span>
              </p>
              <p
                style={{
                  display: "flex",
                }}
              >
                <p>2.9</p>
                <span style={{ paddingLeft: "15px" }}>
                  For any purpose that you provide consent; We will not use your
                  personal information for purposes other than those purposes we
                  have disclosed to you, without your permission. From time to
                  time, and as required under the applicable law, we may request
                  your permission to allow us to share your personal information
                  with third parties. In such instances, you may opt out of
                  having your personal information shared with third parties, or
                  allowing us to use your personal information for any purpose
                  that is incompatible with the purposes for which we originally
                  collected it or subsequently obtained your authorization. If
                  you choose to limit the use of your personal information,
                  certain features or Prembly Services may not be available to
                  you.
                </span>
              </p>

              <p
                style={{
                  display: "flex",
                }}
              >
                <p>2.10</p>
                <span style={{ paddingLeft: "15px" }}>
                  For any purpose that you provide consent; We will not use your
                  personal information for purposes other than those purposes we
                  have disclosed to you, without your permission. From time to
                  time, and as required under the applicable law, we may request
                  your permission to allow us to share your personal information
                  with third parties. In such instances, you may opt out of
                  having your personal information shared with third parties, or
                  allowing us to use your personal information for any purpose
                  that is incompatible with the purposes for which we originally
                  collected it or subsequently obtained your authorization. If
                  you choose to limit the use of your personal information,
                  certain features or Prembly Services may not be available to
                  you.
                </span>
              </p>
              <h4>
                3. How do you consent to our use of your Personal Information?
              </h4>
              <p>
                While signing up for our Service, you will be required to
                consent to this Privacy Policy by ticking a box. Please note
                that by ticking that box, you consent to and authorize us to
                collect, use, keep and compile records of your personal
                information and to use the same as may be necessary for the
                efficient provision of the Service.  
              </p>

              <h4>4. Does Prembly share my information with others? </h4>
              <p
                style={{
                  display: "flex",
                }}
              >
                <p>4.1</p>
                <span style={{ paddingLeft: "15px" }}>
                  We will share certain personal information with our partners
                  and affiliates in order to provide our Service and to resolve
                  any incident, and we may also receive information about you
                  from them.  
                </span>
              </p>

              <p>
                <p style={{ display: "flex", gap: "15px" }}>
                  <p>4.2 </p>
                  <span>We may also share your information:</span>
                </p>

                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                    paddingTop: "3px",
                  }}
                >
                  <p> 4.2.1</p>
                  <span>
                    if we believe it is necessary in order to detect,
                    investigate, prevent, or take action against illegal
                    activities, fraud, or situations involving potential threats
                    to the rights, property, or personal safety of any person
                    products, our Services, our Site or any of our platforms.
                  </span>
                </p>
                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                    paddingTop: "3px",
                  }}
                >
                  <p> 4.2.3</p>
                  <span>
                    in connection with a merger, acquisition, consolidation,
                    change of control, or sale of all or a portion of our assets
                    or if we undergo bankruptcy or liquidation.
                  </span>
                </p>
                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                    paddingTop: "3px",
                  }}
                >
                  <p> 4.2.4</p>
                  <span>
                    to enforce our Terms of Use and other agreements or to
                    investigate potential breaches; or for the purpose of
                    publishing statistics relating to the use of our Services,
                    in which case all information may be aggregated or made
                    anonymous; and 
                  </span>
                </p>
                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                    paddingTop: "3px",
                  }}
                >
                  <p> 4.2.5</p>
                  <span>
                    request your permission to share your personal information
                    for a specific purpose. We will notify you and request
                    consent before you provide PII or before the PII you have
                    already provided is shared for such purposes. 
                  </span>
                </p>
              </p>

              <p>
                <p style={{ display: "flex" }}>
                  <p>4.3</p>  
                  <span style={{ paddingLeft: "15px" }}>
                    Prembly may disclose your personal information, without
                    notice, only if required to do so by applicable law,
                    regulation, operating agreement, legal process or in the
                    good faith or belief that such action is appropriate and
                    necessary to: 
                  </span>
                </p>

                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                    paddingTop: "3px",
                  }}
                >
                  <p> 4.3.1</p>
                  <span>
                    conform to the law or comply with legal processes served on
                    Prembly or the Site
                  </span>
                </p>
                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                    paddingTop: "3px",
                  }}
                >
                  <p>43.2</p>
                  <span>
                    protect and defend the rights or property of Prembly and, 
                  </span>
                </p>

                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                    paddingTop: "3px",
                  }}
                >
                  <p> 4.3.3</p>
                  <span>
                    act under exigent circumstances to protect the personal
                    safety or property of users of Prembly’s Services, or the
                    public in the event of a claim or dispute to the processing
                    of any information supplied by you. 
                  </span>
                </p>
              </p>

              <p style={{ paddingTop: "0px" }}>
                You hereby consent to such disclosures. All such information
                will be held in accordance with this Privacy Policy. 
              </p>
              <br />

              <h4>5. How long will your Personal Information be kept?</h4>
              <p style={{ paddingLeft: "20px" }}>
                We store your personal and business information securely
                throughout the extant functionality of your account with us. We
                will only keep your information for as long as reasonably
                necessary to provide you with our products and Services, and to
                fulfil the purposes for which we collected it, including the
                purposes of satisfying any legal, accounting, or reporting
                obligations or to resolve disputes. In determining the
                appropriate period in which your information would be kept, we
                consider the amount, nature and sensitivity of the personal
                information, the potential risk of harm from unauthorised
                disclosure of your personal information and the applicable
                legal, regulatory, tax, accounting and other requirements.
                <br />
                <br />
                Contact information such as your name, email address and
                telephone number for marketing purposes is retained on an
                ongoing basis until you unsubscribe. Thereafter we will add your
                details to our suppression list to ensure we do not
                inadvertently market to you. Recording of our telephone calls
                with you may be kept for a period of up to six years.
                Information collected via technical means such as cookies,
                webpage counters and other analytics tools is kept for a period
                of up to one year from the expiry of the cookie.
                <br />
                <br />
                {/* ● Public authorities, regulators and supervisory bodies such as 
                fraud protection agencies and the central banks of the 
                countries where we operate.  <br />
                <br />● Judicial/investigative authorities such as the police,
                public  prosecutors, courts and arbitration/mediation bodies on
                their  express and legal request. 
                <br />
                <br /> ● Lawyers, for example, in case of a claim or
                bankruptcy,  trustees who take care of other parties’ interests
                and  company auditors.  Third-party service providers  When we
                use other service providers we only share personal data  that is
                required for the particular task we involve the service 
                provider for. Service providers support us with activities
                like: 
                <br />
                <br />
                ● Performing certain services and operations.  <br />
                <br />● Designing and maintenance of internet-based tools and 
                applications. <br />
                <br />● Marketing activities or events and managing customer 
                communications.  <br />
                <br />● Preparing reports and statistics, printing materials
                and  designing products.  <br />
                <br />● Placing advertisements on apps, websites and social
                media.  Business transfers  The Mainline Digitech Innovations
                Limited may buy or sell business  units or affiliates. In such
                circumstances, we may transfer  customer information as a
                business asset. Without limiting the  foregoing, if our business
                enters into a joint venture with or is sold  to or merged with
                another business entity, your information may  be disclosed to
                our new business partners or owners.  With your permission  Your
                information may also be used for other purposes for which  you
                give your specific permission, or when required by law or  where
                permitted under the terms of the laws of the relevant 
                jurisdiction.  */}
              </p>

              <h4>6. How is your Personal Information protected? </h4>
              <p>
                <p style={{ display: "flex" }}>
                  <p>6.1</p>  
                  <span style={{ paddingLeft: "15px" }}>
                    We restrict access to your information to our employees,
                    partners, affiliates and others who require it in relation
                    to the provision of our products and Services. We also
                    maintain electronic and procedural safeguards to protect
                    your information. Once received, your information will be
                    accorded strict procedures and security features via the use
                    of encryption to try to prevent unauthorised access. 
                  </span>
                </p>

                <p style={{ display: "flex" }}>
                  <p>6.2</p>  
                  <span style={{ paddingLeft: "15px" }}>
                    In addition, you agree and consent that it is your
                    responsibility to keep all tokens and passwords that enables
                    you to access the Services, Site, and our platforms, safe,
                    intact and confidential.  Please ensure you do not disclose
                    your password to third parties, and you log out of your
                    account after each use.  
                  </span>
                </p>
                <p style={{ display: "flex" }}>
                  <p>6.3</p>  
                  <span style={{ paddingLeft: "15px" }}>
                    We will take all reasonable measures to ensure that your
                    data is treated, stored and processed securely and in
                    accordance with this Privacy Policy, however, these measures
                    do not guarantee that your information will not be accessed,
                    disclosed, lost, altered or destroyed by breach of firewalls
                    and secure server software. By using our Service, you
                    acknowledge that you understand and agree to assume these
                    risks, and consent to the collection, transfer, storing or
                    processing of your personal data in the manner set out
                    above. 
                  </span>
                </p>
              </p>

              <h4>
                7. How can you prevent the use of your Personal Information? 
              </h4>
              <p style={{ paddingLeft: "20px" }}>
                You have the right at any time to prevent us from contacting you
                for marketing purposes.  When we send a promotional
                communication to you, you may choose to opt-out of further
                promotional communications by following the unsubscribe
                instructions provided in each promotional e-mail.
                <br /> 
              </p>

              <h4>
                8. What are your rights in relation to your Personal
                Information?  
              </h4>

              {/* <p> */}
              <p>
                8.1 Under data protection laws, you have the right to the
                following:
                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                    paddingTop: "3px",
                  }}
                >
                  <p> 8.1.1</p>
                  <span>
                    Request access to your personal information: This enables
                    you to receive a copy of your Personal Information that we
                    hold. 
                  </span>
                </p>
                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                    paddingTop: "3px",
                  }}
                >
                  <p> 8.1.2</p>
                  <span>
                    Request erasure of personal information: This enables you to
                    ask us to delete your personal information. Please note,
                    however, that we may not always be able to comply with your
                    request of erasure of your personal information for specific
                    legal reasons which will be notified to you, if applicable,
                    at the time of your request. age of your information in the
                    manner provided herein.
                  </span>
                </p>
                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                    paddingTop: "3px",
                  }}
                >
                  <p> 8.1.3</p>
                  <span>
                    Request erasure of personal information: This enables you to
                    ask us to delete your personal information. Please note,
                    however, that we may not always be able to comply with your
                    request of erasure of your personal information for specific
                    legal reasons which will be notified to you, if applicable,
                    at the time of your request. 
                  </span>
                </p>
                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                    paddingTop: "3px",
                  }}
                >
                  <p> 8.1.4</p>
                  <span>
                    Request transfer of your personal information: You may
                    request that we transfer, to you or a third party, the
                    personal information which you had provided to us. 
                  </span>
                </p>
                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                    paddingTop: "3px",
                  }}
                >
                  <p> 8.1.5</p>
                  <span>
                    Right to withdraw consent: Where you have previously granted
                    us consent to process your personal information, you may
                    choose to withdraw such consent. This withdrawal will,
                    however, not affect the lawfulness of any processing carried
                    out before you withdraw your consent. 
                  </span>
                </p>
                <p
                  style={{
                    paddingLeft: "20px",
                    display: "flex",
                    gap: "5px",
                    paddingTop: "3px",
                  }}
                >
                  <p> 8.1.6</p>
                  <span>
                    If you wish to exercise any of the rights set out above,
                    please contact us. 
                  </span>
                </p>
                {/* </p> */}
              </p>

              <h4>9. Use of Cookies</h4>
              <p>
                In order to make your visit to the Site user-friendly, to keep
                track of visits and to improve the service, Prembly collects a
                small piece of information sent from your browser, called a
                cookie. If you register with us or if you continue to use the
                website you agree to our use of cookies. We utilize cookies to
                help manage player logins, to protect against security issues,
                and to remember player preferences. We do not set a persistent
                cookie to store your passwords, as we do not want anyone to be
                able to access your account other than you. We will take all
                reasonable steps to ensure that your information and data is
                treated securely and in accordance with this Privacy Policy. You
                can read more on our Cookie Policy to better understand our use
                of cookies in relation to your information. 
              </p>

              <h4>10. Telephone Calls </h4>
              <p>
                Telephone calls to and from our Customer Contact Centre are
                recorded for training and security purposes along with the
                resolution of any queries arising from the service you receive. 
                you can do too: 
              </p>

              <h4>11. Violation of Privacy Policy</h4>
              <p>
                Prembly will undertake all reasonable measures to ensure that
                your personal data remains protected with firm procedures to
                deal with any suspected personal data breach and will notify you
                of any personal data breach and let you know the steps we have
                taken to remedy the breach and the security measures we have
                applied to render your personal data unintelligible.
              </p>

              <h4>12. Contact us</h4>
              <p>
                You may contact our data privacy team via the following email
                address: team@myidentitypass.com
              </p>
              <br/>
              
              <p><strong>Last updated: This Privacy Policy was last updated on February 23, 2023</strong></p>
            </div>
          </div>
        </div>
      </section>
      <section className="cant-find-answer">
        <div className="container">
          <div className="col-md-10 col-lg-12 mx-auto py-5">
            <div className="cant-find">
              <h3>Can’t find the answer you are looking for?</h3>
              <div className="">
                <a
                  className="btn btn-sales  me-1"
                  href="https://calendly.com/premblysalesteam/45mins"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LatestF/>
      <Footer />
    </div>
  );
}


// Authorised Parties who have or may have access to Personal Data, should be expected to have read, understood and must agree to comply with this Policy as it affects them. No third party may access Personal Data held by MDI without having first entered into a data confidentiality agreement or, which will:
// imposes on the third party obligations which MDI is committed to,
// gives MDI the right to audit compliance with the agreement; and
// compel the third party to indemnify MDI in the event of any Data Breach through the fault of the third party.