import React, { useState, useEffect } from "react";
import axios from "axios";
import NavbarArea from "../../components/navbar";
import Footer from "../../components/footer";
import { identitys } from "../../components/datas";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LatestF from "../allProduct/LatestF";
import { identityT } from "../../components/datas";
import BlueCard from "../allProduct/BlueCard";
import { Link } from "react-router-dom";
import Cards from "../allProduct/Cards";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import photo from "../../assets/prembly-assest/photo.png";
import photo1 from "../../assets/prembly-assest/photo1.png";
import gallery1 from "../../assets/events/gallery1.png";
import gallery2 from "../../assets/events/gallery2.png";
import gallery3 from "../../assets/events/gallery3.png";
import gallery4 from "../../assets/events/gallery4.png";
import gallery5 from "../../assets/events/gallery5.png";
import gallery6 from "../../assets/events/gallery6.png";
import mention1 from "../../assets/events/mention1.png";
import mention3 from "../../assets/events/mention3.png";
import { Helmet } from "react-helmet";
import dia from "../../assets/dia1.png";

import { EffectCoverflow, Pagination } from "swiper/modules";

export default function Dialogue() {
  const [arrowDirection, setArrowDirection] = useState(1);
  const [pressData, setPressData] = useState([]);

  const arrowAngleRight = () => {
    setArrowDirection(2);
  };

  const arrowAngleLeft = () => {
    setArrowDirection(1);
  };

  useEffect(() => {
    axios
      .get(
        "https://cms-backend.myidentitypass.com/resource/press/collections/0"
      )
      .then((res) => {
        // console.log(res);
        setPressData(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div style={{ background: "#ffffff" }}>
      <Helmet>
        <title>Prembly- Dialoque1</title>
        <meta property="og:title" content="Prembly Dialogue1 Event" />
        <meta name="description" content="A Compliance and Security Company" />
      </Helmet>
      <NavbarArea />
      <div className="whiteBg eventHer0"></div>
      <div className="eventHero">
        <div className="container align-items-center ">
          <div className="row home-gap align-items-center justify-content-between">
            <div className="event-title col-md-12 col-lg-6">
              <h1 className="py-3">Prembly Compliance Industry Dialogue I</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="rsvp-section">
        <div className="container py-5">
          <div className="d-flex row mx-auto">
            <div className="event-resvp col-md-12 mx-auto">
              <div className="dialogue-header ">
                <h1 className="">PREMBLY COMPLIANCE INDUSTRY DIALOGUE I</h1>
              </div>
              <div className="">
                <p style={{ fontSize: "17px" }}>
                  The Prembly Compliance Industry Dialogue is a convergence of
                  Industry Regulators & Select Senior Industry Executives and
                  Compliance Leaders, to have insightful conversations on
                  Compliance with the aim of exploring solutions that will
                  foster better interaction between the public & private sectors
                  and ultimately enhance sustainable ties with corresponding
                  positive impacts in the industry.
                </p>
                <p style={{ fontSize: "17px; text-justify" }}>
                  <a
                    href="https://prembly.com/"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      borderBottom: "2px solid #003E51",
                      color: "#003E51",
                    }}
                  >
                    Prembly
                  </a>
                  , a compliance and security infrastructure company with
                  presence across emerging markets, has served hundreds of
                  digital businesses to safely acquire & onboard customers,
                  whilst keeping the internet safe for them to transact globally
                  without restrictions.
                </p>
                <p style={{ fontSize: "17px; text-justify" }}>
                  The first edition, a closed breakfast event held in April 2023
                  in Lagos, hosted top officials from CBN & NGX, and select
                  guests from top Fintech and Banks in Nigeria; as both parties
                  dialogued and established relationships.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="gallery-areas pb-5 ">
        <div className="container">
          <div
            className="col-md-12 mx-auto"
            style={{ padding: "5rem 0 4rem 0" }}
          >
            <div className="text-center" style={{ padding: "0 0 3rem 0" }}>
              <h1>Photos</h1>
              <p>
                If you missed the Prembly Compliance Dialogue Event, here are
                the highlights. Check the photos below to explore!
              </p>
            </div>

            <>
              <Slider {...settings}>
                <div className="swiper-slide">
                  <img src={gallery1} />
                </div>
                <div className="swiper-slide">
                  <img src={gallery2} />
                </div>
                <div className="swiper-slide">
                  <img src={gallery3} />
                </div>
                <div className="swiper-slide">
                  <img src={gallery4} />
                </div>
                <div className="swiper-slide">
                  <img src={gallery5} />
                </div>
                <div className="swiper-slide">
                  <img src={gallery6} />
                </div>
              </Slider>
            </>
          </div>
        </div>
      </section>

      <section className="mentions">
        <div className="container py-5 connect">
          <div className="connects">
            <div className="adjust-content ">
              <div className="tes">
                <h5>MEDIA MENTIONS</h5>
              </div>
              <div className="newsContainer ">
                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img
                        src="https://prembly-assets.s3.us-east-2.amazonaws.com/smalls.png"
                        className="news-header w-100"
                        alt=""
                      />
                    </div>

                    <div className="new-body">
                      <a
                        href="https://techbuild.africa/prembly-compliance-tracker-financial-regulations/"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          Prembly Unveils Compliance Tracker to Enhance
                          Compliance in Financial Sector
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>22 July 2023</p>
                    </div>
                  </div>
                </div>

                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img src={photo} className="news-header w-100" alt="" />
                    </div>
                    <div className="new-body">
                      <a
                        href="https://technext24.com/2023/07/27/premblys-financial-compliance-parley/"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          Regulations must be flexible to accommodate
                          innovation- Experts at Prembly’s compliance parley
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>22 July 2023</p>
                    </div>
                  </div>
                </div>

                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img src={photo1} className="news-header w-100" alt="" />
                    </div>
                    <div className="new-body">
                      <a
                        href="https://techeconomy.ng/prembly-unveils-compliance-tracker-to-enhance-compliance-in-financial-secto"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          Prembly Unveils Compliance Tracker to Enhance
                          Compliance in Financial Sector
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>22 July 2023</p>
                    </div>
                  </div>
                </div>

                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img
                        src={mention1}
                        className="news-header w-100"
                        alt=""
                      />
                    </div>
                    <div className="new-body">
                      <a
                        href="https://businessday.ng/news/article/cbn-to-clean-up-tier-1-accounts-not-linked-to-bvn/"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          CBN to clean up Tier-1 accounts not linked to BVN
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>6 April 2023</p>
                    </div>
                  </div>
                </div>

                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img
                        src={gallery6}
                        className="news-header w-100"
                        alt=""
                      />
                    </div>
                    <div className="new-body">
                      <a
                        href="https://www.thisdaylive.com/index.php/2023/04/13/insider-driven-fraud-biggest-challenge-facing-nigerias-finance-industry-experts-say"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          Insider-Driven Fraud Biggest Challenge Facing
                          Nigeria’s Finance Industry, Experts Say
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>6 April 2023</p>
                    </div>
                  </div>
                </div>

                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img
                        src={mention3}
                        className="news-header w-100"
                        alt=""
                      />
                    </div>
                    <div className="new-body">
                      <a
                        href="https://www.linkedin.com/feed/update/urn:li:activity:7052294370208694272/"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          Issues around compliance are not negotiable in the
                          financial sector - Dr. Blaise Ijebor, Chief Risk
                          Officer, CBN
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>6 April 2023</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="adjust-content">
              <div className="tes">
                <h5>PAST EVENTS</h5>
              </div>
              <div className="newsContainer">
                <>
                  <Link
                    to="/event/dialogueII"
                    style={{
                      textDecoration: "none",
                      color: "#000000",
                    }}
                  >
                    <div className="newsCard">
                      <div className="card mb-5">
                        <div className="selectCompliance">
                          <div className="selectComTitle">
                            <p>COMPLIANCE</p>
                          </div>
                          <img
                            src="https://prembly-assets.s3.us-east-2.amazonaws.com/smalls.png"
                            className="news-header w-100"
                            alt=""
                          />
                        </div>
                        <div className="new-body">
                          <h5 className="pb-3">
                            Prembly Compliance Industry Dialogue II
                          </h5>

                          <hr style={{ width: "60px", marginTop: "-10px" }} />
                          <p>22 July 2023</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </>
                <>
                  <Link
                    to="/event/dialogue"
                    style={{
                      textDecoration: "none",
                      color: "#000000",
                    }}
                  >
                    <div className="newsCard">
                      <div className="card mb-5">
                        <div className="selectCompliance">
                          <div className="selectComTitle">
                            <p>COMPLIANCE</p>
                          </div>
                          <img src={dia} className="news-header w-100" alt="" />
                        </div>
                        <div className="new-body">
                          <h5 className="pb-3">
                            Prembly Compliance Industry Dialogue I
                          </h5>

                          <hr style={{ width: "60px", marginTop: "-10px" }} />
                          <p>22 April 2023</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="countries-section py-5">
        <div className="container">
          <div className="row align-items-center mx-auto justify-content-center countries-gap">
            <div className="col-md-10 col-lg-6 countries-list ">
              <div className="global-color mb-4">
                <p>Global</p>
              </div>
              <h1>Our solution cut across 100+ countries</h1>
              <h5 className="mt-3 mb-4">
                Prembly's extensive range of over 100 APIs can be utilised by
                businesses worldwide to enhance their identity verification and
                authentication processes. Our dedicated teams, located globally,
                are ready to assist you and your customers wherever your
                operations may be
              </h5>
              <div className="countriesFlag">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/countries.svg"
                  alt=""
                />
              </div>

              <div className="d-flex align-items-center">
                <Link
                  to="/about"
                  className="btn btn-green1  mt-4 me-1"
                  rel="noreferrer"
                >
                  Learn More
                </Link>
              </div>
            </div>

            <div className="col-md-10 col-lg-5">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/world-new.gif"
                alt=""
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="feedback" className="feedback-prembly-area">
        <div className="container">
          <div className="col-md-12 col-lg-11 mx-auto py-lg-5 text-center">
            <h1>Our Customers Perspective</h1>
            {arrowDirection === 1 && (
              <div className=" d-flex align-items-center py-5 ">
                <div className="showflex row ">
                  {identitys.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      <Cards
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />
                    </div>
                  ))}
                </div>
                <img
                  onClick={arrowAngleRight}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindright.svg"
                  alt=""
                  className="ms-2"
                />
              </div>
            )}

            {arrowDirection === 2 && (
              <div className=" d-flex align-items-center py-5 ">
                <img
                  onClick={arrowAngleLeft}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindleft.svg"
                  alt=""
                  className="me-2"
                />
                {/* <div className=" showflex"> */}
                <div className="showflex row ">
                  {identityT.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      {/* <Slider {...settings}> */}

                      <Cards
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />

                      {/* </Slider> */}
                    </div>
                  ))}
                </div>
                {/* <img src={rewindright} alt="" className="ms-2"/> */}
              </div>
            )}
          </div>
        </div>
      </section>

      <LatestF />
      <Footer />
    </div>
  );
}
