import React from "react";
// import Footer from "../../components/footer";
import Footer from "../../components/newNavbar/Footer";
import NavBar2 from "../../components/newNavbar/NavBar";
import NavbarArea from "../../components/navbar";
import { Accordion } from "react-bootstrap";
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import General from "./faqFolder/General";
import Billing from "../Resources/faqFolder/Billing";
import Services from "../Resources/faqFolder/Services";
import Reports from "../Resources/faqFolder/Reports";
import Verification from "../Resources/faqFolder/Verification";
import {
  billingData,
  generalData,
  serviceData,
  reportsData,
  verificationData,
} from "./faqsData";
// import Verification from "../Resources/faqFolder/Verification";

// faqsdata
export default function Faqs() {
  const [activeFaq, setActiveFaq] = useState("general");
  // const [generalFaq, setGeneralFaq] = useState(true);
  // const [billingFaq, setBillingFaq] = useState(false);
  // const [reportsFaq, setReportsFaq] = useState(false);
  // const [verificationFaq, setVerificationFaq] = useState(false);
  // const [servicesFaq, setServicesFaq] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const allData = [
    ...generalData,
    ...billingData,
    ...serviceData,
    ...reportsData,
    ...verificationData,
  ];
  const filteredSearchData = allData
    .filter(
      (faq) => faq.question.toLowerCase().includes(searchTerm.toLowerCase())
      // faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort();

  // console.log(filteredSearchData)

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    e.target.value > 0 ? toggleFaq("search") : toggleFaq("general");
    // toggleFaq('search',);
  };

  // const

  // useEffect(() => {
  //   const search = searchTerm.toLowerCase();
  // }, [searchTerm]);

  // let openBilling = () => {
  //   setBillingFaq(!billingFaq);
  //   setGeneralFaq(false);
  //   setReportsFaq(false);
  //   setVerificationFaq(false);
  //   setServicesFaq(false);
  // };

  // let openGeneral = () => {
  //   setGeneralFaq(true);
  //   setBillingFaq(false);
  //   setReportsFaq(false);
  //   setVerificationFaq(false);
  //   setServicesFaq(false);
  // };

  // let openReports = () => {
  //   setReportsFaq(!reportsFaq);
  //   setGeneralFaq(false);
  //   setBillingFaq(false);
  //   setVerificationFaq(false);
  //   setServicesFaq(false);
  // };

  // let openServices = () => {
  //   setServicesFaq(!servicesFaq);
  //   setGeneralFaq(false);
  //   setBillingFaq(false);
  //   setReportsFaq(false);
  //   setVerificationFaq(false);
  // };

  // let openVerification = () => {
  //   setVerificationFaq(!servicesFaq);
  //   setServicesFaq(false);
  //   setGeneralFaq(false);
  //   setBillingFaq(false);
  //   setReportsFaq(false);
  // };

  const isFaqActive = (faqName) => activeFaq === faqName;

  const toggleFaq = (faqsName) => {
    setActiveFaq(faqsName);
  };
  return (
    <div>
      {/* <NavbarArea /> */}
      <NavBar2 />

      <section className="frequentQs-area">
        <div className="container">
          <div className="col-lg-12 mx-auto">
            <div className="firstlayer row col-md-12 col-lg-6 mx-auto justify-content-center text-center">
              <h1>How can we help?</h1>

              <div className="searchelp mt-5">
                <i class="ri-search-line"></i>
                <input
                  type="search"
                  placeholder="Enter a keyword"
                  className="helpsearchbar"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="second-layer py-5 my-4">
        <div className="container ">
          <div className="col-lg-12 px-3">
            <div className="row justify-content-center plans">
              <div className="col-md-10 col-lg-3 section-plan-box">
                <h5>Sections</h5>

                <div className="sectionplan-list">
                  <h4 onClick={() => toggleFaq("general")}>General</h4>
                  <div className="pb-3">
                    <p onClick={() => toggleFaq("verification")}>
                      Verification
                    </p>
                    <p onClick={() => toggleFaq("services")}>Services</p>
                    <p onClick={() => toggleFaq("billing")}>Billing</p>
                    <p onClick={() => toggleFaq("reports")}>Reports</p>
                  </div>
                </div>
              </div>

              <div className="col-md-10 col-lg-7">
                {/* {generalFaq
                  .filter((value) => {
                    if (searchTerm === "") {
                      return value;
                    } else if (
                      value.contextName
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return value;
                    }
                  })
                  .map((value, key) => {
                    return (
                      <div className="outlines" key={key}>
                        <p style={{ cursor: "pointer" }}>{value.contextName}</p>
                      </div>
                    );
                  })} */}
                {isFaqActive("search") && (
                  <FaqSection data={filteredSearchData} />
                )}
                {isFaqActive("general") && <FaqSection data={generalData} />}
                {isFaqActive("billing") && <FaqSection data={billingData} />}

                {isFaqActive("services") && <FaqSection data={serviceData} />}
                {isFaqActive("reports") && <FaqSection data={reportsData} />}
                {isFaqActive("verification") && (
                  <FaqSection data={verificationData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cant-find-answer">
        <div className="container">
          <div className="col-md-10 col-lg-12 mx-auto py-5">
            <div className="cant-find">
              <h3>Can’t find the answer you are looking for?</h3>
              <div className="">
                <a
                  className="btn btn-sales  me-1"
                  href="https://calendly.com/premblysalesteam/45mins"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

// import React from 'react'

function FaqSection({ data }) {
  return (
    <div>
      <div className="faq">
        {data.map((value, key) => (
          <FaqItem
            question={value.question}
            answer={value.answer}
            id={value.id}
            key={value.id}
          />
        ))}
      </div>
    </div>
  );
}

const FaqItem = ({ question, answer, id }) => {
  return (
    <Accordion defaultActiveKey="0" className="">
      <Accordion.Item eventKey={id} className="item">
        <Accordion.Header>{question}</Accordion.Header>
        <Accordion.Body>{answer}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
