import React, { useEffect, useState } from "react";
import NavbarArea from "../../components/navbar";

export default function LatestF(props) {
  const [subscribed, setSubscribed] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);

  const handleSubmit = (e) => {
   e.preventDefault();
  }

  useEffect(() => {
    const subValue = queryParams.get("subscribed") || "";
    if (subValue === "true") {
      setSubscribed(true);
    }
  }, []);

  return (
    <div>
      <section className={`latest  ${props.updateBg ? props.updateBg : ""}`}>
        <div className="container">
          {subscribed ? (
            <div className="col-lg-12 mx-auto">
              <div className="col-md-12 col-lg-6 mx-auto ">
                <div className="text-center">
                  {" "}
                  <h1>Thank you for Signing up</h1>{" "}
                  <p className="mt-3">
                    {" "}
                    Don't miss the latest updates on security and compliance.
                    We'll only send you information that's worth your time.
                  </p>{" "}
                </div>
              </div>
            </div>
          ) : (
            // <div className="col-lg-12 mx-auto">
            <div className="col-md-10 col-lg-6 mx-auto ">
              <div className="text-center">
                <h1>Want Our Latest Updates ?</h1>
                <p className="mt-3">Input your email to get update from us</p>
              </div>

              {/* <div className=""> */}
              <form
                action="https://myidentitypass.us19.list-manage.com/subscribe/post"
                method="POST"
                // onSubmit={handleSubmit}
              >
                <input
                  type="hidden"
                  name="u"
                  value="1954fa3f95658d99efa44cd11"
                />
                <input type="hidden" name="id" value="15f26c0f7f" />

                <div className=" latestbutton">
                  <div
                    className="d-flex align-items-center pb-2 px-1 formbox"
                    id="mergeRow-0"
                  >
                    <i class="ri-mail-fill icon"></i>
                    <input
                      type="email"
                      placeholder="Your email"
                      name="MERGE0"
                      id="MERGE0"
                    />
                  </div>
                  <input
                    type="submit"
                    className="email-btn"
                    name="submit"
                    value="Submit"
                  />
                </div>
              </form>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
