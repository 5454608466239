// import React, { useState, useEffect } from "react";
// // import Footer from "../../components/footer";
// import Footer from "../../components/newNavbar/Footer";
// import NavbarArea from "../../components/navbar";
// import LatestF from "../allProduct/LatestF";
// import { dataContext } from "../../pages/terms/termsData";
// import { useParams } from "react-router-dom";
// import NavBar2 from "../../components/newNavbar/NavBar";

// const termsAndPolicyData = [
//   {
//     index: 1,
//     title: "GENERAL SERVICE TERMS",
//     content: [
//       {
//         index: 1.1,
//         body: "You will ensure that all information you provide to us via any of the Prembly websites or platforms (e.g., information provided in connection with your registration for the Services, community group, etc.) is accurate, complete, and not misleading. ",
//       },

//       {
//         index: 1.2,
//         body: "In processing the personal data of third parties supplied by you in relation to your use of the Service, you bear the responsibility of obtaining necessary consents for processing such data and providing legally adequate privacy notices to such third parties. You represent to us that you have provided all necessary privacy notices and obtained all necessary consents. You are responsible for processing and storing the information exchanged between us under the Services in relation to these third parties.  ",
//       },

//       {
//         index: 1.3,
//         body: "You are responsible for the maintenance of licenses and adhering to the license terms for any software you run in relation to your use of the Services. If we reasonably believe that any of the information supplied to us violates the law, infringes or misappropriates the rights of a third party, or otherwise violates a material provision of the Term (“Prohibited Content”), we will notify you of the Prohibited Content and may, within 2 days of such notice, remove or discontinue the use of such Content in the provision of the Services. Where we deem it necessary in our sole discretion, we may without notice to you, remove such Prohibited Content and/or suspend your access to our Services where we believe such Prohibited Content may disrupt or threaten the Services, or in accordance with applicable law or any judicial, regulatory or governmental order or request. In the event that we remove the Prohibited Content without prior notice, we will provide prompt notice to you unless prohibited by law.",
//       },

//       {
//         index: 1.4,
//         body: "You will provide all information or documentation related to you and your business which may be reasonably requested by us to verify your compliance with the Terms. You will reasonably cooperate with us to identify the source of any problem with the Services that we reasonably believe may be attributable to the information supplied by you or any end user materials which you control. ",
//       },

//       {
//         index: 1.5,
//         body: "You may not transfer outside the Services any software or documentation you obtain from us in connection with the Services without our specific written authorization to do so.",
//       },
//     ],
//   },

//   {
//     index: 2,
//     title: "MODIFICATIONS",
//     content: [
//       {
//         index: 2.1,
//         body: "You will ensure that all information you provide to us via any of the Prembly websites or platforms (e.g., information provided in connection with your registration for the Services, community group, etc.) is accurate, complete, and not misleading. ",
//       },

//       {
//         index: 2.2,
//         body: "In processing the personal data of third parties supplied by you in relation to your use of the Service, you bear the responsibility of obtaining necessary consents for processing such data and providing legally adequate privacy notices to such third parties. You represent to us that you have provided all necessary privacy notices and obtained all necessary consents. You are responsible for processing and storing the information exchanged between us under the Services in relation to these third parties.  ",
//       },

//       {
//         index: 2.3,
//         body: "You are responsible for the maintenance of licenses and adhering to the license terms for any software you run in relation to your use of the Services. If we reasonably believe that any of the information supplied to us violates the law, infringes or misappropriates the rights of a third party, or otherwise violates a material provision of the Term (“Prohibited Content”), we will notify you of the Prohibited Content and may, within 2 days of such notice, remove or discontinue the use of such Content in the provision of the Services. Where we deem it necessary in our sole discretion, we may without notice to you, remove such Prohibited Content and/or suspend your access to our Services where we believe such Prohibited Content may disrupt or threaten the Services, or in accordance with applicable law or any judicial, regulatory or governmental order or request. In the event that we remove the Prohibited Content without prior notice, we will provide prompt notice to you unless prohibited by law.",
//       },
//     ],
//   },

//   {
//     index: 3,
//     title: "DATA PROTECTION",
//     content: [
//       {
//         index: 3.1,
//         body: "We shall ensure that every information you share with us is processed and stored in line with the provisions of the relevant data protection laws which includes the Nigerian Data Protection Regulation ('NDPR').",
//       },

//       {
//         index: 3.2,
//         body: "All modifications are effective immediately we upload them. We therefore, advise that you review these Terms prior to using our Services. You are free to decide whether or not to accept a revised version of these Terms, you are however, required to accept the updated Terms to accessing or us in the Platform. If you do not agree to these Terms or any updated version of these Terms, your sole recourse is to terminate your access or use of the Services.",
//       },

//       {
//         index: 3.3,
//         body: " Except as otherwise expressly stated by us, your access and use of the Platform are subject to and constitute your acceptance of, the version of these Terms in effect at the time of your access or use. ",
//       },
//     ],
//   },

//   {
//     index: 4,
//     title: " PAYMENT AND FEES",
//     content: [
//       {
//         index: 4.1,
//         body: "Our fees and charges for the use of the Service shall be contained in the Fee Schedule. We may in our absolute discretion review these fees and charges and shall notify you at least one (1) month prior to such reviews becoming effective. You may not be able to access any or all of our Services if the amount in your wallet is insufficient to fund the Service requested. Please note that you are not entitled to a refund of any amount paid by you after  subscribing to or funding your wallet for any of our Services.",
//       },
//       {
//         index: 4.2,
//         body: " We reserve the absolute right, without prior notice to you, to suspend your access to the Service where the amount in your wallet is not sufficient to pay for the Service.",
//       },
//       {
//         index: 4.3,
//         body: "Please note that we are under no obligation to refund any funds left in your Wallet should you choose to terminate these Terms. You shall endeavor to exhaust the funds contained in your Wallet. ",
//       },
//     ],
//   },

//   {
//     index: 5,
//     title: "INDEMNITY",
//     content: [
//       {
//         body: "In the event that we incur any costs or suffer any loss, claims or damage as a result of your failure or refusal to observe any of these Terms or conditions, or you fail to observe any data protection rules, you shall indemnify us and hold us harmless against all such claims, costs and/or loss. ",
//       },
//     ],
//   },

//   {
//     index: 6,
//     title: "LIMITATION OF LIABILITY ",
//     content: [
//       {
//         index: 6.1,
//         body: "You acknowledge that we do not, and cannot guarantee or warrant the correctness, completeness, accuracy and/or fitness for purpose of any information in any report which we may issue further to the performance of the Services under these Terms, as such information usually is not the product of an independent investigation by us but an aggregation of data collected by licensed partners with whom we maintain a connection with. You also acknowledge and agree that there is a possibility that such information will contain errors and/or, omissions due to various factors including challenges and/or negligence of the third parties providing data to us. You undertake that you shall therefore rely on the information at your sole risk and that we shall not be liable to you for any loss or injury arising out of or caused, in whole or in part from the use of the information, except for acts of negligence by us in compiling, collecting, reporting, communicating and/or delivering the information. ",
//       },
//       {
//         index: 6.2,
//         body: "If you suffer any loss or damage as a result of Prembly’s negligence; or breach of these Terms, any claim by you against Prembly arising from such negligence or failure will be limited in respect of any one incident, or series of connected incidents, to the fees paid by you in the previous 6 months from the date of such incident.",
//       },
//       {
//         index: 6.3,
//         body: "If you are not satisfied with the Service, you may reach out to any of our customer service agents or send an email to [sales@myidentitypass.com] to lay your complaints. We shall employ our best effort to see that your complaints are resolved. In the unlikely event that we are unable to resolve your complaints, or you remain dissatisfied with our services, you may terminate these Terms in accordance with Clause 7 of these Terms. ",
//       },
//     ],
//   },

//   {
//     index: 7,
//     title: "TERMINATION",
//     content: [
//       {
//         index: 7.1,
//         body: "No fault termination: These terms will subsist while you continue to use the Service, provided that you pay the prescribed fees when due, unless either of us terminates these Terms by giving a 30-days’ notice to the other before the end of the relevant payment period. ",
//       },
//       {
//         index: 7.2,
//         body: "Breach If you; ",
//       },

//       {
//         subindex: 1,
//         subbody:
//           "breach any of these Terms (including without limitations, by non-payment of any fees) and do not remedy the breach within 14 days after receiving notice of the breach if the breach is capable of being remedied;",
//       },

//       {
//         subindex: 2,
//         subbody:
//           "breach any of these Terms and the breach is not capable of being remedied (which includes without limitation, non-payment of any fees that are more than 30 days overdue); or ",
//       },

//       {
//         subindex: 3,
//         subbody:
//           "you or your business becomes insolvent, or your business goes into liquidation or has a receiver or manager appointed of any of its assets, or if you become insolvent, or make any arrangement with your creditors, or become subject to any similar insolvency event in any jurisdiction,",
//       },

//       {
//         newsects:
//           "Prembly may take any or all of the following actions, at its sole discretion:",
//       },

//       {
//         subindex: 1,
//         subbody:
//           "terminate these Terms or your use of the Services and any of our platforms;",
//       },
//       {
//         subindex: 2,
//         subbody:
//           "suspend for any definite or indefinite period of time, your use of the Services and the website;",
//       },
//       {
//         subindex: 3,
//         subbody: "suspend access to all or any data.",
//       },

//       {
//         index: 7.3,
//         body: "Accrued Rights ",
//       },
//       {
//         newsect:
//           "Termination of these Terms is without prejudice to any rights and obligations of the parties accrued up to and including the date of termination. On termination of these Terms by Prembly, you will immediately cease to use the Services.",
//       },
//     ],
//   },

//   {
//     index: 8,
//     title: "CONFIDENTIALITY ",
//     content: [
//       {
//         index: 8.1,
//         body: "Unless the recipient of a confidential information has the prior written consent of the disclosing party or unless required to do so by law, the recipient shall preserve the confidentiality of all Confidential Information of the disclosing obtained in connection with these Terms and the Services. Neither party will, without the prior written consent of the other, disclose or make any Confidential Information available to a third party or use same for its own benefit, other than as contemplated by these Terms.",
//       },
//       {
//         index: 8.2,
//         body: "Our (You and Prembly) obligation of confidentiality under this clause shall continue and survive the termination of these Terms.",
//       },
//       {
//         index: 8.3,
//         body: "The provisions of this clause shall not apply to information which is:",
//       },

//       {
//         subindex: 1,
//         subbody:
//           " is or becomes public knowledge other than by breach of this clause;",
//       },
//       {
//         subindex: 2,
//         subbody:
//           " is received from a third party who lawfully acquired it and who is under no obligation restricting its disclosure;",
//       },
//       {
//         subindex: 3,
//         subbody:
//           " is in the possession of the receiving party without restriction in relation to disclosure before the date of receipt from the disclosing party; or",
//       },
//       {
//         subindex: 4,
//         subbody:
//           "is independently developed without access to the Confidential Information.",
//       },
//       {
//         index: 8.4,
//         body: "For the purposes of this clause, “Confidential Information” means all information relating to the disclosing party which is obtained, whether in writing, pictorially, in machine readable form or orally or by observation in connection with these Terms, including but without limitation, APIs, source codes, financial information, know-how, processes, ideas, intellectual property (irrespective of its registration or patentability status), schematics, trade secrets, technology, customer list (potential or actual) and other customer-related information, sales statistics, market, market intelligence, marketing and other business strategies and other commercial information of a secret, proprietary or confidential nature. ",
//       },
//     ],
//   },

//   {
//     index: 9,
//     title: "PRIVACY",
//     content: [
//       {
//         // index: 4.1,
//         body: "Prembly takes your privacy seriously. You have the right to know what we do with your personal information so you can make informed decisions while subscribing to our Services. We maintain a privacy policy that sets out our collective obligations in respect of information shared between us. You should read that policy here and you will be taken to have accepted the privacy policy when you accept these Terms. ",
//       },
//     ],
//   },

//   {
//     index: 10,
//     title: "INTELLECTUAL PROPERTY",
//     content: [
//       {
//         index: 10.1,
//         body: "General:",
//       },
//       {
//         subindex: 1,
//         subbody:
//           " Title to, and all Intellectual Property Rights in the Services, the website and any documentation relating to the Serices shall remain the property of Prembly (or its licensors).",
//       },
//       {
//         subindex: 2,
//         subbody:
//           "For the purpose of these Terms, “Intellectual Property Right” means any patent, trademark, service mark, copyright, moral right, right in a design, know-how and any other intellectual or industrial property rights, anywhere in the world, whether or not registered.",
//       },
//       {
//         index: 10.2,
//         body: "Ownership of data: ",
//       },
//       {
//         subbody:
//           "Title to, and all Intellectual Property Rights in, the information you share with us remain your property. However, your access to your information with us is contingent upon full payment of the fees when due. You grant Prembly a license to use, copy, transmit, store, and back-up any information which you share with us for the purpose of enabling you access and use the Services, and for any other purpose related to the provision of the Services to you. ",
//       },
//       {
//         index: 10.3,
//         body: "Security and Backup of data: ",
//       },
//       {
//         subindex: 1,
//         subbody:
//           "We care about the integrity and security of your information. Prembly adheres to best practice policies and procedures to prevent data loss, unauthorized access, use, alteration, or disclosure. However, you acknowledge and agree that despite our reasonable security measures, we cannot guarantee that electronic communications over the Internet will be 100% secure and that unauthorized third parties will never be able to defeat those measures or use your User Information for improper purposes. You also acknowledge that you are under no obligation to use the Services, and that you provide any personal or sensitive information in order to use the Services at your own risk.",
//       },

//       {
//         subindex: 2,
//         subbody:
//           "Notwithstanding anything in these Terms, Prembly expressly excludes liability for any loss of data no matter how caused.",
//       },
//     ],
//   },

//   {
//     index: 11,
//     title: "INFORMATION ABOUT US  ",
//     content: [
//       {
//         // index: 11.1,
//         body: "The Website contains information about us. While this information was believed to be accurate as of the date prepared, we disclaim any duty or obligation to update this information. Information about companies other than ours contained on the Website, should not be relied upon as being provided or endorsed by us.",
//       },
//     ],
//   },

//   {
//     index: 12,
//     title: "HELP DESK",
//     content: [
//       {
//         index: 12.1,
//         body: "Technical Problems: In the case of technical problems, you must take all reasonable efforts to investigate and diagnose problems before contacting Prembly. If you still need technical help, please check the support provided by Prembly on the website or send us an email at [Team@identitypass.com]",
//       },
//       {
//         index: 12.2,
//         body: "Service Availability: While Prembly intends that the Services should be available 24 hours a day, seven days a week, it is possible that on occasions the Services or website may be unavailable to permit maintenance or other development activity to take place. If for any reason Prembly has to interrupt the Services for longer periods than Prembly would normally expect, Premby will use reasonable endeavours to publish in advance, details of such activity on the website.",
//       },
//     ],
//   },

//   {
//     index: 13,
//     title: "GENERAL",
//     content: [
//       {
//         index: 13.1,
//         body: "Governing Law: These Terms are made under and will be governed by and construed in accordance with the laws of the Federal Republic of Nigeria, without giving effect to any principle(s) that provide for the application of the law of another jurisdiction.",
//       },
//       {
//         index: 13.2,
//         body: "No Waiver: The failure of Prembly to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.",
//       },
//       {
//         index: 13.3,
//         body: "Severability: If any provisions of the Terms are found by a court of competent jurisdiction to be invalid, the invalidity of such provision shall not affect the legality, enforceability or validity of the remaining provisions which shall remain in full force and effect.",
//       },

//       {
//         index: 13.4,
//         body: "Assignment: Prembly expressly reserves the right to assign, delegate and transfer these Terms and its rights and obligations under these Terms in part or as a whole and including without limitation, to a subsidiary, affiliate, successor or any third-party whatsoever without your consent. You shall not transfer, assign or delegate these Terms, or your rights, obligations and User Account in any manner whatsoever under these Terms, to any person or entity without our prior written approval.",
//       },
//       {
//         index: 13.5,
//         body: "Entire Agreement: These Terms together with our Privacy Policy, and any other legal notices published by us, shall constitute the entire agreement between us and you with respect to the Website, the content, products or Services provided by us or through the Website. You hereby acknowledge and agree that you are not an employee, agent, or partner of Prembly, and you do not have any authority of any kind to bind Prembly in any respect whatsoever.",
//       },
//       {
//         index: 13.6,
//         body: "Additional Terms: In conjunction with your access or use of the Services, you may be subject to additional terms, rules, policies, and conditions that are posted on the Website, including, but not limited to, terms and conditions for our referral or rewards programs (the “Additional Terms”), which are hereby incorporated by reference into these Terms. In the event of a conflict between any Additional Terms and these Terms, these Terms shall prevail.",
//       },

//       {
//         index: 13.7,
//         body: "Force Majeure Event: If a force majeure event gives rise to a failure of delay in either party performing any obligation under these Terms (other than any obligation to make payment), that obligation will be suspended for the duration of the force majeure event. A party that becomes aware of a force majeure event which gives rise to, or which is likely to give rise to, any failure or delay in that party performing any obligation under these Terms, must promptly notify the other, and inform the other of the period for which it is estimated that such failure or delay will continue. A party whose performance of its obligations under these Terms is affected by a force majeure must take reasonable steps to mitigate the effects of the force majeure event.",
//       },
//     ],
//   },

//   {
//     index: 14,
//     title: "CONTACT US",
//     content: [
//       {
//         // index: 4.1,
//         body: "You may contact our data privacy team via the following email address: team@myidentitypass.com",
//       },
//     ],
//   },
// ];

// export default function TermsPolicy() {
//   const [searchTerm, setSearchTerm] = useState("");

//   const [fillteredTermsAndPolicyData, setfillteredTermsAndPolicyData] =
//     useState(termsAndPolicyData);

//   useEffect(() => {
//     if (searchTerm.length > 0) {
//       const search = searchTerm.toLowerCase();
//       const filter = termsAndPolicyData.filter((data) =>
//         data.title.toLowerCase().includes(search)
//       );
//       // console.log(search, filter);
//       setfillteredTermsAndPolicyData(
//         filter.length > 0 ? filter : termsAndPolicyData
//       );
//     }
//     //
//   }, [searchTerm]);

//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value);
//   };
//   return (
//     <div>
//       <NavBar2 />
//       <section className="frequentQs-area">
//         <div className="container">
//           <div className="col-lg-12 mx-auto">
//             <div className="firstlayers row col-md-12 mx-auto justify-content-center text-center">
//               <h1>Prembly Terms of Use</h1>
//             </div>

//             <div className="searchelp mt-5 col-md-5 mx-auto">
//               <i class="ri-search-line"></i>
//               <input
//                 type="search"
//                 placeholder="Enter a keyword"
//                 className="helpsearchbar"
//                 value={searchTerm}
//                 onChange={handleSearch}
//               />
//             </div>
//           </div>
//         </div>
//       </section>

//       <section className="statement py-5" id="statement">
//         <div className="container">
//           <div className="row  justify-content-center privacy-gap">
//             <div className="col-md-10 col-lg-2 privacy-boxs">
//               <h5>CONTENT OUTLINE</h5>
//               <div style={{ paddingTop: "20px" }}>
//                 <h3 id="introduction">INTRODUCTION</h3>

//                 <div className="outlines">
//                   <p>GENERAL SERVICE TERMS</p>
//                   <p>MODIFICATIONS </p>
//                   <p>DATA PROTECTION</p>
//                   <p>PAYMENT AND FEES</p>
//                   <p>INDEMNITY</p>
//                   <p>LIMITATION OF LIABILITY </p>
//                   <p>TERMINATION</p>
//                   <p>CONFIDENTIALITY</p>
//                   <p>PRIVACY</p>
//                   <p>INTELLECTUAL PROPERTY</p>
//                   <p>HELP DESK</p>
//                   <p>GENERAL</p>
//                   <p>Violation of Privacy Policy</p>
//                   <p>CONTACT US</p>
//                 </div>

//                 {/* {dataContext
//                   .filter((value) => {
//                     if (searchTerm === "") {
//                       return value;
//                     } else if (
//                       value.contextName
//                         .toLowerCase()
//                         .includes(searchTerm.toLowerCase())
//                     ) {
//                       return value;
//                     }
//                   })
//                   .map((value) => {
//                     return (
//                       <div className="outlines" key={value.id}>
//                         <p style={{ cursor: "pointer" }}>{value.contextName}</p>
//                       </div>
//                     );
//                   })} */}
//               </div>
//             </div>

//             <div className="col-md-10 col-lg-9 info-policy">
//               <div id="introduction 1" value={"introduction"}>
//                 <h4>INTRODUCTION</h4>
//                 <p>
//                   Prembly is a verification and compliance services company
//                   providing its services through a vast array of user-friendly
//                   software and APIs. These Terms of Use (the “Terms”), together
//                   with any and all specific terms and conditions for each of our
//                   products and solutions (“Services”), as amended from time to
//                   time, governs your use of the Services.
//                   <br />
//                   <br />
//                   Please read these terms carefully to ensure that you
//                   understand each provision. by downloading, registering an
//                   account, signing into, accessing, browsing, or otherwise using
//                   the platform whether as a guest or registered user, or
//                   otherwise accepting these terms, you are signifying that you
//                   have read and understood the terms, and agree to be bound by
//                   these terms, all guidelines, our privacy policy and any and
//                   all future modifications to these terms.  
//                 </p>
//               </div>
//               {fillteredTermsAndPolicyData.map((value) => {
//                 return (
//                   <div id="modification 3">
//                     <h4>
//                       {value.index}. {value.title}
//                     </h4>
//                     <div>
//                       {value.content.map((content) => (
//                         <div className="decendant">
//                           <p>{content.index}</p>
//                           <p>{content.body}</p>
//                           <div
//                             style={{
//                               // paddingLeft: "3\0px",
//                               display: "flex",
//                               gap: "5px",
//                             }}
//                           >
//                             <p style={{ margin: "-10px 0px 10px 0px" }}>
//                               {content.subindex}
//                             </p>
//                             <p style={{ margin: "-10px 0px 10px 0px" }}>
//                               {content.subbody}
//                             </p>
//                             <p style={{ margin: "-15px 0px 10px -10px" }}>
//                               {content.newsects}
//                             </p>
//                             <p style={{ margin: "-15px 0px 10px -7px" }}>
//                               {content.newsect}
//                             </p>
//                           </div>
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 );
//               })}

//               <p>
//                 <strong>
//                   Last updated: This Privacy Policy was last updated on February
//                   23, 2023
//                 </strong>
//               </p>
//             </div>
//             {/* <div className="col-md-10 col-lg-9 info-policy"> */}

//             {/* </div> */}
//           </div>
//         </div>
//       </section>

//       <section className="cant-find-answer">
//         <div className="container">
//           <div className="col-md-10 col-lg-12 mx-auto py-5">
//             <div className="cant-find">
//               <h3>Can’t find the answer you are looking for?</h3>
//               <div className="">
//                 <a
//                   className="btn btn-sales  me-1"
//                   href="https://calendly.com/premblysalesteam/45mins"
//                   target="_blank"
//                   rel="noreferrer"
//                 >
//                   Contact Sales
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <LatestF />
//       <Footer />
//     </div>
//   );
// }



import React, { useState, useEffect } from "react";
// import Footer from "../../components/footer";
import Footer from "../../components/newNavbar/Footer";
import NavbarArea from "../../components/navbar";
import LatestF from "../allProduct/LatestF";
import { dataContext } from "../../pages/terms/termsData";
import { useParams } from "react-router-dom";
import NavBar2 from "../../components/newNavbar/NavBar";

const termsAndPolicyData = [
  {
    index: 1,
    title: "GENERAL SERVICE TERMS",
    content: [
      {
        index: 1.1,
        body: "You will ensure that all information you provide to us via any of the Prembly websites or platforms (e.g., information provided in connection with your registration for the Services, community group, etc.) is accurate, correct, complete, and not misleading. ",
      },

      {
        index: 1.2,
        body: "In processing the personal data of third parties supplied by you in relation to your use of the Service, you bear the responsibility of obtaining necessary consents for processing such data and providing legally adequate privacy notices to such third parties. You hereby represent to us that you have provided all necessary privacy notices and obtained all necessary consents. You are responsible for processing and storing the information exchanged between us under the Services in relation to these third parties.  ",
      },

      {
        index: 1.3,
        body: "You are responsible for the maintenance of licenses and adhering to the license terms for any software you run in relation to your use of the Services. If we reasonably believe that any of the information supplied to us violates the law or any regulation, infringes or misappropriates the rights of a third party, or otherwise violates a material provision of the Term (“Prohibited Content”), we may notify you of the Prohibited Content and may, within 2 days of such notice, remove or discontinue the use of such Content in the provision of the Services. Where we deem it necessary in our sole discretion, we may without notice to you, remove such Prohibited Content and/or suspend your access to our Services where we believe such Prohibited Content may disrupt or threaten the Services, or in accordance with applicable law or any judicial, regulatory or governmental order or request. In the event that we remove the Prohibited Content without prior notice, we will provide prompt notice to you unless prohibited by law. ",
      },

      {
        index: 1.4,
        body: "You will provide all information or documentation related to you and your business which may be reasonably requested by us to verify your compliance with the Terms. You will reasonably cooperate with us to identify the source of any problem with the Services that we reasonably believe may be attributable to the information supplied by you or any end user materials which you control. "
      },

      {
        index: 1.5,
        body: "You may not transfer outside the Services any software or documentation you obtain from us in connection with the Services without our specific written authorization from ourselves to do so."

      },
    ],
  },

  {
    index: 2,
    title: "MODIFICATIONS",
    content: [
      {
        index: 2.1,

        body: "All modifications are effective immediately we upload them. We, therefore, advise that you review these Terms prior to using our Services. You are free to decide whether or not to accept a revised version of these Terms, you are however, required to accept the updated Terms to accessing or us in the Platform. If you do not agree to these Terms or any updated version of these Terms, your sole recourse is to terminate your access or use of the Services. "
      },

      {
        index: 2.2,
        body: "Except as otherwise expressly stated by us, your access and use of the Platform are subject to and constitute your acceptance of, the version of these Terms in effect at the time of your access or use. "
      },

      // {
      //   index: 2.3,
      //   body: "You are responsible for the maintenance of licenses and adhering to the license terms for any software you run in relation to your use of the Services. If we reasonably believe that any of the information supplied to us violates the law, infringes or misappropriates the rights of a third party, or otherwise violates a material provision of the Term (“Prohibited Content”), we will notify you of the Prohibited Content and may, within 2 days of such notice, remove or discontinue the use of such Content in the provision of the Services. Where we deem it necessary in our sole discretion, we may without notice to you, remove such Prohibited Content and/or suspend your access to our Services where we believe such Prohibited Content may disrupt or threaten the Services, or in accordance with applicable law or any judicial, regulatory or governmental order or request. In the event that we remove the Prohibited Content without prior notice, we will provide prompt notice to you unless prohibited by law.",
      // },
    ],
  },

  {
    index: 3,
    title: "DATA PROTECTION",
    content: [
      {
        index: 3.1,
        body: "We shall ensure that every information you share with us is processed and stored in line with the provisions of the relevant data protection laws. "
      },

      // {
      //   index: 3.2,
      //   body: "All modifications are effective immediately we upload them. We therefore, advise that you review these Terms prior to using our Services. You are free to decide whether or not to accept a revised version of these Terms, you are however, required to accept the updated Terms to accessing or us in the Platform. If you do not agree to these Terms or any updated version of these Terms, your sole recourse is to terminate your access or use of the Services.",
      // },

      // {
      //   index: 3.3,
      //   body: " Except as otherwise expressly stated by us, your access and use of the Platform are subject to and constitute your acceptance of, the version of these Terms in effect at the time of your access or use. ",
      // },
    ],
  },

  {
    index: 4,
    title: " PAYMENT AND FEES",
    content: [
      {
        index: 4.1,
        body: "Our fees and charges for the use of the Service shall be contained in the Fee Schedule. We may in our absolute discretion review these fees and charges and shall notify you at least one (1) month prior to such reviews becoming effective. Prembly reserves the right to adjust the fees and data pricing to reflect changes in service providers, partners or economic conditions. "
      },
      {
        index: 4.2,
        body: "You are required to purchase ID Pass units from us to enable you access the Service. We shall maintain these units in an account with us (the “Wallet”). Upon accessing our Service, we shall debit the applicable amount of ID Pass Units from your Wallet as consideration for the Service. It is your obligation to ensure that your ID Pass Account is always in credit to ensure continued access to and use of the Service.  "
      },
      {
        index: 4.3,
        body: "You understand that you may not be able to access any or all of our Services if the amount in your Wallet is insufficient to fund the Service requested. Please note that you are not entitled to a refund of any amount paid by you after subscribing to or funding your wallet for any of our Services. "
      },

      {
        index: 4.4,
        body: "We reserve the absolute right, without prior notice to you, to suspend your access to the Service where the amount in your wallet is not sufficient to pay for the Service. "
      },
      {
        index: 4.5,
        body: "Please note that we are under no obligation to refund any funds left in your Wallet should you choose to terminate these Terms. You shall endeavor to exhaust the funds contained in your Wallet. "
      }

    ],
  },

  {
    index: 5,
    title: "INDEMNITY",
    content: [
      {
        body: "In the event that we incur any costs or suffer any loss, claims or damage as a result of your failure or refusal to observe any of these Terms or conditions, or you fail to observe any data protection rules, you hereby  agree to indemnify, defend and hold harmless Prembly, its affiliates, officers, directors, employees, consultants and agents from any and all third party claims, liability, damages and/or costs (including, but not limited to, attorneys fees) arising from your use of our services, your violation of the Terms of Service or your infringement, or infringement by any other user of your account, of any intellectual property or other right of any person or entity. "
      },
    ],
  },

  {
    index: 6,
    title: "LIMITATION OF LIABILITY ",
    content: [
      {
        index: 6.1,
        body: "The Service is used on  “as is” basis. Notwithstanding anything else in these Terms and Conditions, to the maximum extent permitted by applicable law, the service is provided on an “as is” and “as available” basis, and Prembly makes no representations or warranties and there are no conditions of any kind, either express, implied or statutory, including but not limited to any warranties of non-interference, merchantability, fitness for a particular purpose or use, or arising from course of dealing or usage of trade. You assume all responsibility and risk for use of the service.  Any statements made in any written or electronic documents, or by any Prembly employees, representatives, or agents, are provided for informational purposes only and not as representations or warranties of any kind.   Prembly does not warrant that service will be uninterrupted, error free, or free from service degradation, or that any information, software, or other material accessible on the service are free from viruses, worms, trojan horses, or other code that manifests contaminating, interfering, or destructive properties.  Prembly cannot and does not guarantee the security or integrity of data transmission or storage, or that viruses, worms, trojan horses, or other code that manifests contaminating or destructive properties will be detected or remediated by the service.  You acknowledge that the service may be temporarily unavailable for maintenance, equipment modifications, or upgrades, and for other reasons within and without the direct control of Prembly.   "
      },
      {
        index: 6.2,
        body: "You acknowledge that we do not, and cannot guarantee or warrant the correctness, completeness, accuracy and/or fitness for purpose of any information in any report which we may issue further to the performance of the Services under these Terms, as such information usually is not the product of an independent investigation by us but an aggregation of data collected by licensed partners with whom we maintain a connection with. You also acknowledge and agree that there is a possibility that such information will contain errors and/or, omissions due to various factors including challenges and/or negligence of the third parties providing data to us. You undertake that you shall therefore rely on the information at your sole risk and that we shall not be liable to you for any loss or injury arising out of or caused, in whole or in part from the use of the information, except for acts of negligence by us in compiling, collecting, reporting, communicating and/or delivering the information.  "
      },
      {
        index: 6.3,
        body: "If you suffer any loss or damage as a result of Prembly’s negligence; or breach of these Terms, any claim by you against Prembly arising from such negligence or failure will be limited in respect of any one incident, or series of connected incidents, to the fees paid by you in the previous 6 months from the date of such incident. "
      },
      {
        index: 6.4,
        body: "Notwithstanding anything in these Terms, Prembly expressly excludes liability for any loss of data no matter how caused "
      },
      {
        index: 6.5,
        body: "If you are not satisfied with the Service, you may reach out to any of our customer service agents or send an email to [support@prembly.com] to lay your complaints. We shall employ our best effort to see that your complaints are resolved. In the unlikely event that we are unable to resolve your complaints, or you remain dissatisfied with our services, you may terminate these Terms in accordance with Clause 7 of these Terms.  "
      }

    ],
  },

  {
    index: 7,
    title: "TERMINATION",
    content: [
      {
        index: 7.1,
        body: "No fault termination: These terms will subsist while you continue to use the Service, unless either of us terminates these Terms by giving a 30-days’ notice to the other before the end of the relevant payment period.  "
      },
      {
        index: 7.2,
        body: "Breach If you; ",
      },

      {
        subindex: 1,
        subbody: "breach any of these Terms (including without limitations, by non-payment of any fees) and do not remedy the breach within 14 days after receiving notice of the breach if the breach is capable of being remedied; "
      },

      {
        subindex: 2,
        subbody:
          "breach any of these Terms and the breach is not capable of being remedied (which includes without limitation, non-payment of any fees that are more than 30 days overdue); or "
      },

      {
        subindex: 3,
        subbody:
          "you or your business becomes insolvent, or your business goes into liquidation or has a receiver or manager appointed of any of its assets, or if you become insolvent, or make any arrangement with your creditors, or become subject to any similar insolvency event in any jurisdiction, "
      },

      {
        newsects:
          "Prembly may take any or all of the following actions, at its sole discretion:",
      },

      {
        subindex: 1,
        subbody:
          "terminate these Terms or your use of the Services and any of our platforms;",
      },
      {
        subindex: 2,
        subbody:
          "suspend for any definite or indefinite period of time, your use of the Services and the website;",
      },
      {
        subindex: 3,
        subbody: "suspend access to all or any data.",
      },

      {
        index: 7.3,
        body: "Accrued Rights ",
      },
      {
        newsect:
          "Termination of these Terms is without prejudice to any rights and obligations of the parties accrued up to and including the date of termination. On termination of these Terms by Prembly, you will immediately cease to use the Services. "
      },
    ],
  },

  {
    index: 8,
    title: "CONFIDENTIALITY ",
    content: [
      {
        index: 8.1,
        body: "Unless the recipient of a confidential information has the prior written consent of the disclosing party or unless required to do so by law, the recipient shall preserve the confidentiality of all Confidential Information of the disclosing obtained in connection with these Terms and the Services. Neither party will, without the prior written consent of the other, disclose or make any Confidential Information available to a third party or use same for its own benefit, other than as contemplated by these Terms. "
      },
      {
        index: 8.2,
        body: "Our (You and Prembly) obligation of confidentiality under this clause shall continue and survive the termination of these Terms. "
      },
      {
        index: 8.3,
        body: "The provisions of this clause shall not apply to information which is:",
      },

      {
        subindex: 1,
        subbody:
          " is or becomes public knowledge other than by breach of this clause;",
      },
      {
        subindex: 2,
        subbody:
          " is received from a third party who lawfully acquired it and who is under no obligation restricting its disclosure;",
      },
      {
        subindex: 3,
        subbody:
          " is in the possession of the receiving party without restriction in relation to disclosure before the date of receipt from the disclosing party; or",
      },
      {
        subindex: 4,
        subbody:
          "is independently developed without access to the Confidential Information.",
      },
      {
        index: 8.4,
        body: "For the purposes of this clause, “Confidential Information” means all information relating to the disclosing party which is obtained, whether in writing, pictorially, in machine readable form or orally or by observation in connection with these Terms, including but without limitation, APIs, source codes, financial information, know-how, processes, ideas, intellectual property (irrespective of its registration or patentability status), schematics, trade secrets, technology, customer list (potential or actual) and other customer-related information, sales statistics, market, market intelligence, marketing and other business strategies and other commercial information of a secret, proprietary or confidential nature.  "
      },
    ],
  },

  {
    index: 9,
    title: "PRIVACY",
    content: [
      {
        // index: 4.1,
        body: "Prembly takes your privacy seriously. You have the right to know what we do with your personal information so you can make informed decisions while subscribing to our Services. We maintain a Privacy Policy that sets out our collective obligations in respect of information shared between us. You should read that policy here and you will be taken to have accepted the privacy policy when you accept these Terms.  "
      },
    ],
  },

  {
    index: 10,
    title: "INTELLECTUAL PROPERTY",
    content: [
      {
        index: 10.1,
        body: "General:",
      },
      {
        subindex: 1,
        subbody:
          " Title to, and all Intellectual Property Rights in the Services, the website and any documentation relating to the Serices shall remain the property of Prembly (or its licensors).",
      },
      {
        subindex: 2,
        subbody:
          "For the purpose of these Terms, “Intellectual Property Right” means any patent, trademark, service mark, copyright, moral right, right in a design, know-how and any other intellectual or industrial property rights, anywhere in the world, whether or not registered. "
      },
      {
        index: 10.2,
        body: "Ownership of data: ",
      },
      {
        subbody:
          "Title to, and all Intellectual Property Rights in, the information you share with us remain your property. However, your access to your information with us is contingent upon full payment of the fees when due. You grant Prembly a license to use, copy, transmit, store, and back-up any information which you share with us for the purpose of enabling you access and use the Services, and for any other purpose related to the provision of the Services to you."
      },
      {
        index: 10.3,
        body: "Security and Backup of data: ",
      },
      {
        subindex: 1,
        subbody: " We care about the integrity and security of your information. Prembly adheres to best practice policies and procedures to prevent data loss, unauthorized access, use, alteration, or disclosure. However, you acknowledge and agree that despite our reasonable security measures, we cannot guarantee that electronic communications over the Internet will be 100% secure and that unauthorized third parties will never be able to defeat those measures or use your User Information for improper purposes. You also acknowledge that you are under no obligation to use the Services, and that you provide any personal or sensitive information in order to use the Services at your own risk."
      },

    ],
  },

  {
    index: 11,
    title: "INFORMATION ABOUT US",
    content: [
      {
        // index: 11.1,
        body: "The Website contains information about us. While this information was believed to be accurate as of the date prepared, we disclaim any duty or obligation to update this information. Information about companies other than ours contained on the Website, should not be relied upon as being provided or endorsed by us. "
      },
    ],
  },

  {
    index: 12,
    title: "HELP DESK",
    content: [
      {
        index: 12.1,
        body: "Technical Problems: In the case of technical problems, you must take all reasonable efforts to investigate and diagnose problems before contacting Prembly. If you still need technical help, please check the support provided by Prembly on the website or send us an email at [Team@identitypass.com]",
      },
      {
        index: 12.2,
        body: "Service Availability: While Prembly intends that the Services should be available 24 hours a day, seven days a week, it is possible that on occasions the Services or website may be unavailable to permit maintenance or other development activity to take place. If for any reason Prembly has to interrupt the Services for longer periods than Prembly would normally expect, Premby will use reasonable endeavours to publish in advance, details of such activity on the website.",
      },
    ],
  },

  {
    index: 13,
    title: "GENERAL",
    content: [
      {
        index: 13.1,
        body: "Governing Law: These Terms are made under and will be governed by and construed in accordance with the laws of the Federal Republic of Nigeria, without giving effect to any principle(s) that provide for the application of the law of another jurisdiction.",
      },
      {
        index: 13.2,
        body: "No Waiver: The failure of Prembly to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.",
      },
      {
        index: 13.3,
        body: "Severability: If any provisions of the Terms are found by a court of competent jurisdiction to be invalid, the invalidity of such provision shall not affect the legality, enforceability or validity of the remaining provisions which shall remain in full force and effect.",
      },

      {
        index: 13.4,
        body: "Assignment: Prembly expressly reserves the right to assign, delegate and transfer these Terms and its rights and obligations under these Terms in part or as a whole and including without limitation, to a subsidiary, affiliate, successor or any third-party whatsoever without your consent. You shall not transfer, assign or delegate these Terms, or your rights, obligations and User Account in any manner whatsoever under these Terms, to any person or entity without our prior written approval. "
      },
      {
        index: 13.5,

        body: "Customer Data: Prembly may retain relevant customer data as necessary to provide the Service. The Software User is solely responsible for obtaining all necessary consents from data owners and ensuring compliance with applicable data privacy laws. Prembly shall not be liable for any damages or losses arising from the Software User's failure to obtain such consents. "
      },
      {
        index: 13.6,
        body: "Interaction Records: Prembly reserves the right to retain copies of all interactions with the Software User, including but not limited to email correspondence, chat logs, and support tickets. These records may be used for customer support purposes, quality control, and other legitimate business needs. "
      },

      {
        index: 13.7,
        body: "Entire Agreement: These Terms together with our Privacy Policy, and any other legal notices published by us, shall constitute the entire agreement between us and you with respect to the Website, the content, products or Services provided by us or through the Website. You hereby acknowledge and agree that you are not an employee, agent, or partner of Prembly, and you do not have any authority of any kind to bind Prembly in any respect whatsoever. "
      },
      {
        index: 13.8,
        body: "Additional Terms: In conjunction with your access or use of the Services, you may be subject to additional terms, rules, policies, and conditions that are posted on the Website, including, but not limited to, terms and conditions for our referral or rewards programs (the “Additional Terms”), which are hereby incorporated by reference into these Terms. In the event of a conflict between any Additional Terms and these Terms, these Terms shall prevail. "
      },
      {
        index: 13.9,
        body: "Force Majeure Event: If a force majeure event gives rise to a failure of delay in either party performing any obligation under these Terms (other than any obligation to make payment), that obligation will be suspended for the duration of the force majeure event. A party that becomes aware of a force majeure event which gives rise to, or which is likely to give rise to, any failure or delay in that party performing any obligation under these Terms, must promptly notify the other, and inform the other of the period for which it is estimated that such failure or delay will continue. A party whose performance of its obligations under these Terms is affected by a force majeure must take reasonable steps to mitigate the effects of the force majeure event. "
      },
      {
        index: 13.10,
        body: "These Terms of Service read in conjunction with other specific API documentations or terms constitute the entire agreement between the parties with respect to the subject matter hereof and supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter. Any waiver of any provision of the Terms of Service will be effective only if in writing and signed by Prembly. "
      }
    ],
  },

  {
    index: 14,
    title: "CONTACT US",
    content: [
      {
        // index: 4.1,
        body: "You may contact our data privacy team via the following email address: team@myidentitypass.com",
      },

    ],
  },
];

export default function TermsPolicy() {
  const [searchTerm, setSearchTerm] = useState("");

  const [fillteredTermsAndPolicyData, setfillteredTermsAndPolicyData] =
    useState(termsAndPolicyData);

  useEffect(() => {
    if (searchTerm.length > 0) {
      const search = searchTerm.toLowerCase();
      const filter = termsAndPolicyData.filter((data) =>
        data.title.toLowerCase().includes(search)
      );
      // console.log(search, filter);
      setfillteredTermsAndPolicyData(
        filter.length > 0 ? filter : termsAndPolicyData
      );
    }
    //
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  return (
    <div>
      <NavBar2 />
      <section className="frequentQs-area">
        <div className="container">
          <div className="col-lg-12 mx-auto">
            <div className="firstlayers row col-md-12 mx-auto justify-content-center text-center">
              <h1>Prembly Terms of Use</h1>
            </div>

            <div className="searchelp mt-5 col-md-5 mx-auto">
              <i class="ri-search-line"></i>
              <input
                type="search"
                placeholder="Enter a keyword"
                className="helpsearchbar"
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="statement py-5" id="statement">
        <div className="container">
          <div className="row  justify-content-center privacy-gap">
            <div className="col-md-10 col-lg-2 privacy-boxs">
              <h5>CONTENT OUTLINE</h5>
              <div style={{ paddingTop: "20px" }}>
                <h3 id="introduction">INTRODUCTION</h3>

                <div className="outlines">
                  <p>GENERAL SERVICE TERMS</p>
                  <p>MODIFICATIONS </p>
                  <p>DATA PROTECTION</p>
                  <p>PAYMENT AND FEES</p>
                  <p>INDEMNITY</p>
                  <p>LIMITATION OF LIABILITY </p>
                  <p>TERMINATION</p>
                  <p>CONFIDENTIALITY</p>
                  <p>PRIVACY</p>
                  <p>INTELLECTUAL PROPERTY</p>
                  <p>HELP DESK</p>
                  <p>GENERAL</p>
                  <p>Violation of Privacy Policy</p>
                  <p>CONTACT US</p>
                </div>

                {/* {dataContext
                  .filter((value) => {
                    if (searchTerm === "") {
                      return value;
                    } else if (
                      value.contextName
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return value;
                    }
                  })
                  .map((value) => {
                    return (
                      <div className="outlines" key={value.id}>
                        <p style={{ cursor: "pointer" }}>{value.contextName}</p>
                      </div>
                    );
                  })} */}
              </div>
            </div>

            <div className="col-md-10 col-lg-9 info-policy">
              <div id="introduction 1" value={"introduction"}>
                <h4>INTRODUCTION</h4>
                <p>
                  Prembly is a verification and compliance services company
                  providing its services through a vast array of user-friendly
                  software and APIs. These Terms of Use (the “Terms”), together
                  with any and all specific terms and conditions for each of our
                  products and solutions (“Services”), as amended from time to
                  time, governs your use of the Services.
                  <br />
                  <br />
                  Please read these terms carefully to ensure that you
                  understand each provision. by downloading, registering an
                  account, signing into, accessing, browsing, or otherwise using
                  the platform whether as a guest or registered user, or
                  otherwise accepting these terms, you are signifying that you
                  have read and understood the terms, and agree to be bound by
                  these terms, all guidelines, our privacy policy and any and
                  all future modifications to these terms.
                </p>
              </div>
              {fillteredTermsAndPolicyData.map((value) => {
                return (
                  <div id="modification 3">
                    <h4 className="mt-2">
                      {value.index}. {value.title}
                    </h4>
                    <div>
                      {value.content.map((content) => (
                        <div className="decendant">
                          <p>{content.index}</p>
                          <p>{content.body}</p>
                          <div
                            style={{
                              // paddingLeft: "3\0px",
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <p style={{ margin: "-10px 0px 10px 0px" }}>
                              {content.subindex}
                            </p>
                            <p style={{ margin: "-10px 0px 10px 0px" }}>
                              {content.subbody}
                            </p>
                            <p style={{ margin: "-15px 0px 10px -10px" }}>
                              {content.newsects}
                            </p>
                            <p style={{ margin: "-15px 0px 10px -7px" }}>
                              {content.newsect}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}

              <p className="mt-3">
                <strong>
                  Last updated: This Terms and Conditions was last updated on October 3, 2024
                </strong>
              </p>
            </div>
            {/* <div className="col-md-10 col-lg-9 info-policy"> */}

            {/* </div> */}
          </div>
        </div>
      </section>

      <section className="cant-find-answer">
        <div className="container">
          <div className="col-md-10 col-lg-12 mx-auto py-5">
            <div className="cant-find">
              <h3>Can’t find the answer you are looking for?</h3>
              <div className="">
                <a
                  className="btn btn-sales  me-1"
                  href="https://calendly.com/premblysalesteam/45mins"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LatestF />
      <Footer />
    </div>
  );
}
