
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import './styles.css'
import logo from '../../../assets/partner-assest/prembly-logo (1).svg'
import vector from '../../../assets/partner-assest/Pulley.png'
import calendar from '../../../assets/partner-assest/calendar-2.png'
import percentage from '../../../assets/partner-assest/percentage-circle.png'
import close from '../../../assets/partner-assest/close.svg';

// import { useDispatch } from "react-redux";
// import { registerUserRequest, registerUserSuccess, registerUserFailure } from '../action';



const Header = () =>{ 
//   const dispatch = useDispatch();

//   // State variables to store form data
//   const [firstName, setFirstName] = useState("");
//   const [lastName, setLastName] = useState("");
//   const [email, setEmail] = useState("");
//   const [companyName, setCompanyName] = useState("");
//   const [country, setCountry] = useState("USA"); // Default country

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     dispatch(registerUserRequest());
  
//     const formData = {
//       email: email,
//       first_name: firstName,
//       last_name: lastName,
//       organisation_name: companyName,
//       country: country,
//       referral: "prembly",
//       sector: "finane "
    
//     };
  

//     // email: action.payload.values.email,
//     // first_name: action.payload.values.first_name,
//     // last_name: action.payload.values.last_name,
//     // organisation_name: action.payload.values.organisation_name,
//     // country: action.payload.values.country,
//     // referral: ""
//     // sector: action.payload.values.sector
//     //  partner: action.payload.values.partner

//     fetch('https://api.prembly.com/prembly/api/v1/auth/register/session/initialize', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(formData)
     
//     })
    
//     .then(response => {
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       return response.json();
//     })
//     .then(data => {
//       dispatch(registerUserSuccess(data));
//       console.log(data);
//     })
//     .catch(error => {
//       dispatch(registerUserFailure(error));
//       console.log(error);
 
//     });
//     console.log( formData);
//   };

  return (
    <div className="app">
      <div className="left-section">
        <div className="header-logo">
          <img
            className="logo-icon1"
            loading="lazy"
            alt=""
            src={logo}
            style={{height:"32px"}}
          />
          <img
            className="close-icon"
            loading="lazy"
            alt=""
            style={{height:"24px"}}
            src={close}
          />
          <img
            className="percentagecircle-icon"
            loading="lazy"
            alt=""
            style={{height:"32px"}}
            src={vector}
          />
        </div>
        <h1 className="compliance">
          Compliance and Security <br/>for Emerging Markets
        </h1>
        <h3 className="customers-enjoy">
          Pulley customers enjoy discounted access to our ID
          verification, fraud prevention, background check, form
          building, and global compliance tools.
        </h3>

        <div className="side-by-side">


          <div className="boxss">
            <div className="box"> 
            
                 
              <img
                className="percentage-circle-icon"
                loading="lazy"
                alt=""
                src={percentage}
              />
            </div>
            <div className="off-all-prembly">
              20% off all Prembly products       
            </div>
          </div>
          
          <div className="boxss2">
            <div className="box">      
              <img
                className="percentage-circle-icon"
                loading="lazy"
                alt=""
                src={calendar}
              />
            </div>
            <div className="off-all-prembly">
              Valid for 12 months
            </div>
    
          </div>
        </div>


  </div>
<div className="new">
<a
    className=" submit-btn btn btn-sales2 signin-sales  py-3 me-lg-2 mb-lg-0 mt-4"
                  href="https://forms.prembly.com/view/85eacd2c-1f73-45df-ba38-2b8cb44e3274"
                  target="_blank"
                  rel="noreferrer"
                  id=""
                  style={{fontSize:"18px" }}
    >
       Claim your Discounts
 </a>
</div>
    </div>

  );
  
}


export default Header;