import React, { useState } from "react";
import cookie from "../.././assets/cookie.svg";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { Button, Modal } from "antd";
import { Link, useLocation } from "react-router-dom";



export default function Cookie() {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let setAllCookieConsent = () => {
    Cookies.set("allCookieConsent", "accepted");
    handleCancel();
  };

  let setNecessaryCookieConsent = () => {
    Cookies.set("necessaryCookieConsent", "accepted");
    handleCancel();
  };

  useEffect(() => {
    const cookieConsent =
      Cookies.get("allCookieConsent") ||
      Cookies.get("necessaryCookieConsent") ||
      "";
    cookieConsent === "" ? setIsModalOpen(true) : setIsModalOpen(false);
  }, []);


  return (
    <div className="py-5">
      <div className=" d-flex justify-content-center col-md-12 mx-auto px-5">
        <div className="col-md-5 mx-auto cookie-box d-block">
          <>
                  <Modal
                    open={isModalOpen}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <div className="cookie-box">
                      <img src={cookie} alt="cookie" />
                      <p>
                        By clicking “Accept all cookies”, you agree Prembly can
                        store cookies on your device and disclose information in
                        accordance with our{" "}
                        <Link to="/Policy" className="cookie-link">
                          Cookie Policy
                        </Link>
                        .
                      </p>
                      <div className="cookie-btn">
                        <button
                          className="btn btn-green1"
                          onClick={setAllCookieConsent}
                        >
                          Accept all cookies
                        </button>
                        <button
                          href=""
                          className="btn btn-green2 btn-green-outline"
                          onClick={setNecessaryCookieConsent}
                        >
                          Necessary cookies only
                        </button>
                      </div>
                      <button className="btn btn-greennew btn-green-outline">
                        Customize settings
                      </button>
                    </div>
                  </Modal>
                </>
        </div>
      </div>
    </div>
  );
}
