import React, { useEffect, useState } from "react";
import NavBar2 from "../NavBar";
import Footer from "../Footer";
import { FloatingWhatapp } from "../FloatingWhatapp";
import { Row, Col, Form, Button, Card, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import whatsappIcon from "../../../newassets/whatapp.svg";

const TrackingPage = () => {
  const [formID, setFormID] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [language, setLanguage] = useState("en");
  const [translatedError, setTranslatedError] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhoneNumber = (phone) => {
    const re = /^\d{7,14}$/;
    return re.test(String(phone));
  };

  const translate = (enText, swText) => (language === "en" ? enText : swText);

  const getSwahiliErrorMessage = (enErrorMsg) => {
    const errorTranslations = {
      "Email or phone number is required":
        "Barua pepe au nambari ya simu inahitajika",
      "Enter a valid email address or phone number.":
        "Weka barua pepe au nambari ya simu sahihi.",
      "Something went wrong. Please try again.":
        "Kuna tatizo. Tafadhali jaribu tena.",
    };
    return errorTranslations[enErrorMsg] || enErrorMsg;
  };

  const setErrorMessage = (errorMsg) => {
    setError(errorMsg);
    setTranslatedError(
      language === "en" ? errorMsg : getSwahiliErrorMessage(errorMsg)
    );
  };

  useEffect(() => {
    setTranslatedError(
      language === "en" ? error : getSwahiliErrorMessage(error)
    );
  }, [language, error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus(null);
    setSubmitted(true);
    setError(""); // Clear the previous error message

    if (!formID.trim()) {
      setErrorMessage("Email or phone number is required");
      setLoading(false);
      return;
    } else if (!validateEmail(formID) && !validatePhoneNumber(formID)) {
      setErrorMessage("Enter a valid email address or phone number.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        "https://api.prembly.com/background-check/background/api/v1/bolt_customer_background_check_status",
        {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(
            validateEmail(formID) ? { email: formID } : { phone: formID }
          ),
        }
      );

      if (!response.ok) {
        throw new Error(
          translate(
            "Something went wrong. Please try again.",
            "Kuna tatizo. Tafadhali jaribu tena."
          )
        );
      }

      const data = await response.json();
      if (data.detail && data.detail.length === 0) {
        setStatus(null);
      } else {
        setStatus(data);
      }
      setError(""); // Clear the error message if request is successful
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  };

  const capitalize = (text) => {
    if (!text) return "";
    return text
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const calculateProgressBarValue = () => {
    const completionStatus = parseInt(status?.detail?.completion_status, 10);
    if (isNaN(completionStatus)) {
      return 0;
    }
    if (status?.detail?.paid) {
      return completionStatus;
    }
    return completionStatus;
  };

  const fullName = `${capitalize(status?.detail?.first_name)} ${
    status?.detail?.middle_name ? capitalize(status?.detail?.middle_name) : ""
  } ${capitalize(status?.detail?.last_name)}`;

  const getRequestStatusStyle = () => {
    switch (status?.detail?.request_status) {
      case "ACCEPTED":
        return {
          backgroundColor: "green",
          color: "white",
          padding: "10px",
          borderRadius: "5px",
        };
      case "PENDING":
        return {
          backgroundColor: "orange",
          color: "white",
          padding: "10px",
          borderRadius: "5px",
        };
      default:
        return {};
    }
  };

  return (
    <>
      <div className="">
        <NavBar2 isHomePage={false} isBoltPage={false} />
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center btnee me-3 justify-content-between">
            <FaArrowLeftLong className="me-2" />
            <Link to="/bolt" className="text-decoration-none">
              {translate("Go Back", "Rudi Nyuma")}
            </Link>
          </div>
          <div className="mt-3">
            <Button
              className="green-btn me-3"
              onClick={() => setLanguage(language === "en" ? "sw" : "en")}
            >
              {language === "en"
                ? "Badili hadi Kiswahili"
                : "Switch to English"}
            </Button>
          </div>
        </div>

        <h1 className="d-flex justify-content-center track tw-font-generalSansRegular w-font-[600] tw-text-[2.5rem]">
          {translate(
            "Track Your Bolt Verification Application",
            "Fuata Maombi Yako ya Uthibitisho wa Bolt"
          )}
        </h1>
        <br />
        <Row className="justify-content-center my-4 tw-font-generalSansRegular">
          <Col xs={12} md={5} className="justify-content-center px-4">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formID">
                <Form.Label>
                  {translate(
                    "Tracking Email or Phone Number",
                    "Barua pepe au Nambari ya Simu ya Ufuatiliaji"
                  )}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={translate(
                    "Enter your email address or phone number",
                    "Ingiza anwani yako ya barua pepe au nambari ya simu"
                  )}
                  value={formID}
                  onChange={(e) => setFormID(e.target.value)}
                />
                {submitted && translatedError && (
                  <Form.Text className="text-danger">
                    {translatedError}
                  </Form.Text>
                )}
              </Form.Group>

              <Button
                type="submit"
                className="mt-3 w-100 green-btn"
                disabled={loading}
              >
                {loading
                  ? translate("Submitting...", "Inatuma...")
                  : translate("Submit", "Tuma")}
              </Button>
            </Form>
          </Col>
        </Row>

        {loading && (
          <div className="text-center mt-4">
            <h3>{translate("Loading...", "Inapakia...")}</h3>
          </div>
        )}

        <Row className="justify-content-center tw-font-generalSansRegular w-font-[600]">
          <Col xs={12} md={8} className="px-0">
            {!loading && submitted && status && status.detail ? (
              <div className="mt-4">
                <Card className="mt-4 p-4 shadow">
                  <h5 className="mb-3">
                    {translate("Completion Status", "Hali ya Kukamilika")}
                  </h5>
                  <ProgressBar
                    now={calculateProgressBarValue() || "0%"}
                    label={calculateProgressBarValue() + "%" || "0%"}
                    style={{ height: "30px", fontSize: "1.2rem" }}
                    aria-valuenow="0"
                    variant="success"
                  />
                  <Card.Body className="mt-3 px-0">
                    <Row className="mb-3">
                      <Col xs={12} className="d-flex justify-content-between">
                        <strong>
                          {translate("Request Status", "Hali ya Ombi")}:
                        </strong>
                        <div style={getRequestStatusStyle()}>
                          {capitalize(
                            translate(
                              status.detail.request_status,
                              status.detail.request_status === "ACCEPTED"
                                ? "IMEKUBALIWA"
                                : status.detail.request_status === "PENDING"
                                ? "INASUBIRI"
                                : status.detail.request_status
                            )
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} className="d-flex justify-content-between">
                        <strong>
                          {translate("Payment Status", "Hali ya Malipo")}:
                        </strong>
                        <div>
                          {status.detail.paid
                            ? translate("Paid", "Imelipwa")
                            : translate("Unpaid", "Haijalipwa")}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} className="d-flex justify-content-between">
                        <strong>
                          {translate("Payment Amount", "Kiasi cha Malipo")}:
                        </strong>
                        <div>
                          {status.detail.paid
                            ? `KES ${status.detail.amount_paid}`
                            : translate("N/A", "Haipatikani")}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} className="d-flex justify-content-between">
                        <strong>
                          {translate("Payment Date", "Tarehe ya Malipo")}:
                        </strong>
                        <div>
                          {status.detail.paid
                            ? new Date(
                                status.detail.date_paid
                              ).toLocaleDateString()
                            : translate("N/A", "Haipatikani")}
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            ) : (
              !loading &&
              submitted &&
              !error &&
              !status?.detail && (
                <div className="text-center mt-4 px-2">
                  <h3 className="tracking-text">
                    <span>
                      {translate(
                        "Sorry, this application was not found.",
                        "Samahani, maombi haya hayakupatikana."
                      )}
                      <br /> {translate("Visit", "Tembelea")}{" "}
                      <span
                        style={{
                          color: "#2f9d92",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                      >
                        <a
                          href="https://prembly.com/bolt"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          www.prembly.com/bolt
                        </a>
                      </span>{" "}
                      {translate(
                        "to apply for your bolt check",
                        "kuomba uhakiki wako wa bolt"
                      )}
                      <br />
                    </span>
                  </h3>
                </div>
              )
            )}
          </Col>
        </Row>
      </div>

      <div className="text-center mx-auto py-2 mergerText px-2">
        <h3
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            lineHeight: "30px",
          }}
        >
          <span>
            *{translate("Note:", "Kumbuka:")}{" "}
            {translate(
              "Your application will take between",
              "Maombi yako yatachukua kati ya"
            )}{" "}
            <span
              style={{
                color: "#2f9d92",
              }}
            >
              24 - 48hrs
            </span>{" "}
            {translate("to be completed.", "kukamilika.")} <br />
            <br />
            <span
              className="d-flex justify-content-center p-2 rounded"
              style={{
                background: "#25d366",
              }}
            >
              <a
                href="https://api.whatsapp.com/send?phone=254796111020&text=Hello%2C%20I'm%20from%20the%20Prembly%20website%20and%20I%20have%20an%20enquiry"
                target="_blank"
                className="d-flex justify-content-center align-items-center"
                style={{ color: "black", textDecoration: "none" }}
              >
                {/* {translate("For any complaint", "Kwa malalamiko yoyote")} */}
                {translate(
                  "For any complaint - chat with us on WhatsApp",
                  "Kwa malalamiko yoyote - piga soga nasi kwenye WhatsApp "
                )}{" "}
                <img
                  src={whatsappIcon}
                  alt="WhatsApp"
                  className="whatsapp-icon"
                />
              </a>
            </span>
          </span>
        </h3>
      </div>
      <Footer />
      <FloatingWhatapp />
    </>
  );
};

export default TrackingPage;
