import React, { useRef, useState, useEffect } from "react";
import "./style.css";
import prembly_logo from "../../newassets/Logo (1).svg";
import Kenya from "../../newassets/kenya.svg";
import BGC from "../../newassets/BGC.svg";
import { useMediaQuery } from "react-responsive";
import compliance from "../../newassets/compliance.svg";
import prembly from "../../newassets/Premly logo 4.svg";
import IdentityRadar from "../../newassets/Identityredar.svg";
import IdentityForms from "../../newassets/Identityfom Logo Icon 2.svg";
import IdentityPass from "../../newassets/Identitypass .svg";
import research from "../../newassets/bi_book.svg";
import blog from "../../newassets/jam_write.svg";
import events from "../../newassets/mic.svg";
import Carrer from "../../newassets/carrer.svg";
import { TfiArrowCircleDown } from "react-icons/tfi";
import { FaBars } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const NavBar2 = ({ isHomePage, isBoltPage }) => {
  const [open, setOpen] = useState(false);
  const [openBaner, setOpenBanner] = useState(true);
  const [isFixed, setIsFixed] = useState(false);

  const handleOpen = () => setOpen(!open);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 2000px)",
  });

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleCloseBanner = () => {
    setOpenBanner(false);
  };

  const navBarStyle = `${isHomePage
    ? "tw-top-0 tw-left-0 tw-w-full tw-z-50"
    : "tw-top-0 tw-left-0 tw-w-full tw-z-50 tw-bg-white"
    } ${isFixed ? "tw-fixed tw-bg-white" : "tw-sticky"} ${openBaner ? "tw-pt-[3.5rem] tw-p-[0]" : "tw-pt-[0.2rem]"
    }`;
  const mobileNavbarStyle = `${openBaner ? "tw-mt-[1rem]" : "tw-pt-[0]"}`;
  return (
    <>
      {openBaner && (
        <div className="tw-fixed tw-top-0 tw-z-[100] tw-bg-[#2f9d92] tw-p-[1rem] tw-w-full tw-text-[#FFFFFF] tw-font-[GeneralSans] tw-flex tw-items-center tw-justify-between md:tw-justify-center">
          <div className=" tw-flex tw-items-center tw-text-center md:tw-justify-center">
            {/* //Our Biometric Technology is actively combating the growing threat of deepfakes. Learn More Here."

            Link to: https://blog.prembly.com/the-abc-of-biometrics-dynamism/ */}


            {/* <p className="tw-text-center">
              Our Biometric Technology is actively combating the growing threat of deepfakes. Learn more
              <a
                href="https://blog.prembly.com/the-abc-of-biometrics-dynamism/"
                target="_blank"
                className="tw-ml-[0.5rem] tw-text-[#FFFFFF]"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline" }}
              >
                here
              </a>
            </p> */}
            <p className="tw-text-center">
              We've just updated our Terms & Conditions to ensure that we continue providing you with top-tier, compliant data services. Learn more
              <a
                href="https://prembly.com/terms"
                target="_blank"
                className="tw-ml-[0.5rem] tw-text-[#FFFFFF]"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline" }}
              >
                here
              </a>
            </p>

          </div>
          <div className="tw-ml-[1rem] tw-flex md:tw-absolute md:tw-right-[2.5rem]">
            <IoCloseSharp
              className="tw-cursor-pointer tw-text-[#FFFFFF] "
              onClick={handleCloseBanner}
            />
          </div>
        </div>
      )}

      <div className={navBarStyle}>
        <nav
          className={`tw-px-[5.65rem] tw-pb-[1rem] md:tw-flex md:tw-items-center md:tw-justify-between md:tw-w-[100%] md:tw-mx-auto landing ${!isDesktopOrLaptop ? mobileNavbarStyle : ""
            }`}
        >
          <div
            className={`tw-flex tw-items-center tw-justify-between ${isDesktopOrLaptop ? "tw-mt-[1.5rem]" : "tw-mt-[3.5rem]"
              }`}
          >
            {/* logo */}
            <a href="/">
              {" "}
              <img src={prembly_logo} alt="Logo" />
            </a>

            {/* hamburger */}
            <div className="cursor-pointer md:tw-hidden" onClick={handleOpen}>
              {open ? <IoCloseSharp size={20} /> : <FaBars size={20} />}
            </div>
          </div>

          {/* start of navLinks */}
          {isDesktopOrLaptop ? (
            <div>
              <NavLinks setOpen={setOpen} isBoltPage={isBoltPage} />
            </div>
          ) : (
            <div>
              {open ? (
                <div
                  className="tw-fixed tw-top-0 tw-z-[100] tw-left-0 tw-w-[100%] tw-h-[100%]"
                  style={{ backgroundColor: "rgba(149, 150, 153,.9)" }}
                >
                  <div className="tw-w-[308px] tw-mx-auto tw-mt-[1.5rem]">
                    <NavLinks setOpen={setOpen} isBoltPage={isBoltPage} />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}

          {isDesktopOrLaptop ? (
            <div className="tw-flex tw-items-center tw-gap-[1.5rem] tw-mt-[1.5rem]">
              {isBoltPage ? (
                <a className="green-btn" href="/get-started" target="_blank">
                  Get Started
                </a>
              ) : (
                <>
                  <a
                    className="grey-btn"
                    href="https://outlook.office365.com/owa/calendar/PremblySalesTeam@prembly.com/bookings/s/F0duHde86k66hHrEI0Q71g2"
                    color="grey"
                    target="_blank"
                  >
                    Speak to Sales
                  </a>
                  <a
                    href="https://account.prembly.com/login"
                    className="green-btn"
                    target="_blank"
                  >
                    Sign In
                  </a>
                </>
              )}
            </div>
          ) : (
            ""
          )}
        </nav>

        {/* Sticky line */}
        <div
          className=" md:tw-block tw-sticky tw-top-0 tw-left-0 tw-w-full tw-z-50"
          style={{ borderTop: "1px solid #E3E3E3" }}
        ></div>
      </div>
    </>
  );
};

export default NavBar2;

const NavLink = ({ title, navlinks, currentLinkName, link }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const route = useNavigate();
  const [subLinkisHover, setSubLinkIsHover] = useState("");

  const SubNavlinks = ({ icon, title, body, link, subLinks = [] }) => {
    return (
      <div className="tw-relative ">
        <div
          className="tw-flex tw-gap-[1.25rem] tw-flex-row tw-cursor-pointer tw-transition tw-duration-300 tw-py-[0.5rem] tw-px-[1rem] "
          // className="tw-flex tw-gap-[1.25rem] tw-flex-row tw-cursor-pointer tw-transition tw-duration-300 tw-py-[0.5rem] tw-px-[1rem] hover:tw-bg-[#2f9d92]"
          onClick={() => {
            if (subLinks.length === 0) {
              if (link.includes("https")) {
                window.open(link, "_blank");
              } else {
                route(link);
              }
            }
          }}
        >
          {icon && <img src={icon} alt={title} />}
          <div className="">
            <p className="tw-font-generalSansMedium tw-text-[1.125rem] tw-font-[500] hover:tw-text-[#2f9d92]">
              {title}
            </p>
            <p className="tw-text-[#677489] tw-font-[400] tw-text-[0.875rem]">
              {body}
            </p>
          </div>
        </div>

        {subLinkisHover === title && (
          <div
            style={
              isDesktopOrLaptop
                ? { boxShadow: "0px 4px 4px 0px #00000040" }
                : {}
            }
            className="tw-py-[1rem] md:tw-absolute md:tw-top-[5px] md:tw-translate-x-[360px] md:tw-bg-white md:tw-inline-block  md:tw-rounded-[8px] "
            onMouseLeave={() => setSubLinkIsHover("")}
          >
            {subLinks.map((sub, index) => (
              <a
                href={sub.link}
                key={index}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="tw-flex tw-gap-[1.25rem] tw-flex-row tw-cursor-pointer md:tw-py-[0.9rem] md:tw-px-[1rem]  tw-transition tw-duration-300">
                  {sub.icon && <img src={sub.icon} alt={sub.title} />}
                  <div>
                    <p className="tw-font-generalSansMedium tw-text-[1.125rem] tw-font-[500] hover:tw-text-[#2f9d92]">
                      {sub.title}
                    </p>
                    <p className="tw-text-[#677489] tw-font-[400] tw-text-[0.875rem]">
                      {sub.body}
                    </p>
                  </div>
                </div>
              </a>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="tw-px-[1.313rem] md:tw-px-[0] md:tw-relative " id={title}>
      <div
        className={`tw-text-[#4F4F4F] tw-cursor-pointer tw-py-[0.688rem] tw-flex tw-items-center tw-justify-between md:tw-gap-[0.25rem] ${currentLinkName === title ? "tw-text-primary40" : ""
          }`}
        style={{ marginTop: "20px" }}
        onClick={() => setSubLinkIsHover(subLinkisHover === title ? "" : title)}
      >
        <p className="tw-font-generalSansMedium tw-font-[400] tw-text-[1rem]">
          {title}
        </p>
        <TfiArrowCircleDown size={isDesktopOrLaptop ? 20 : 24} />
      </div>

      {currentLinkName === title && (
        <div
          className="tw-py-[1.25rem] tw-gap-[1.25rem] tw-flex tw-flex-col md:tw-absolute md:tw-w-[354px] md:tw-bg-[white] md:tw-py-[1.25rem]  md:tw-rounded-[20px] md:tw-top-[77px] md:tw-z-[400] "
          style={
            isDesktopOrLaptop ? { boxShadow: "0px 4px 4px 0px #00000040" } : {}
          }
        >
          <div
            className="tw-hidden tw-bg-[white] tw-h-[20px] tw-w-[20px] tw-absolute tw-translate-y-[-30px] tw-translate-x-[55px] md:tw-block "
            style={{ clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)" }}
          ></div>

          {navlinks.map((d, index) => (
            <div
              key={index}
              onMouseOver={() => {
                if (d.subLinks.length !== 0) {
                  setSubLinkIsHover(d.title);
                }
              }}
              onClick={() => {
                if (d.subLinks.length !== 0) {
                  setSubLinkIsHover(d.title);
                }
              }}
            >
              <SubNavlinks {...d} subLinkisHover={subLinkisHover} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const NavLinks = ({ setOpen, isBoltPage }) => {
  const navlinks = [
    {
      title: "Company",
      navlinks: [
        { icon: prembly, title: "About", body: "", link: "/about" },
        {
          icon: prembly,
          title: "Our Story",
          body: "",
          link: "/story",
        },
      ],
    },
    {
      // title: "For Businesses",
      title: "Products",
      navlinks: [
        {
          // icon: IdentityPass,
          title: "For Businesses",
          body: "Comprehensive compliance and verification solutions.",
          link: "https://idpass.prembly.com",
          subLinks: [
            {
              icon: IdentityPass,
              title: "IdentityPass",
              body: "Verify and Onboard customers with our robust features for KYC, KYB",
              link: "https://idpass.prembly.com",
            },
            {
              icon: IdentityRadar,
              title: "IdentityRadar",
              body: "Fraud prevention and financial transaction monitoring",
              link: "https://identityradar.prembly.com/",
            },
            {
              icon: IdentityForms,
              title: "IdentityForms",
              body: "Simplify data collection and verification with our intuitive No-Code form builder",
              link: "https://identityforms.prembly.com",
            },
            {
              icon: BGC,
              title: "Background Checks",
              body: "Conduct thorough background check for seamless onboarding",
              link: "https://backgroundcheck.prembly.com/",
            },
            {
              icon: compliance,
              title: "Compliance Tracker",
              body: "Stay up to date with certificates and regulations to run your bussinesses",
              link: "https://compliance.prembly.com",
            },
          ],
        },
        {
          // icon: IdentityPass,
          title: "For Individual",
          body: "Personal identity and event management tools.",

          subLinks: [
            {
              title: "NISES",
              body: <p className="tw-w-[150px]">Coming Soon</p>,
              link: "#",
            },

            {
              title: "Evento",
              body: "Coming Soon",
              link: "#",
            },
          ],
        },
      ],
    },

    {
      title: "Resources",
      navlinks: [
        {
          icon: blog,
          title: "Blog",
          body: "Stay updated with the latest industry trends and success stories",
          link: "https://blog.prembly.com/",
        },
        {
          icon: research,
          title: "Research",
          body: "Stay informed with the latest developments and trends in our research field.",
          link: "https://blog.prembly.com/category/publications/",
        },
        {
          icon: Carrer,
          title: "Careers",
          body: "Explore exciting career opportunities with us.",
          link: "/career",
        },
        {
          icon: events,
          title: "Events",
          body: "Get Updated on the latest news happening in Prembly",
          link: "/event",
        },
      ],
    },
  ];

  if (isBoltPage) {
    navlinks.push({
      title: "Industry use Cases",
      navlinks: [
        {
          icon: Kenya,
          title: "Bolt Service 1",
          body: "Details about Bolt Service 1",
          link: "/bolt-service-1",
        },
        {
          icon: Kenya,
          title: "Bolt Service 2",
          body: "Details about Bolt Service 2",
          link: "/bolt-service-2",
        },
      ],
    });
  }

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 700px)",
  });
  const [currentLink, setCurrentLink] = useState("");
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setCurrentLink(""); // Close dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      className="tw-w-[100%] tw-py-[2.25rem] tw-px-[0.625rem] tw-bg-white tw-rounded-[8px] md:tw-rounded-[unset] md:tw-py-[0] md:tw-px-[0]"
      style={
        isDesktopOrLaptop
          ? { backgroundColor: "transparent" }
          : { boxShadow: "0px 4px 4px 0px #00000040" }
      }
    >
      <IoCloseSharp
        className="tw-ml-[auto] tw-mr-[1.25rem] tw-my-[1.25rem] md:tw-hidden"
        onClick={() => setOpen(false)}
        size={25}
      />
      <div className="md:tw-flex md:tw-gap-[2rem]">
        {navlinks.map((link, index) => (
          <div
            key={index}
            onClick={() => {
              if (currentLink === link.title) {
                setCurrentLink("");
              } else {
                setCurrentLink(link.title);
              }
            }}
          >
            <NavLink
              {...link}
              currentLinkName={currentLink}
              isBoltPage={isBoltPage}
            />
          </div>
        ))}
      </div>
      {/* Buttons for mobile view */}
      {!isDesktopOrLaptop && (
        <div className="tw-flex tw-flex-col tw-gap-[0.5rem] tw-mt-[1.25rem]">
          {isBoltPage ? (
            <a className="green-btn" href="/get-started" target="_blank">
              Get Started
            </a>
          ) : (
            <>
              <a
                className="grey-btn"
                href="https://outlook.office365.com/owa/calendar/PremblySalesTeam@prembly.com/bookings/s/F0duHde86k66hHrEI0Q71g2"
                color="grey"
                target="_blank"
              >
                Speak to Sales
              </a>
              <a
                href="https://account.prembly.com/login"
                className="green-btn"
                target="_blank"
              >
                Sign In
              </a>
            </>
          )}
        </div>
      )}
    </div>
  );
};
