import React, { useState, useEffect } from "react";
import NavbarArea from "../../components/navbar";
// import Footer from "../../components/footer";
import Footer from "../../components/newNavbar/Footer";
import { nanoid } from "nanoid";
import GetStarted from "../allProduct/GetStarted";
import BackgroundDiff from "../allProduct/BackgroundDiff";
import Feedback from "../allProduct/Feedback";
// import teams from "../../assets/teams.svg";
import { dataSub } from "./resourceData";
import NewsComp from "../../../src/pages/Resources/NewsComp";
import PictureComp from "../../../src/pages/Resources/PictureComp";
import LogosComp from "./LogosComp";
import axios from "axios";
import NavBar2 from "../../components/newNavbar/NavBar";

export default function Press(props) {
  const [showNews, setShowNews] = useState(true);
  const [showPictures, setShowPictures] = useState(false);
  const [showLogos, setShowLogos] = useState(false);
 

  return (
    <div>
      <NavBar2/>
      
      <section className="press pb-5 pt-3">
        <div className="containerPress ">
          {/* <div className="col-lg-12 mx-auto"> */}
          <div className="containerPress-bg"></div>

          <div className=" text-center col-md-11 col-lg-8 mx-auto">
            <h1 className="mb-3">Press and Media Kit.</h1>
            <p>
              Find out all about us that is in the news and get access to all
              media you need from us
            </p>
          </div>

          <div className="pressMediaContainer">
            <div className="navigateBox">
              <div className="">
                  <button
                    onClick={() => {
                        setShowNews(true)
                        setShowPictures(false)
                        setShowLogos(false)
                    }}
                    className={`btnSwitch ${showNews ? "active" : ""}`}
                  >
                    News
                  </button>
              </div>
              {/* <div className="">
              <button
                onClick={() => {
                    setShowNews(false)
                    setShowPictures(true)
                    setShowLogos(false)
                }}
                className={`btnSwitch ${showPictures ? "active" : ""}`}
              >
                Pictures
              </button>
              </div> */}
              <div className="">
              <button
                onClick={() => {
                    setShowNews(false)
                    setShowPictures(false)
                    setShowLogos(true)
                }}
                className={`btnSwitch ${showLogos ? "active" : ""}`}
              >
                Logos
              </button>
              </div>
            </div>
            <div className="allPressContainer row justify-content-center  align-items-center ">
            {showNews && <NewsComp />}
            {/* {showPictures && <PictureComp />} */}
            {showLogos && <LogosComp />}
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
      <Footer/>
    </div>
  );
}
