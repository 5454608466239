import React, { useState, useEffect, useTransition } from "react";
// import meets from "../../assets/meets.svg";

import meets from "../../assets/meets.svg";
import { Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
// // import { useTranslation } from "react-i18next";
// import achieve from "../../assets/achievements.svg"
import complianceIcon from "../../assets/compliance-icon.png";

export default function NavbarArea(props) {
  const [productOptions, setProductOptions] = useState(false);
  const [resourceOptions, setResourceOptions] = useState(false);
  const [solutionOptions, setSolutionOptions] = useState(false);
  const [companyOptions, setCompanyOptions] = useState(false);
  const [countryFlag, setCountryFlag] = useState(false);
  const [developersOptions, setDevelopersOptions] = useState(false);

  // const [flags, setFlags] = useState([
  //   {
  //     id: 1,
  //     flagimg: "https://prembly-assets.s3.us-east-2.amazonaws.com/GB.svg",
  //     flag: "English",
  //     decode: "en",
  //   },

  //   {
  //     id: 2,
  //     flagimg: "https://prembly-assets.s3.us-east-2.amazonaws.com/FR.svg",
  //     flag: "France",
  //     decode: "fr",
  //   },

  //   {
  //     id: 3,
  //     flagimg: "https://prembly-assets.s3.us-east-2.amazonaws.com/ES.svg",
  //     flag: "Spanish",
  //     decode: "sp",
  //   },
  // ]);

  let location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  let closeProductOption = () => {
    setProductOptions(!productOptions);
    setResourceOptions(false);
    setSolutionOptions(false);
    setCompanyOptions(false);
    setCountryFlag(false);
  };

  let closeResourceOption = () => {
    setResourceOptions(!resourceOptions);
    setProductOptions(false);
    setSolutionOptions(false);
    setCompanyOptions(false);
    setCountryFlag(false);
    setDevelopersOptions(false);
  };

  let closeSolutionOption = () => {
    setSolutionOptions(!solutionOptions);
    setProductOptions(false);
    setResourceOptions(false);
    setCompanyOptions(false);
    setCountryFlag(false);
    setDevelopersOptions(false);
  };

  let closeCompanyOption = () => {
    setCompanyOptions(!companyOptions);
    setSolutionOptions(false);
    setProductOptions(false);
    setResourceOptions(false);
    setCountryFlag(false);
    setDevelopersOptions(false);
  };

  let closeCountyFlagOption = () => {
    setCountryFlag(!countryFlag);
    setCompanyOptions(false);
    setSolutionOptions(false);
    setProductOptions(false);
    setResourceOptions(false);
    setDevelopersOptions(false);
    // console.log("click me");
  };

  let closeDevelopersOption = () => {
    setDevelopersOptions(!developersOptions);
    setResourceOptions(false);
    setProductOptions(false);
    setSolutionOptions(false);
    setCompanyOptions(false);
    setCountryFlag(false);
  };

  useEffect(() => {
    document.querySelector(".app").addEventListener(
      "onclick",
      (event) => {
        setProductOptions(false);
        setResourceOptions(false);
        setSolutionOptions(false);
        setCompanyOptions(false);
        setCountryFlag(false);
        setDevelopersOptions(false);
      }
      // true
    );
  }, []);

  return (
    <>
      <div className="navbar-area fixed-top">
        <Navbar className="navbar-light" expand="lg">
          <div className="container ">
            <Navbar.Brand href="/">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/prembly-logo.svg"
                alt=""
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="navbar-nav py-2 mb-2 mb-lg-0 ps-lg-5 align-items-lg-center mx-auto">
                <a className="link">
                  {/* href="#about" */}
                  <li className="nav-item" onClick={closeProductOption}>
                    <p className="nav-link">Products</p>
                    <i class="ri-arrow-down-s-line" />

                    {productOptions && (
                      <div className="navbar-options products-options">
                        <div className="col">
                          <div className="col-md-6 d-flex align-items-start products">
                            <Link
                              to="/identityPass"
                              onClick={closeProductOption}
                              className="link d-flex align-items-start"
                            >
                              <div className="positionidpass">
                                <div className="imagetagborder">
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/myPass.svg"
                                    alt=""
                                  />
                                </div>
                                <span>
                                  <h6>IdentityPass</h6>
                                  <p>
                                    Onboard verified customers in seconds,
                                    reduce friction and fraud by 100%.
                                  </p>
                                </span>
                              </div>
                            </Link>
                          </div>

                          <div className="col-md-6 d-flex align-items-start products ">
                            <a
                              href="https://identityradar.prembly.com/"
                              onClick={closeProductOption}
                              className="link d-flex align-items-start "
                            >
                              <div className="positionidpass">
                                <div className="imagetagborder">
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/search.svg"
                                    alt=""
                                  />
                                </div>
                                <span>
                                  <h6>IdentityRadar</h6>
                                  <p>
                                    Stop financial crime and optimize fraud
                                    protection with swift accuracy.
                                  </p>
                                </span>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6 d-flex align-items-start products">
                            {/* <Link
                              to="/identityForm"
                              onClick={closeProductOption}
                              className="link d-flex align-items-start"
                            > */}
                            <a
                              href="https://identityforms.prembly.com/"
                              onClick={closeProductOption}
                              className="link d-flex align-items-start"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="positionidpass">
                                <div className="imagetagborder">
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/newform_logo.png"
                                    alt=""
                                  />
                                </div>

                                <span>
                                  <h6>IdentityForm</h6>
                                  <p>
                                    IdentityForms helps business create the most
                                    secure data collection forms
                                  </p>
                                </span>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6 d-flex align-items-start products">
                            <Link
                              to="/backgroundCheck"
                              onClick={closeProductOption}
                              className="link d-flex align-items-start "
                            >
                              <div className="positionidpass">
                                <div className="imagetagborder">
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/form.png"
                                    alt=""
                                  />
                                </div>
                                <span>
                                  <h6>Background Check</h6>
                                  <p>
                                    Conduct thorough background checks using
                                    data from over 50 digital sources.
                                  </p>
                                </span>
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-6 d-flex align-items-start products">
                            <a
                              href="https://compliance.prembly.com"
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={closeProductOption}
                              className="link d-flex align-items-start "
                            >
                              <div className="positionidpass">
                                <div className="imagetagborder">
                                  <img src={complianceIcon} alt="" />
                                </div>
                                <span>
                                  <h6>Compliance Tracker</h6>
                                  <p>
                                    Stay up to date with certificates and
                                    regulations to run your bussinesses.
                                  </p>
                                </span>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                </a>

                <a className="link">
                  <li className="nav-item" onClick={closeSolutionOption}>
                    <p className="nav-link">Industries</p>
                    <i class="ri-arrow-down-s-line" />

                    {solutionOptions && (
                      <div className="navbar-options products-option">
                        <div className="col">
                          <div className="d-flex industry-nav">
                            <div className="col-md-6 d-flex align-items-start productSolution ">
                              <Link
                                to="/sector"
                                onClick={closeSolutionOption}
                                className="link d-flex align-items-start"
                              >
                                <div className="positionidpass">
                                  {/* <div className="imagetagborder"> */}
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/finan.svg"
                                    alt=""
                                  />
                                  {/* </div> */}
                                  <span>
                                    <p>Financial Sector</p>
                                  </span>
                                </div>
                              </Link>
                            </div>

                            <div className="col-md-6 d-flex align-items-start productSolution">
                              <Link
                                to="/tobacco"
                                onClick={closeSolutionOption}
                                className="link d-flex align-items-start"
                              >
                                <div className="positionidpass">
                                  {/* <div className="imagetagborder"> */}
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/tobacco.svg"
                                    alt="tobacco"
                                  />
                                  {/* </div> */}
                                  <span>
                                    <p>Alcohol & Tobacco</p>
                                  </span>
                                </div>
                              </Link>
                            </div>
                          </div>

                          <div className="d-flex industry-nav">
                            <div className="col-md-6 d-flex align-items-start productSolution ">
                              <Link
                                to="/crypto"
                                onClick={closeSolutionOption}
                                className="link d-flex align-items-start"
                              >
                                <div className="positionidpass">
                                  {/* <div className="imagetagborder"> */}
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/crypo.svg"
                                    alt=""
                                  />
                                  {/* </div> */}
                                  <span>
                                    <p>Cryptocurrency</p>
                                  </span>
                                </div>
                              </Link>
                            </div>

                            <div className="col-md-6 d-flex align-items-start productSolution">
                              <Link
                                to="/publicSector"
                                onClick={closeSolutionOption}
                                className="link d-flex align-items-start"
                              >
                                <div className="positionidpass">
                                  {/* <div className="imagetagborder"> */}
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/publicSector.svg"
                                    alt="tobacco"
                                  />
                                  {/* </div> */}
                                  <span>
                                    <p>Public Sector</p>
                                  </span>
                                </div>
                              </Link>
                            </div>
                          </div>

                          <div className="d-flex industry-nav">
                            <div className="col-md-6 d-flex align-items-start productSolution ">
                              <Link
                                to="/management"
                                onClick={closeSolutionOption}
                                className="link d-flex align-items-start"
                              >
                                <div className="positionidpass">
                                  {/* <div className="imagetagborder"> */}
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/hrs.svg"
                                    alt=""
                                  />
                                  {/* </div> */}
                                  <span>
                                    <p>HR Management</p>
                                  </span>
                                </div>
                              </Link>
                            </div>

                            <div className="col-md-6 d-flex align-items-start productSolution">
                              <Link
                                to="/insurance"
                                onClick={closeSolutionOption}
                                className="link d-flex align-items-start"
                              >
                                <div className="positionidpass">
                                  {/* <div className="imagetagborder"> */}
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/insurance.svg
                                  "
                                    alt="tobacco"
                                  />
                                  {/* </div> */}
                                  <span>
                                    <p>Insurance</p>
                                  </span>
                                </div>
                              </Link>
                            </div>
                          </div>

                          <div className="col-md-6 d-flex align-items-start productSolution ">
                            <Link
                              to="/mobility"
                              onClick={closeSolutionOption}
                              className="link d-flex align-items-start"
                            >
                              <div className="positionidpass">
                                {/* <div className="imagetagborder"> */}
                                <img
                                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/mobility.svg"
                                  alt=""
                                />
                                {/* </div> */}
                                <span>
                                  <p>Mobility</p>
                                </span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                </a>

                <a className="link">
                  <li className="nav-item" onClick={closeResourceOption}>
                    <p className="nav-link">Resources</p>
                    <i class="ri-arrow-down-s-line" />

                    {resourceOptions && (
                      <div className="navbar-options products-options ">
                        <div className="col">
                          <div className="col-md-6 d-flex align-items-start products">
                            <a
                              href="https://blog.prembly.com/"
                              target="_blank"
                              onClick={closeResourceOption}
                              className="link d-flex align-items-start"
                            >
                              <div className="positionidpass">
                                <div className="">
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/blog.svg"
                                    alt=""
                                  />
                                </div>
                                <span>
                                  <h6 style={{ lineHeight: "5px" }}>Blog</h6>
                                  <p style={{ lineHeight: "15px" }}>
                                    Prepare to be immersed in a world of
                                    limitless compliance and security knowledge.
                                  </p>
                                </span>
                              </div>
                            </a>
                          </div>

                          <div className="col-md-6 d-flex align-items-start products">
                            <Link
                              to="/press"
                              onClick={closeResourceOption}
                              className="link d-flex align-items-start"
                            >
                              <div className="positionidpass">
                                <div className="">
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/mic.svg"
                                    alt=""
                                  />
                                </div>
                                <span>
                                  <h6 style={{ lineHeight: "5px" }}>
                                    Press & Media
                                  </h6>
                                  <p style={{ lineHeight: "15px" }}>
                                    Keep up with the buzz and discover the
                                    latest news and happenings from Prembly.
                                  </p>
                                </span>
                              </div>
                            </Link>
                          </div>

                          <div className="col-md-6 d-flex align-items-start products">
                            <Link
                              to="/faqs"
                              onClick={closeResourceOption}
                              className="link d-flex align-items-start"
                            >
                              <div className="positionidpass">
                                <div className="">
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/chats.svg"
                                    alt=""
                                  />
                                </div>
                                <span>
                                  <h6 style={{ lineHeight: "5px" }}>
                                    Frequently Asked Questions
                                  </h6>
                                  <p style={{ lineHeight: "15px" }}>
                                    Get the scoop on everything you need about
                                    our products and services.
                                  </p>
                                </span>
                              </div>
                            </Link>
                          </div>

                          <div className="col-md-6 d-flex align-items-start products">
                            <Link
                              to="/event"
                              onClick={closeResourceOption}
                              className="link d-flex align-items-start"
                            >
                              <div className="positionidpass">
                                <div className="">
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/mic.svg"
                                    alt=""
                                  />
                                </div>
                                <span>
                                  <h6 style={{ lineHeight: "5px" }}>Events</h6>
                                  <p style={{ lineHeight: "15px" }}>
                                    Keep up with the buzz and discover the
                                    latest news and happenings from Prembly.
                                  </p>
                                </span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                </a>

                <a className="link">
                  <li className="nav-item" onClick={closeCompanyOption}>
                    <p className="nav-link">Company</p>
                    <i class="ri-arrow-down-s-line" />
                    {companyOptions && (
                      <div className="navbar-options products-options">
                        <div className="col">
                          <div className="col-md-6 d-flex align-items-start products">
                            <Link
                              to="/about"
                              onClick={closeResourceOption}
                              className="link d-flex align-items-start"
                            >
                              <div className="positionidpass">
                                <div className="">
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/about.svg"
                                    alt=""
                                  />
                                </div>
                                <span>
                                  <h6 style={{ lineHeight: "5px" }}>
                                    About Us
                                  </h6>
                                  <p style={{ lineHeight: "15px" }}>
                                    Discover our company's history, mission, and
                                    vision, and see why we're passionate about
                                    what we do!
                                  </p>
                                </span>
                              </div>
                            </Link>
                          </div>

                          <div className="col-md-6 d-flex align-items-start products">
                            <Link
                              to="/career"
                              onClick={closeResourceOption}
                              className="link d-flex align-items-start"
                            >
                              <div className="positionidpass">
                                <div className="">
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/climb.svg"
                                    alt=""
                                  />
                                </div>
                                <span>
                                  <h6 style={{ lineHeight: "5px" }}>Career</h6>
                                  <p style={{ lineHeight: "15px" }}>
                                    Ready to join the team? Explore our job
                                    opportunities and start your journey to
                                    success.
                                  </p>
                                </span>
                              </div>
                            </Link>
                          </div>

                          <div className="col-md-6 d-flex align-items-start products">
                            <Link
                              to="/contact"
                              onClick={closeResourceOption}
                              className="link d-flex align-items-start"
                            >
                              <div className="positionidpass">
                                <div className="">
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/call.svg"
                                    alt=""
                                  />
                                </div>
                                <span>
                                  <h6 style={{ lineHeight: "5px" }}>
                                    Contact Us
                                  </h6>
                                  <p style={{ lineHeight: "15px" }}>
                                    Got a question or comment? Speak to any of
                                    our experts- we're always happy to help!
                                  </p>
                                </span>
                              </div>
                            </Link>
                          </div>

                          {/* <div className="col-md-6 d-flex align-items-start products">
                            <Link
                              to="/meet"
                              onClick={closeResourceOption}
                              className="link d-flex align-items-start"
                            >
                              <div className="positionidpass">
                                <div className="">
                                  <img src={meets} alt="" />
                                </div>
                                <span>
                                  <h6 style={{ lineHeight: "5px" }}>
                                    Meet The Team
                                  </h6>
                                  <p style={{ lineHeight: "15px" }}>
                                    Ready to join the team? Explore our job
                                    opportunities and start your journey to
                                    success.
                                  </p>
                                </span>
                              </div>
                            </Link>
                          </div> */}

                          {/* <div className="col-md-6 d-flex align-items-start products">
                            <Link
                              to="/compliance"
                              onClick={closeResourceOption}
                              className="link d-flex align-items-start"
                            >
                              <div className="positionidpass">
                                <div className="">
                                <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/about.svg"
                                    alt=""
                                  />
                                </div>
                                <span>
                                  <h6 style={{ lineHeight: "5px" }}>
                                     Compliance Certificates
                                  </h6>
                                  <p style={{ lineHeight: "15px" }}>
                                  We are globally compliant. Check all our certifications.
                                  </p>
                                </span>
                              </div>
                            </Link>
                          </div> */}

                          <div className="col-md-6 d-flex align-items-start products">
                            <Link
                              to="/partnership"
                              onClick={closeResourceOption}
                              className="link d-flex align-items-start"
                            >
                              <div className="positionidpass">
                                <div className="">
                                  <img
                                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/climb.svg"
                                    alt=""
                                  />
                                </div>
                                <span>
                                  <h6 style={{ lineHeight: "5px" }}>
                                    Become a Partner
                                  </h6>
                                  <p style={{ lineHeight: "15px" }}>
                                    Explore partnership options at prembly and
                                    collaborate with us to drive innovations
                                  </p>
                                </span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                </a>

                <a className="link" href="#dev">
                  <li className="nav-item" onClick={closeDevelopersOption}>
                    <p className="nav-link">Developers</p>
                    <i class="ri-arrow-down-s-line" />
                    {developersOptions && (
                      <div className="navbar-options products-optiones">
                        <div className="col">
                          <div className="col-md-6 d-flex align-items-start products">
                            <div className="positionidpass">
                              <a
                                className=""
                                style={{
                                  textDecoration: "none",
                                  color: "#000000",
                                }}
                                href="https://prembly.org/"
                                target="_blank"
                                alt=""
                              >
                                <h6>Community</h6>
                              </a>
                            </div>
                          </div>
                          <div className="col-md-6 d-flex align-items-start products">
                            <div className="positionidpass">
                              <a
                                style={{
                                  textDecoration: "none",
                                  color: "#000000",
                                }}
                                href="https://docs.prembly.com/"
                                target="_blank"
                              >
                                <h6>Documentation</h6>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                </a>
              </Nav>

              <Nav
                className={`navbar-nav py-2 mb-2 mb-lg-0 align-items-lg-center ms-auto colors ${
                  props.colouredButton ? props.colouredButton : ""
                }`}
              >
                <a
                  className="btn btn-sales1 signin-sales  py-3 me-lg-2 mb-lg-0"
                  href="https://account.prembly.com/login"
                  target="_blank"
                  rel="noreferrer"
                  id=""
                >
                  Sign In
                </a>
                <a
                  className="btn btn-sales2 signin-sales  py-3 me-lg-2 mb-lg-0"
                  href="https://calendly.com/premblysalesteam/45mins"
                  target="_blank"
                  rel="noreferrer"
                  id=""
                >
                  Request Demo
                </a>

                {/* <div className="d-flex align-items-center gapfornav">
                  <a className="link">
                    <li
                      className="nav-item navCountry"
                      onClick={closeCountyFlagOption}
                    >
                      <div>
                        <img src={flags[selectedFlag]?.flagimg} alt="flag" />
                      </div>

                      <i class="ri-arrow-down-s-line" />

                      {countryFlag ? (
                        <div className="navbar-options products-optionss">
                          <div className="col">
                            {flags.map((country, index) => (
                              <div
                                className="products px-3 py-1"
                                key={country.id}
                                onClick={() =>
                                  setSelectedFlag(index)
                                }
                              >
                                <div
                                  className=" d-flex positionidpasss"
                                  onClick={() => changeDefaultFlag(index, country.decode)}
                                >
                                  <div>
                                    <img src={country.flagimg} alt="" />
                                  </div>
                                  <span>
                                    <h6>{country.flag} </h6>
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </li>
                  </a>
                </div> */}
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
        {/* <div className="container" style={{position:"relative"}}> </div> */}
      </div>
    </>
  );
}
