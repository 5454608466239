import React, { useState } from "react";
import Footer from "../../../components/footer";
import NavbarArea from "../../../components/navbar";
import LatestF from "../../allProduct/LatestF";
import { Modal } from "react-bootstrap";
import { identitys } from "../../../components/datas";
import RadarCard from "../../allProduct/RadarCard";
import { identityT } from "../../../components/datas";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import allImg from "../../../assets/allLogo.png";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "none" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "green",
        borderRadius: "30px",
        width: "50px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={onClick}
    />
  );
}

export default function RadarPage() {
  const [show, setShow] = useState(false);
  const [productTab, setProductTab] = useState(1);
  const [solutionTab, setSolutionTab] = useState(1);
  const [arrowDirection, setArrowDirection] = useState(1);

  const carouselBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 5 },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const arrowAngleRight = () => {
    setArrowDirection(2);
  };

  const arrowAngleLeft = () => {
    setArrowDirection(1);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div style={{ background: "#ffffff" }}>
       <Helmet>
        <title>Prembly- IdentityRadar</title>
        <meta
          name="description"
          content="Advanced Fraud Prevention Solution in Africa"
        />
      </Helmet>
      <>
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Identityradar </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              src="https://drive.google.com/file/d/1nYSigQg5XyKFRhd7Cfvw36imEA9RYCsj/preview"
              title="Radar-Explainer"
              className="w-100"
              height="520"
              allow="autoplay"
              allowFullScreen="allowFullScreen"
            ></iframe>
          </Modal.Body>
          {/* <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Save Changes
              </Button>
            </Modal.Footer> */}
        </Modal>
      </>

      <NavbarArea colouredButton="pinkNav" />
      <div className="whiteBg"></div>
      <section className=" radar-hero-area">
        <div className="container py-5">
          <div className="row align-items-center mx-auto justify-content-between graphHeader">
            <div className="col-md-12 col-lg-6 py-5 idcore">
              <div className="d-flex align-items-center radar-card">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/radar.png"
                  alt=""
                />
                <a
                  className="btn btn-radar px-md-2 px-4 py-2 mt-0 "
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  Identityradar
                </a>
              </div>
              <h1 className="mb-4 mt-4">
                The all-in-one AI-Powered Comprehensive Fraud Detection &
                Prevention Solution
              </h1>
              <p>
                By leveraging the flexibility of integrating our APIs into your
                system, you can quickly determine the risks connected with
                specific individuals right from onboarding.
              </p>

              <div className="d-flex align-items-center">
                <a
                  className="btn btn-pink  me-1"
                  href="https://account.prembly.com/signUp"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get Started
                </a>
                {/* <a
                  className="btn btn-pinks px-md-4 py-3"
                  href="https://calendly.com/premblysalesteam/45mins"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>Talk to Sales</span>
                  <i class="ri-arrow-right-line ms-2"></i>
                </a> */}

                <Link to="/contact" className="btn btn-pinks px-md-4 py-3 mt-3">
                  <span>Talk to Sales</span>
                  <i class="ri-arrow-right-line ms-2"></i>
                </Link>
              </div>
            </div>

            <div className="col-md-12 col-lg-5 ">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/videos/idRadar.gif"
                alt="landing"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        id="partners"
        className="loved-by-section pt-5  text-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="">
          <div>
            <h4>We are trusted by all top financial institutions</h4>

            <div className="marquee">
              <div className="marquee-content">
                <ul>
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>

                <ul>
                  {" "}
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>
                <ul>
                  {" "}
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*--------------how radar helps bussiness---------------*/}

      <section id="" className="how-can-premblyhelp-area">
        <div className="col-lg-12 mx-auto">
          <div className="container d-flex justify-content-center">
            <div className="col-md-8 col-lg-10 quickhelps py-3 ">
              <p>
                The AI-powered AML solution provides a more integrated insight
                into the day-to-day customer transaction processes, analyzing
                mis-match and tracking fraudulent activity in real-time, whilst
                offering Willa library of trusted data sources to run identity
                record checks, watchlist checks, and ongoing monitoring for
                digital businesses to navigate KYC and AML requirements at
                scale, helping to detect and flag down fraudulent activities. 
              </p>

              <h1 className="mb-4 radar-business ">
                How Identityradar helps businesses
              </h1>
            </div>
          </div>

          <div className=" row align-items-center verifyallapplicant  seperate-contents  justify-content-center">
            <div className="col-md-12 col-lg-5 verifyapplicantstodays">
              <h1>Seamless Onboarding </h1>
              <p className="mt-4 ">
                Onboarding customers can be time-consuming and complicated, but
                it doesn't have to be. With Identityradar, you can make the
                experience convenient for your customers and deploy custom
                intelligence tools tailored to your specific security needs.   
              </p>
            </div>

            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants" id="magic-bg">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/identity-radar-herosection/seamless-onboarding.jpg"
                  alt="Customers Onboarding"
                />
              </div>
            </div>
          </div>

          <div
            className="row align-items-center verifyallapplicant justify-content-center"
            id="columforbg"
          >
            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants" id="magic-bg">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/identity-radar-herosection/transaction-monitoringn.jpg"
                  alt="Fraud Prevention Solution"
                />
              </div>
            </div>

            <div className="col-md-10 col-lg-5 verifyapplicantstodays">
              <h1>Transaction Monitoring </h1>
              <p className="mt-4 ">
                Protecting your business and customers against fraud is critical
                in today's digital landscape. By integrating Identityradar’s
                tools to detect and flag unusual transactions, you can
                proactively address potential fraudulent activity and minimize
                the risk of financial loss. Identityradar helps to ensure the
                security of your platform and the stability of your business,
                building trust and confidence in your brand. 
              </p>
            </div>
          </div>

          <div className=" row align-items-center verifyallapplicant seperate-contents justify-content-center">
            <div className="col-md-12 col-lg-5 verifyapplicantstodays">
              <h1>Watch List </h1>
              <p className="mt-4 ">
                Identityradar's AML tool makes it easy to comply with AML
                regulations by providing seamless integration options such as
                SDKs or API, allowing you to run watchlists, sanctions lists,
                and PEP lists record checks with ease and efficiency.
              </p>
            </div>

            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants" id="magic-bg">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/identity-radar-herosection/watchlist.jpg"
                  alt="Identity Security Solution"
                />
              </div>
            </div>
          </div>

          <div
            className=" row align-items-center justify-content-center  verifyallapplicant"
            id="columforbg"
          >
            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants" id="magic-bg">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/identity-radar-herosection/fraud-detection.jpg"
                  alt="Fraud Detection Solution"
                />
              </div>
            </div>
            <div className="col-md-10 col-lg-5 verifyapplicantstodays">
              <h1>Fraud Detection </h1>
              <p className="mt-4 ">
                Identityradar's powerful fraud detection system leverages
                advanced AI algorithms and integrate with a wide range of data
                sources to provide a comprehensive and effective solution for
                detecting and preventing fraud. With its innovative technology
                and real-time monitoring capabilities, Identityradar gives you
                the peace of mind that comes with knowing your business is
                protected against fraudulent activity. 
              </p>
            </div>
          </div>

          <div className=" row align-items-center verifyallapplicant seperate-contents justify-content-center ">
            <div className="col-md-12 col-lg-5  verifyapplicantstodays">
              <h1>AML/CFT & KYC Compliance </h1>
              <p className="mt-4">
                Using Identityradar's tools for identity record checks,
                watchlist checks, and monitoring, you can easily navigate
                complex KYC (know your customer) and AML (anti-money laundering)
                requirements. This helps ensure that your business complies with
                relevant regulations and reduces the risk of financial loss from
                fraudulent activity. 
              </p>
            </div>

            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants" id="magic-bg">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/identity-radar-herosection/AML.jpg"
                  alt="AML & KYC Compliance"
                />
              </div>
            </div>
          </div>

          <div
            className=" row align-items-center justify-content-center  verifyallapplicant"
            id="columforbg"
          >
            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants" id="magic-bg">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/identity-radar-herosection/TRUST-SCORE.jpg"
                  alt="Employee BackgroundCheks Solution"
                />
              </div>
            </div>
            <div className="col-md-10 col-lg-5 verifyapplicantstodays">
              <h1>Transparent Trust Score </h1>
              <p className="mt-4 ">
                Access comprehensive background information on any applicant
                with just one click. Make informed hiring decisions with our
                in-depth background reports.
              </p>
            </div>
          </div>

          <div className=" row align-items-center justify-content-center  verifyallapplicant seperate-contents">
            <div className="col-md-12 col-lg-5 verifyapplicantstodays">
              <h1>PEP and FEP watchlists </h1>
              <p className="mt-4">
                Get instant results with our fast and accurate applicant
                verification process. Verify employment history, education, and
                more in seconds
              </p>
            </div>

            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants" id="magic-bg">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/identity-radar-herosection/pep.jpg"
                  alt="Security Documents"
                />
              </div>
            </div>
          </div>

          <div
            className=" row align-items-center justify-content-center  verifyallapplicant"
            id="columforbg"
          >
            <div className="col-md-12 col-lg-5 verify-applicant">
              <div className="verify-applicants" id="magic-bg">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/identity-radar-herosection/risk-management.jpg"
                  alt="KYC Solution"
                />
              </div>
            </div>
            <div className="col-md-10 col-lg-5 verifyapplicantstodays">
              <h1>Risk Management Dashboard </h1>
              <p className="mt-4 ">
                Get an overview of potential threats and risks to a company's
                security from fraudsters. Track and monitor security incidents,
                assess risk scores, and take necessary actions to mitigate those
                risks.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*--------------swift---------------*/}
      <section className="swift pinkswift py-5">
        <div className="container py-5">
          <div className="col-lg-12 mx-auto">
            <div className="row align-items-center justify-content-between swiftcontents">
              <div className="col-md-12 col-lg-6 swift-text">
                <h2 className="mb-4">
                  Swift Fraud Prevention with machine learning rules.
                </h2>
                <p>
                  Leverage our machine data rules and insights to combat cyber
                  security threats. Discover suspicious activity using
                  behavioral risk scores for added security using data around
                  your users.
                </p>

                {/* <button className="btn btn-green1 px-4 py-3" type="button">
                  Check Pricing
                </button> */}
              </div>
              <div className="col-md-12 col-lg-5 ">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/images/rightframe.svg"
                  className="w-100 emailimg"
                  alt="AI-Powered Security Solution"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="countries-section py-5">
        <div className="container">
          <div className="col-lg-12 mx-auto">
            <div className="row align-items-center mx-auto justify-content-center countries-gap">
              <div className="col-md-12 col-lg-6 countries-list ">
                <div className="globalpink mb-4">
                  <p>Global</p>
                </div>
                <h1>Our solution cut across 100+ countries</h1>
                <h5 className="mt-3 mb-4">
                  Prembly's extensive range of over 100 APIs can be utilised by
                  businesses worldwide to enhance their identity verification
                  and authentication processes. Our dedicated teams, located
                  globally, are ready to assist you and your customers wherever
                  your operations may be
                </h5>

                <div className="countriesFlag">
                  <img
                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/countries.svg"
                    alt="Global compliance and security infrastructure."
                  />
                </div>

                <div className="d-flex align-items-center">
                  <Link
                    to="/about"
                    className="btn btn-pink  mt-4 me-1"
                    rel="noreferrer"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
              <div className="col-md-12 col-lg-5">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/world-new.gif"
                  alt="Verify customers and prevent fraud across emerging markets"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="feedback" className="feedback-prembly-area">
        <div className="container">
          <div className="col-md-12 col-lg-11 mx-auto py-lg-5 text-center">
            <h1>Our Customers Perspective</h1>
            {arrowDirection === 1 && (
              <div className=" d-flex align-items-center py-5 ">
                {/* <img src={rewind1} alt="" className="me-2"/> */}
                {/* <div className=" showflex"> */}
                <div className="showflex row ">
                  {identitys.map((data) => (
                    <div className="col-md-12 col-lg-4">
                      {/* <Slider {...settings}> */}

                      <RadarCard
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />

                      {/* </Slider> */}
                    </div>
                  ))}
                </div>
                <img
                  onClick={arrowAngleRight}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindright.svg"
                  alt=""
                  className="ms-2"
                />
              </div>
            )}

            {arrowDirection === 2 && (
              <div className=" d-flex align-items-center py-5 ">
                <img
                  onClick={arrowAngleLeft}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindleft.svg"
                  alt=""
                  className="me-2"
                />
                {/* <div className=" showflex"> */}
                <div className="showflex row ">
                  {identityT.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      {/* <Slider {...settings}> */}

                      <RadarCard
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />

                      {/* </Slider> */}
                    </div>
                  ))}
                </div>
                {/* <img src={rewindright} alt="" className="ms-2"/> */}
              </div>
            )}
          </div>
        </div>
      </section>

      <LatestF updateBg="pinkbg" />
      <Footer />
    </div>
  );
}
