import React, { useState, useEffect } from "react";
import { identitys } from "../../../../components/datas";
// import Footer from "../../../../components/footer";
import Footer from "../../../../components/newNavbar/Footer";
import { Link } from "react-router-dom";
import LatestF from "../../../allProduct/LatestF";
import Cards from "../../../allProduct/Cards";
import Cookies from "js-cookie";
import allImg from "../../../../assets/allLogo.png";
import { identityT } from "../../../../components/datas";
import { ArrowForwardIos } from "@mui/icons-material";
import { Helmet } from "react-helmet";
// external
import ExternalNavbarArea from "../../../../components/External/Navbar";
import Forms from '../../../../components/External/sproutly'
import Slider from "react-slick";
import NavBar2 from "../../../../components/newNavbar/NavBar";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);


  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "none" }}
      onClick={onClick}
    >
      hr
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "green",
        borderRadius: "30px",
        width: "50px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={onClick}
    />
  );
}

export default function HomePage() {
  const [productTab, setProductTab] = useState(1);
  const [solutionTab, setSolutionTab] = useState(1);
  const [arrowDirection, setArrowDirection] = useState(1);

  const carouselBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 5 },
  ];

  const arrowAngleRight = () => {
    setArrowDirection(2);
  };

  const arrowAngleLeft = () => {
    setArrowDirection(1);
  };

  const NextBtn = (props) => {
    const { onClick } = props;
    return (
      <ArrowForwardIos
        style={{
          color: "black",
          fontSize: "15px",
          position: "absolute",
          top: "0px",
          right: "15px",
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextBtn />,
  };
  return (
    <div style={{ background: "#ffffff" }}>
      <Helmet>
        <title>Prembly - A Data Infrastructure Company.</title>
        <meta
          name="description"
          content="Building security, compliance, and verification tools to make the internet a safer place for everyone."
        />
      </Helmet>

   
      <NavBar2 />
      <div className="whiteBg"></div>


            
                <Forms/>
            
            
            

 

      {/* <section
        id="partners"
        className="loved-by-section pt-5  text-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
         <div className="">
          <div>
            <h4>We are trusted by all top financial institutions</h4>

            <div className="marquee">
              <div className="marquee-content">
                <ul>
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>

                <ul>
                  {" "}
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>
                <ul>
                  {" "}
                  <li>
                    <img src={allImg} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> 
      </section> */}

      <section id="animation" className="animation-area py-5 ">
        <div className="container">
          <div className="col-md-9 mx-auto py-5 video-area">
            <video
              className="w-100"
              controls
              poster="https://prembly-assets.s3.us-east-2.amazonaws.com/landpage-video.mp4"
            >
              <source
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/landpage-video.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </section>

      <section id="premblyEcosystem" className="prembly-ecosystem-area py-5">
        <div className="container">
          <div className="text-center col-md-11  mx-auto">
            <h1>
              We help thousands businesses verify all their users with our wide
              array of tools
            </h1>
            <p className="mt-3">
              With monthly identity verification rates in millions, 100s of
              digital businesses across emerging markets are being Powered to
              Greatness by Prembly’s vast array of AI-powered software,
              including leading financial institutions, Crypto, logistics &
              mobility, FMCGs, Gaming, Insurance, Alcohol & Tobacco, and Public
              sectors. 
            </p>
          </div>

          <div className="row col-md-12 align-items-center  justify-content-center py-5 prembly-gap">
            <div className="col-md-10  newprembly-row col-lg-6">
              <div className="row pt-6 new-prembly-content">
                <div className="d-flex align-items-center">
                  <img
                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/myPassNew.svg"
                    alt="KYC/KYB verification solutions"
                  />
                  <a
                    className="btn btn-purple px-md-2 px-4 py-2 mt-0 "
                    href=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    Identitypass
                  </a>
                </div>
                <div className="eco-headings">
                  <h1 className="mt-3">
                    Safely acquire & seamlessly onboard customers anywhere &
                    anytime, within seconds! 
                  </h1>
                  <p className="first-text mt-3">
                    Identitypass enables safe and seamless identity verification
                    that aids swift customer onboarding.
                  </p>
                  <p className="first-text">
                    With any government issued identifier, digital businesses
                    can now onboard more customers, hence expand their
                    businesses beyond their territorial boundaries; transacting
                    across borders with trust & without restriction, whilst
                    being compliant!
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <Link to="/identitypass">
                    <a
                      className="btn btn-green1 px-md-5 px-5  mt-4 me-1"
                      href="http://account.prembly.com/signUp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn More
                    </a>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-10 col-lg-5 pt-4 id-passimgs">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/homepage2/idpass-homepage.png"
                alt="Instant customers onboarding solution"
                className="w-100"
              />
            </div>
          </div>

          <div
            className="row col-md-12 align-items-center justify-content-center py-5 prembly-gap"
            id="columforbg"
          >
            <div className="col-md-10 col-lg-5 pt-4">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/homepage2/idradar-homepage.png"
                alt="Fraud Prevention Solution"
                className="w-100"
              />
            </div>

            <div className="col-md-10  newprembly-row col-lg-6">
              <div className="row pt-6 new-prembly-content">
                <div className="d-flex align-items-center radar-card">
                  <img
                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/radar.png"
                    alt="IdentityRadar-logo"
                  />
                  <a
                    className="btn btn-radar px-md-2 px-4 py-2 mt-0 "
                    href=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    Identityradar
                  </a>
                </div>
                <div className="eco-headings">
                  <h1 className="mt-3">
                    The all-in-one AI-Powered AML/CFT solution
                  </h1>
                  <p className="first-text mt-2">
                    Identityradar is a fraud intelligence solution designed for
                    banks, financial institutions, cryptocurrency companies,
                    regulators, and law enforcement agencies that utilizes
                    cutting-edge analytics, including Machine Learning and
                    Artificial Intelligence. 
                  </p>
                </div>

                <div className="d-flex align-items-center">
                  <Link to="/IdentityRadar">
                    <a
                      className="btn btn-green1 px-md-5 px-5  mt-4 me-1"
                      href="http://account.prembly.com/signUp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn More
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row col-md-12 align-items-center  justify-content-center py-5 prembly-gap">
            <div className="col-md-10  newprembly-row col-lg-6">
              <div className="row pt-6 new-prembly-content">
                <div className="d-flex align-items-center">
                  <img
                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/form.png"
                    alt="BackgroundChecks-logo"
                  />
                  <a
                    className="btn btn-purple px-md-2 px-4 py-2 mt-0 "
                    href=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    Background Check
                  </a>
                </div>
                <h1 className="firstheading mt-3">
                  Screen Farther, from Anywhere & Anytime! 
                </h1>
                <p className="first-text mt-2">
                  Background Checks by Prembly, the AI-powered tool built for
                  global enterprises gets as far as you want it to; accurately
                  and swiftly delivering individual’s background verification &
                  screening information in record time 
                </p>

                <div className="d-flex align-items-center">
                  <Link to="/backgroundCheck">
                    <a className="btn btn-green1 px-md-5 px-5  mt-4 me-1">
                      Learn More
                    </a>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-10 col-lg-5 pt-4">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/homepage2/backgrounnd-check-homepage.png"
                alt="BackgroundChecks Solution"
                className="w-100"
              />
            </div>
          </div>

          <div className="row col-md-12 align-items-center  justify-content-center py-5 prembly-gap">
            <div className="col-md-10 col-lg-5 pt-4">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/id-form.png"
                alt="Instant Identity verification forms"
                className="w-100"
              />
            </div>
            <div className="col-md-10  newprembly-row col-lg-6">
              <div className="row pt-6 new-prembly-content">
                <div className="d-flex align-items-center">
                  <img
                    src="https://prembly-assets.s3.us-east-2.amazonaws.com/newform_logo.png"
                    alt="IdentityForms-logo"
                    className="homelogosid"
                  />
                  <a
                    className="btn btn-lights px-md-2 px-4 py-2 mt-0 "
                    href=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    IdentityForm
                  </a>
                </div>
                <h1 className="firstheading mt-3">
                  Create the most secure data collection forms
                </h1>
                <p className="first-text mt-2">
                  A drag and drop form builder, built for global business and
                  designed to swiftly and accurately verify data.
                </p>

                <div className="d-flex align-items-center">
                  <Link to="/identityForm">
                    <a className="btn btn-green1 px-md-5 px-5  mt-4 me-1">
                      Learn More
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="countries-section py-5">
        <div className="container">
          <div className="row align-items-center mx-auto justify-content-center countries-gap">
            <div className="col-md-10 col-lg-6 countries-list ">
              <div className="global-color mb-4">
                <p>Global</p>
              </div>
              <h1>Our solution cut across 100+ countries</h1>
              <h5 className="mt-3 mb-4">
                Prembly's extensive range of over 100 APIs can be utilised by
                businesses worldwide to enhance their identity verification and
                authentication processes. Our dedicated teams, located globally,
                are ready to assist you and your customers wherever your
                operations may be
              </h5>
              <div className="countriesFlag">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/countries.svg"
                  alt="Global compliance and security infrastructure."
                />
              </div>

              <div className="d-flex align-items-center">
                <Link
                  to="/about"
                  className="btn btn-green1  mt-4 me-1"
                  rel="noreferrer"
                >
                  Learn More
                </Link>
              </div>
            </div>
            <div className="col-md-10 col-lg-5">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/world-new.gif"
                alt="Verify customers and prevent fraud across emerging markets"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="feedback" className="feedback-prembly-area">
        <div className="container">
          <div className="col-md-12 col-lg-11 mx-auto py-lg-5 text-center">
            <h1>Our Customers Perspective</h1>
            {arrowDirection === 1 && (
              <div className=" d-flex align-items-center py-5 ">
                <div className="showflex row ">
                  {identitys.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      <Cards
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />
                    </div>
                  ))}
                </div>
                <img
                  onClick={arrowAngleRight}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindright.svg"
                  alt=""
                  className="ms-2"
                />
              </div>
            )}

            {arrowDirection === 2 && (
              <div className=" d-flex align-items-center py-5 ">
                <img
                  onClick={arrowAngleLeft}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindleft.svg"
                  alt=""
                  className="me-2"
                />
                {/* <div className=" showflex"> */}
                <div className="showflex row ">
                  {identityT.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      {/* <Slider {...settings}> */}

                      <Cards
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />

                      {/* </Slider> */}
                    </div>
                  ))}
                </div>
                {/* <img src={rewindright} alt="" className="ms-2"/> */}
              </div>
            )}
          </div>
        </div>
      </section>

      <LatestF />
      <Footer />
    </div>
  );
}
