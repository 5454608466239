import React, { useEffect, useState } from "react";
import axios from "axios";
import premblyc from "../../assets/premblyc.svg"

export default function LogosComp() {
  //

  //   useEffect(() => {
  //     axios
  //       .get("https://cms-backend.myidentitypass.com/resource/logo/collections")
  //       .then((res) => {
  //         console.log(res);
  //         setLogosData(res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }, []);

  return (
    <div>
      <div className="logo">
        <div className="row justify-content-center align-items-center logosContianer mb-3">
          <div className="col d-flex justify-content-center align-items-center mb-3">
            <div className="logoCard d-flex justify-content-center align-items-center">
              <div className="logo-body">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/idsvg.svg"
                  alt=""
                />
                <div className="icon-download">
                  <p>Identitypass Logo</p>
                  <div>
                    
                  </div>
                  <a
                    href="https://prembly-assets.s3.us-east-2.amazonaws.com/idsvg.svg"
                    download
                    className="link"
                  >
                    <i class="ri-download-2-line"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col d-flex justify-content-center align-items-center mb-3">
            <div className="logoCard d-flex justify-content-center align-items-center">
              <div className="logo-body">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/idlogosvg.svg"
                  alt=""
                />
                <div className="icon-download">
                  <p>Identitypass Logo</p>
                  <a
                    href="https://prembly-assets.s3.us-east-2.amazonaws.com/idlogosvg.svg"
                    download
                    className="link"
                  >
                    <i class="ri-download-2-line"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col d-flex justify-content-center align-items-center mb-3">
            <div className="logoCard d-flex justify-content-center align-items-center">
              <div className="logo-body ">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/radartagsvg.svg"
                  alt=""
                  download
                />
                <div className="icon-download">
                  <p>IdentityRadar Logo</p>
                  <a
                    href="https://prembly-assets.s3.us-east-2.amazonaws.com/radartagsvg.svg"
                    download
                    className="link"
                  >
                    <i class="ri-download-2-line sm-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col d-flex justify-content-center align-items-center mb-3">
            <div className="logoCard d-flex justify-content-center align-items-center">
              <div className="logo-body">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/radarsvg.svg"
                  alt=""
                />
                <div className="icon-download">
                  <p>IdentityRadar Logo</p>
                  <a
                    href="https://prembly-assets.s3.us-east-2.amazonaws.com/radarsvg.svg"
                    download
                    className="link"
                  >
                    <i class="ri-download-2-line sm-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row logosContianer mb-5">
          <div className="col d-flex justify-content-center align-items-center mb-3">
            <div className="logoCard d-flex justify-content-center align-items-center">
              <div className="logo-body">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/formtag.png"
                  alt=""
                />
                <div className="icon-download">
                  <p> Background Checks Logo</p>

                  <a
                    href="https://prembly-assets.s3.us-east-2.amazonaws.com/formtag.png"
                    download
                    className="link"
                  >
                    <i class="ri-download-2-line"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col d-flex justify-content-center align-items-center mb-3">
            <div className="logoCard d-flex justify-content-center align-items-center">
              <div className="logo-body">
                <img
                  src={premblyc}
                  alt=""
                />
                <div className="icon-download align-items-center">
                  <p>Prembly Community Logo</p>
                  {/* <i class="ri-download-2-line"></i> */}

                  <a
                    href="https://prembly-assets.s3.us-east-2.amazonaws.com/formtag.svg"
                    download
                    className="link"
                  >
                    <i class="ri-download-2-line"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col d-flex justify-content-center align-items-center mb-3">
            <div className="logoCard d-flex justify-content-center align-items-center">
              <div className="logo-body">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/premblysvg.svg"
                  alt=""
                />
                <div className="icon-download">
                  <p>Prembly Logo</p>
                  <a
                    href="https://prembly-assets.s3.us-east-2.amazonaws.com/premblysvg.svg"
                    download
                    className="link"
                  >
                    <i class="ri-download-2-line sm-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col d-flex justify-content-center align-items-center mb-3">
            <div className="logoCard d-flex justify-content-center align-items-center">
              <div className="logo-body">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/premblytag.svg"
                  alt=""
                />
                <div className="icon-download">
                  <p>Prembly Logo</p>
                  <a
                    href="https://prembly-assets.s3.us-east-2.amazonaws.com/premblytag.svg"
                    download
                    className="link"
                  >
                    <i class="ri-download-2-line sm-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
