import React from "react";
import NavbarArea from "../../components/navbar";
import NavBar2 from "../../components/newNavbar/NavBar";
// import Footer from '../../components/footer'
import Footer from "../../components/newNavbar/Footer";
import partnerIconOne from "../../assets/partner-icon-1.png";
import partnerIconTwo from "../../assets/partner-icon-2.png";
import partnerIconThree from "../../assets/partner-icon-3.png";
import partnerIconFour from "../../assets/partner-icon-4.png";
import axios from "axios";
// import partnershipLogos from '../../assets/partnership-logos.png'
import PartnershipFAQ from "./faqFolder/partnership";
import partnerLogoOne from "../../assets/google-workspace-logo.svg";
import partnerLogoTwo from "../../assets/stripe-atlas-logo.png";
import partnerLogoThree from "../../assets/deel-logo.svg";
import partnerLogoFour from "../../assets/zendesk-loo.svg";
import partnerLogoFive from "../../assets/parity-logo.svg";
import partnerLogoSix from "../../assets/osia-logo.png";

export default function PartnershipPage() {
  // function doPost(e) {
  //     // Access submitted form values
  //     const sheetName = "Sheet1"; // Replace with your actual sheet name
  //     const response = JSON.parse(e.postData.contents);
  //     const values = response.namedValues;

  //     // Extract question and answer values
  //     const full_name = values.full_name;
  //     const company_name = values.company_name;
  //     const email = values.email;
  //     const partner_type = values.partner_type;
  //     const region = values.region;
  //     // ... (Extract more questions and answers if needed)

  //     // Access the Google Sheet
  //     const sheet = SpreadsheetApp?.openById("1CBgzmgI0tk9QyAKCLtdCth5sW3M9PEoLn64f4mKIzZo").getSheetByName(sheetName);

  //     // Get the last row number (assuming data starts from row 2)
  //     const lastRow = sheet.getLastRow() + 1;

  //     // Write form values to the sheet
  //     sheet.getRange(lastRow, 1).setValue(full_name);
  //     sheet.getRange(lastRow, 2).setValue(company_name);
  //     sheet.getRange(lastRow, 3).setValue(email);
  //     sheet.getRange(lastRow, 4).setValue(partner_type);
  //     sheet.getRange(lastRow, 5).setValue(region);
  //     // ... (Write more values for additional questions)
  // }

  let dataSubmission = () => {
    let data = JSON.stringify({
      full_name: "tes",
      company_name: "comp",
      email: "msail",
      partner_type: "part",
      region: "reg",
    });

    // let baseUrl = 'https://script.google.com/macros/s/AKfycbwzaOKyzoWsjmQHdO3bBZ9wIFipF8aTKHuTnQ0wcuoLADk7ZrJBu1G43GlgFew0b4dF/exec'

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      // url: 'https://script.google.com/macros/s/AKfycbwzaOKyzoWsjmQHdO3bBZ9wIFipF8aTKHuTnQ0wcuoLADk7ZrJBu1G43GlgFew0b4dF/exec',
      url: "https://api.prembly.com/prembly/api/v1/account/partner/form",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <div>
      {/* <NavbarArea /> */}
      <NavBar2 />
      <div className="bg-light">
        <section className="partnership-hero-area">
          <div className="container-fluid text-center">
            <div className="col-lg-10 mx-auto partnership-hero-card">
              <div className="col-md-10 col-lg-8 mx-auto">
                <h1>Partner with Prembly to Power your greatness</h1>
                <br />
                <p>
                  Unlock Business Growth and Visibility: Discover Our Exclusive
                  Partnership Programs and Benefits
                  {/* Explore our partnership Programs and enjoy special benefits and opportunities designed
                                    for your business growth and visibility. */}
                </p>
                <br />
                <a
                  href="https://forms.prembly.com/view/a2f1c9ed-b0dc-4a41-ba55-46175e2af80e"
                  className="btn btn-green2"
                  target="_blank"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
        <section>
          <div className="container pb-5 mt-3">
            <div className="col-lg-9 mx-auto">
              <div className="row">
                <div className="col-md-9 mx-auto text-center">
                  <h2 style={{ fontSize: "30px" }}>
                    About the Prembly Partner Program
                  </h2>
                  <br />
                  <br />
                  <p>
                    Unleash Growth with Exclusive Perks, Advance through
                    Reseller and Referral Opportunities, Leverage Cutting-Edge
                    Technology Integration, and Expand Your Reach with our Data
                    Partnership. A tailored pathway to elevate your business.
                  </p>
                  <br />

                  <a
                    href="https://forms.prembly.com/view/a2f1c9ed-b0dc-4a41-ba55-46175e2af80e"
                    className="btn btn-green2"
                    target="_blank"
                  >
                    Partner with Prembly
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-9 mx-auto mt-5 pt-5">
              <div className="row">
                <div className="col-md-6 mx-auto text-center">
                  <h2 style={{ fontSize: "30px" }}>Explore Our Partnerships</h2>
                  <br />
                  <p>
                    {" "}
                    Our Collaborations Embrace a Range of Areas, Including:{" "}
                  </p>
                </div>
              </div>
            </div>
            <br />
            <div className="col mx-auto">
              <div className="row justify-content-lg-around">
                <div className="col-md-6 col-lg-2 mt-4 partners-explore-area">
                  <div>
                    <span className="mb-2">
                      <img
                        src={partnerIconThree}
                        alt=""
                        width="40px"
                        className="mb-3"
                      />
                    </span>
                    <h5>
                      Reseller <br /> Partnership
                    </h5>
                    <p>
                      Join as a reseller partner in our distribution network and
                      earn commissions on your customer transactions.
                    </p>
                    <a
                      href="https://forms.prembly.com/view/a2f1c9ed-b0dc-4a41-ba55-46175e2af80e"
                      className="btn p-0 d-flex align-items-center"
                      target="_blank"
                    >
                      Get Started{" "}
                      <i className="ri-arrow-right-circle-line ri-xl ms-2" />
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-lg-2 mt-4 partners-explore-area">
                  <div>
                    <span className="mb-2">
                      <img
                        src={partnerIconThree}
                        alt=""
                        width="40px"
                        className="mb-3"
                      />
                    </span>
                    <h5>
                      Referral <br /> Partnership
                    </h5>
                    <p>
                      Turn your referrals into rewards. Simply refer clients to
                      us and earn a commission for every successful deal.
                    </p>
                    <a
                      href="https://forms.prembly.com/view/a2f1c9ed-b0dc-4a41-ba55-46175e2af80e"
                      className="btn p-0 d-flex align-items-center"
                      target="_blank"
                    >
                      Get Started{" "}
                      <i className="ri-arrow-right-circle-line ri-xl ms-2" />
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-lg-2 mt-4 partners-explore-area">
                  <div>
                    <span className="mb-2">
                      <img
                        src={partnerIconFour}
                        alt=""
                        width="40px"
                        className="mb-3"
                      />
                    </span>
                    <h5>
                      Perks and Discounts <br /> Partnership
                    </h5>
                    <p>
                      Provide exclusive offers to our customers, enhancing your
                      visibility and strengthening your brand within our
                      community.
                    </p>
                    <a
                      href="https://forms.prembly.com/view/a2f1c9ed-b0dc-4a41-ba55-46175e2af80e"
                      className="btn p-0 d-flex align-items-center"
                      target="_blank"
                    >
                      Get Started{" "}
                      <i className="ri-arrow-right-circle-line ri-xl ms-2" />
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-lg-2 mt-4 partners-explore-area">
                  <div>
                    <span className="mb-2">
                      <img
                        src={partnerIconOne}
                        alt=""
                        width="40px"
                        className="mb-3"
                      />
                    </span>
                    <h5>
                      Data <br /> Partnership
                    </h5>
                    <p>
                      Partner with us to provide data endpoints, enhancing both
                      our data insights and your business reach.
                    </p>
                    <a
                      href="https://forms.prembly.com/view/a2f1c9ed-b0dc-4a41-ba55-46175e2af80e"
                      className="btn p-0 d-flex align-items-center"
                      target="_blank"
                    >
                      Get Started{" "}
                      <i className="ri-arrow-right-circle-line ri-xl ms-2" />
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-lg-2 mt-4 partners-explore-area">
                  <div>
                    <span className="mb-2">
                      <img
                        src={partnerIconTwo}
                        alt=""
                        width="40px"
                        className="mb-3"
                      />
                    </span>
                    <h5>
                      Technology <br /> Partnership
                    </h5>
                    <p>
                      Integrate our advanced verification technology through
                      custom APIs, boosting your solutions and client value.
                    </p>
                    <a
                      href="https://forms.prembly.com/view/a2f1c9ed-b0dc-4a41-ba55-46175e2af80e"
                      className="btn p-0 d-flex align-items-center"
                      target="_blank"
                    >
                      Get Started{" "}
                      <i className="ri-arrow-right-circle-line ri-xl ms-2" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="partnership-partner-area py-4"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="container">
            <div className="text-center">
              <h2 style={{ fontSize: "30px" }}>Our Partners Include</h2>
              <div className="col-lg-9 mx-auto py-5">
                <div className="row align-items-center">
                  <div className="col-4 col-md-2 col-lg-2 mt-3">
                    <img src={partnerLogoThree} alt="" className="w-50" />
                  </div>
                  <div className="col-4 col-md-2 col-lg-2 mt-3">
                    <img src={partnerLogoFour} alt="" className="w-75" />
                  </div>
                  <div className="col-4 col-md-2 col-lg-2 mt-3">
                    <img src={partnerLogoOne} alt="" className="w-100" />
                  </div>
                  <div className="col-4 col-md-2 col-lg-2 mt-3">
                    <img src={partnerLogoFive} alt="" className="w-75" />
                  </div>
                  <div className="col-4 col-md-2 col-lg-2 mt-3">
                    <img src={partnerLogoSix} alt="" className="w-50" />
                  </div>
                  <div className="col-4 col-md-2 col-lg-2 mt-3">
                    <img src={partnerLogoTwo} alt="" className="w-100" />
                  </div>
                </div>
                {/* <img src={partnershipLogos} alt="" className='w-100' /> */}
              </div>
            </div>

            <div className="col-lg-10 mx-auto">
              <div className="row">
                <div className="col-md-8 col-lg-6  text-center mx-auto py-5">
                  <h1>Become a Prembly Partner</h1>
                  <br />
                  <p>
                    Click here to join the partner program. We will get back to
                    you in a day or 2
                    {/* Fill out and submit the form to register for our Partners program. We will get
                                        back to you in a couple of days */}
                  </p>
                  {/* <a href="mailto:team@mprembly.com" className="btn becomeateam " onClick={dataSubmission}>
                                        Join Partner Program
                                    </a> */}
                  <br />
                  <a
                    href="https://forms.prembly.com/view/a2f1c9ed-b0dc-4a41-ba55-46175e2af80e"
                    className="btn becomeateam"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Join Partner Program
                  </a>
                </div>
                {/* <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5>Prembly Partners Form</h5>
                                            <div className='mt-5'>
                                                <div>
                                                    <p className="p-0 mb-1 ms-1">Full Name</p>
                                                    <input
                                                        type="text"
                                                        placeholder="Full name"
                                                        className="mb-3 form-control rounded-4 py-3"
                                                    />
                                                </div>
                                                <div>
                                                    <p className="p-0 mb-1 ms-1">Company Name</p>
                                                    <input
                                                        type="text"
                                                        placeholder="Company name"
                                                        className="mb-3 form-control rounded-4 py-3"
                                                    />
                                                </div>
                                                <div>
                                                    <p className="p-0 mb-1 ms-1">Email</p>
                                                    <input
                                                        type="email"
                                                        placeholder="Email"
                                                        className="mb-3 form-control rounded-4 py-3"
                                                    />
                                                </div>
                                                <div>
                                                    <p className="p-0 mb-1 ms-1">Partner Type</p>
                                                    <select className='form-select mb-3 rounded-4 py-3'>
                                                        <option value="">Choose partner type</option>
                                                        <option value="Data Partner">Data Partner</option>
                                                        <option value="Technology Partner">Technology Partner</option>
                                                        <option value="Reseller Partner">Reseller Partner</option>
                                                        <option value="Community Partner">Community Partner</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <p className="p-0 mb-1 ms-1">Region</p>
                                                    <select className='form-select mb-3 rounded-4 py-3'>
                                                        <option value="">Choose Region</option>
                                                        <option value="EMEA - Europe, the Middle East and Africa">EMEA - Europe, the Middle East and Africa</option>
                                                        <option value="NA - North America">NA - North America</option>
                                                        <option value="LATAM - Latin America">LATAM - Latin America</option>
                                                        <option value="APAC - Asia-Pacific">APAC - Asia-Pacific</option>
                                                    </select>
                                                </div>

                                                <button className="btn becomeateam w-100 " onClick={dataSubmission}>
                                                    Join Partner Program
                                                </button>

                                            </div>

                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </section>

        <section className="partnership-join-area">
          <div className="container-fluid text-center">
            <div className="col-md-10 col-lg-8 mx-auto">
              <h1>Let us answer your questions</h1>
              <PartnershipFAQ />
            </div>
            <div className="col-lg-10 mx-auto partnership-join-card">
              <div className="col-md-8 mx-auto">
                <h5>SUBSCRIBE TO OUR MAILING LIST</h5>
                <h1>Join our mailing list to get more exclusives from us!</h1>
                <br />
                <p>
                  Enter your email to get insights into the compliance space and
                  what you need to do keep your company ahead
                </p>
                <br />
                <div className="col-md-9 mx-auto">
                  <form
                    action="https://myidentitypass.us19.list-manage.com/subscribe/post"
                    method="POST"
                    // onSubmit={handleSubmit}
                  >
                    <input
                      type="hidden"
                      name="u"
                      value="1954fa3f95658d99efa44cd11"
                    />
                    <input type="hidden" name="id" value="15f26c0f7f" />
                    <div className="d-flex" id="mergeRow-0">
                      <div className="input-group me-3">
                        <span
                          className="input-group-text rounded-start-4 px-1"
                          style={{ background: "#fff" }}
                          id="basic-addon1"
                        >
                          <i class="ri-mail-line icon" />
                        </span>
                        <input
                          type="email"
                          className="form-control border-start-0 rounded-end-4"
                          style={{ minHeight: "55px" }}
                          placeholder="Your Email"
                          name="MERGE0"
                          id="MERGE0"
                        />
                      </div>
                      <button
                        className=" email-btn rounded-4"
                        style={{ minWidth: "150px" }}
                        type="submit"
                        target="_blank"
                      >
                        Join Prembly
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}
