import Button from "./Button";
// import logogcta from '../assests/Logo (1).svg'
import logogcta from '../../newassets/Logo (1).svg'


const CtaPan= ()=>{

    return (
        <div className="tw-px-[1.25rem] tw-py-[2.5rem] tw-bg-secondary40 md:tw-bg-[#F4FBFA]">
        <div className="tw-max-w-[728px] tw-mx-auto">
         {/* <a href="/"> <img src={logogcta} className="tw-block tw-mx-[auto]" alt="" /></a> */}
<br/>
         <a href="/"> 
          <img src={logogcta} className="tw-block tw-mx-[auto]" alt="Logo" />
         
         </a>



          <h2 className="tw-pt-[1.5rem] tw-font-epi tw-font-[590] tw-text-[2rem] tw-leading-[40px] tw-text-center">
            Let us create a better future together
          </h2>
          <p className="tw-text-center tw-font-generalSansRegular tw-font-[600] tw-text-[#1D1D1D] tw-text-[1.125rem] tw-pb-[1.5rem] tw-pt-[0.5rem] md:tw-w-[539px] tw-mx-auto">
            Together, we can create a world where everyone has access to secure and reliable digital identities, transforming the way we live, work, and interact.
          </p>
          <a href="https://prembly.com/contact" target="_blank" className="tw-w-[100%] md:tw-w-[195px] tw-block md:tw-mx-auto green-btn tw-text-center tw-py-2">
  Contact Us
</a>

        </div>
      </div>
      
    )
}

export default CtaPan;