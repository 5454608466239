import { useState } from "react";
// import arrow from '../assests/about/arrow-circle-down.svg'
import arrow from '../../newassets/about/arrow-circle-down.svg'
import { useMediaQuery } from 'react-responsive';

// type Prop = React.PropsWithChildren<{
// name:string,
// title:string,
// content:string,
// open:boolean,
// handleOpen:(open:boolean)=>void;
// }>
const Accordion =(
    {children,name,title,content,handleOpen,open}
    // :Prop
    )=>{
   const [mobileControlOpen,setMobileControlOpen] = useState(false);
   const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const externalStyle =open?'tw-bg-primary40 tw-text-white md:tw-bg-[#8fd6cf] md:tw-text-[#0B0A0A]':'md:tw-text-[#A3A3A3]'
  const interalStyle =mobileControlOpen?'tw-bg-primary40 tw-text-white md:tw-bg-[#8fd6cf] md:tw-text-[#0B0A0A]':'md:tw-text-[#A3A3A3]'
    
  const templateBollean = isDesktopOrLaptop?open:mobileControlOpen
  return (
        <div>
        <div

        className={`tw-py-[1.25rem] tw-px-[2rem]  tw-border-[#E6E6E6] tw-border-[1px] tw-rounded-[30px] tw-mb-[1.4rem]
        
      
        md:tw-py-[1rem] md:tw-border-[transparent] tw-cursor-pointer
        ${
            isDesktopOrLaptop?
            externalStyle:
            interalStyle
        }
        `}
        onClick={()=>{
            handleOpen(open)
            setMobileControlOpen(!mobileControlOpen)
        }}
        >
         <div className="tw-flex tw-justify-between ">
            <p>{name}</p>
            
            <img
            src={arrow}
            //  src="/arrow-circle-down.svg"
             className="md:tw-hidden" alt="" />
        </div>
       </div>
       {
        templateBollean?
        <div className="md:tw-absolute md:tw-left-0 tw-w-[100%] tw-h-[100%] ">
        <div className="md:tw-flex tw-items-start tw-justify-between">
            <div className="md:tw-w-[470px]" 
            // style={{'border':'1px solid red'}}
            >
                <h2 className="tw-font-epi tw-font-[600] tw-text-[1.5rem] tw-pb-[0.5rem] md:tw-text-[2.5rem]">
                    {title}
                </h2>
                <p className="tw-font-generalSansLight tw-font-[600] tw-text-[1rem] tw-text-[#353535] md:tw-text-[1.375rem] md:tw-leading-[2rem]">
                {content}
                </p>
            </div>

            <div className="">
            {children}
            </div>
        </div>

    </div>:''
       }
        </div>
    )
}

export default Accordion;