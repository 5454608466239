import { lineHeight } from "@mui/system";
import React from "react";
// import Footer from "../../components/footer";
import Footer from "../../components/newNavbar/Footer";
import NavbarArea from "../../components/navbar";
import LatestF from "../allProduct/LatestF";
import NavBar2 from "../../components/newNavbar/NavBar";

export default function index() {
  return (
    <div>
      {/* <NavbarArea /> */}
      <NavBar2 />
      <section className="frequentQs-area">
        <div className="container">
          <div className="col-lg-12 mx-auto">
            <div className="firstlayers row col-md-12 mx-auto justify-content-center text-center">
              <h1 style={{ fontSize: "46px" }}>Data Protection Policy</h1>
            </div>

            <div className="searchelp mt-5 col-md-5 mx-auto">
              <i class="ri-search-line"></i>
              <input
                type="search"
                placeholder="Enter a keyword"
                className="helpsearchbar"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="statement py-5" id="statement">
        <div className="container">
          <div className="row justify-content-center privacy-gap">
            <div className="col-md-10 col-lg-3 privacy-boxs">
              <h5>CONTENT OUTLINE</h5>
              <div style={{ paddingTop: "20px" }}>
                <h3>LIST OF ABBREVIATIONS</h3>

                <div className="outlines">
                  <p>Introduction</p>
                  <p>Policy Statement </p>
                  <p>Responsibilities and Roles Under the NDPR</p>
                  <p>Purpose of Data Processing</p>
                  <p>Data Protection Principles</p>
                  <p>Data Subjects’ rights</p>
                  <p>Consent</p>
                  <p>Security of data</p>
                  <p>Disclosure of data</p>
                  <p>Retention and disposal of data</p>
                  <p>Destruction of Records</p>
                  <p>Data transfers</p>
                  <p>Information asset register/data inventory</p>
                  <p>CONTACT US</p>
                </div>
              </div>
            </div>

            <div className="col-md-10 col-lg-9 info-policy">
              <h4>LIST OF ABBREVIATIONS</h4>
              <div>
                <p>DPCO – Data Protection Compliance Organisation</p>
                <p>DPIA – Data Protection Impact Assessment</p>
                <p>DPO – Data Protection Officer</p>
                <p>GDPR – General Data Protection Regulation</p>
                <p>
                  NITDA – National Information Technology Development Agency
                </p>
                <p>NDPR – General Data Protection Regulation</p>
              </div>
              <br />

              <div>
                <h4>1. Introduction</h4>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <p>1.1</p>
                  <span>
                    <p>
                      <p style={{ paddingBottom: "0px", paddingTop: "0px" }}>
                        Background to the NDPR
                      </p>
                      The NDPR was issued by the NITDA in furtherance of the
                      National Information Technology Development Agency Act
                      2007 to protect the privacy of Nigerians and to ensure
                      that Personal Data is not processed without their
                      knowledge and consent (wherever possible).
                    </p>
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <p>1.2</p>
                  <span>
                    <p>
                      <p style={{ paddingBottom: "0px", paddingTop: "0px" }}>
                        Definitions
                      </p>
                      1. Authorised Party – means any individual or company
                      authorised by Prembly to engage in
                    </p>
                    <p>
                      2. any service which will involve the processing of
                      Personal Data.
                    </p>
                    <p>
                      3. Board – the Board of Directors of Prembly which will
                      include the management of Prembly where the context
                      allows.
                    </p>
                    <p>
                      4. Data Breach – a breach of security leading to the
                      accidental, or unlawful, destruction, loss, alteration,
                      unauthorised disclosure of, or access to, Personal Data
                      transmitted, stored or otherwise processed. There is an
                      obligation on the controller to report Personal Data
                      breaches to the supervisory authority and where the breach
                      is likely to adversely affect the Personal Data or privacy
                      of the Data Subject.
                    </p>
                    <p>
                      5. Data Controller/Controller – the natural or legal
                      person, public authority, agency or other body which,
                      alone or jointly with others, determines the purposes and
                      means of the processing of Personal Data of Nigerians.
                    </p>
                    <p>
                      6. Data Subject – any living individual who is the subject
                      of Personal Data held by an organisation.
                    </p>
                    <p>
                      7. Data Subject consent - means any freely given,
                      specific, informed and unambiguous indication of the Data
                      Subject's wishes by which he or she, by a statement or by
                      clear affirmative action, signifies agreement to the
                      processing of Personal Data.
                    </p>
                    <p>
                      8. Management – means the top management of Prembly.
                      Prembly – is an Innovations Limited, a private company
                      limited by
                    </p>
                    <p>9. shares with registration number 1577301.</p>
                    <p>
                      10. Personal Data/Data – any information relating to an
                      identified or identifiable natural person; an identifiable
                      natural person is a person who can be identified, directly
                      or indirectly, in particular by reference to an identifier
                      such as a name, an identification number, location data,
                      an online identifier or to one or more factors specific to
                      the physical, physiological, genetic, mental, economic,
                      cultural or social identity of that natural person.
                    </p>
                    <p>
                      11. Processing – any operation or set of operations which
                      is performed on Personal Data or sets of Personal Data,
                      whether or not by automated means, such as collection,
                      recording, organisation, structuring, storage, adaptation
                      or alteration, retrieval, consultation, use, disclosure by
                      transmission, dissemination or otherwise making available,
                      alignment or combination, restriction, erasure or
                      destruction.
                    </p>
                    <p>
                      12. Data Processor/Processor – any company or individual
                      who is engaged to Process Personal Data on behalf of the
                      Controller.
                    </p>
                    <p>
                      13. Profiling – is any form of automated processing of
                      Personal Data intended to evaluate certain personal
                      aspects relating to a natural person, or to analyse or
                      predict that person’s performance at work, economic
                      situation, location, health, personal preferences,
                      reliability, or behaviour. This definition is linked to
                      the right of the Data Subject to object to profiling and a
                      right to be informed about the existence of profiling, of
                      measures based on profiling and the envisaged effects of
                      profiling on the individual.
                    </p>
                    <p>
                      14. Sensitive Personal Data – means Personal Data
                      revealing racial or ethnic origin, financial details,
                      political opinions, religious or philosophical beliefs,
                      trade-union membership, genetic data and biometric data,
                      data concerning health or sexual orientation.
                    </p>
                    <p>
                      15. Subject Access Request Procedure – means the procedure
                      for Data Subject to access the Personal Data which is in
                      the possession of Prembly.
                    </p>
                    <p>
                      16. Staff – means any individual under employment with
                      Prembly or any engaged contractor.
                    </p>
                    <p>
                      17. Third-Party – a natural or legal person, public
                      authority, agency or body other than the Data Subject,
                      controller, processor and persons who, under the direct
                      authority of the controller or processor, are authorised
                      to process Personal Data.
                    </p>
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <p>1.3</p>
                  <span>
                    <p>
                      <p style={{ paddingBottom: "0px", paddingTop: "0px" }}>
                        Purpose and Scope
                      </p>
                      Purpose and Scope The purpose of this Policy is to create
                      a data processing procedure and system that complies
                      strictly with the NDPR. This Policy has been designed to
                      assist all departments of Prembly to perform their duties
                      in line with the NDPR. The main objective is to ensure
                      thorough processing of Personal Data of Data Subjects in
                      line with the objectives of the NDPR. The DPO shall be
                      responsible for the general cycle of data processing of
                      Prembly.
                    </p>
                    <p>
                      All staff are required to strictly adhere to the rules
                      outlined herein
                    </p>
                  </span>
                </div>
              </div>
              <br />
              <div>
                <h4>2. Policy Statement</h4>
                <div
                  style={{
                    display: "flex",
                    gap: "7px",
                  }}
                >
                  <p>2.1</p>

                  <span>
                    <p>
                      Prembly is a Controller and/or Processor under the NDPR
                      (as the case may be).
                    </p>
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "7px",
                  }}
                >
                  <p>2.2</p>

                  <span>
                    <p>
                      The Board is committed to protecting the privacy of the
                      Data Subjects and complying with all relevant data
                      protection laws.
                    </p>
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "7px",
                  }}
                >
                  <p>2.3</p>

                  <span>
                    <p>
                      Strict compliance with this policy is required alongside
                      other relevant policies such as the Information Security
                      Policy.
                    </p>
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "7px",
                  }}
                >
                  <p>2.4</p>

                  <span>
                    <p>
                      This policy shall govern the processing of Personal Data
                      by Prembly, including the processing of data of customers,
                      Staff, partners, and contractors, and any other Personal
                      Data the organisation processes from any source.
                    </p>
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "7px",
                  }}
                >
                  <p>2.5</p>

                  <span>
                    <p>
                      This policy applies to all Staff and any breach of this
                      policy shall be treated as gross misconduct. Where the act
                      of the defaulting staff also results in a criminal
                      offence, the offence shall be reported as soon as possible
                      to the appropriate authorities.
                    </p>
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "7px",
                  }}
                >
                  <p>2.6</p>

                  <span>
                    <p>
                      Authorised Parties who have or may have access to Personal
                      Data, should be expected to have read, understood and must
                      agree to comply with this Policy as it affects them. No
                      third party may access Personal Data held by Prembly
                      without having first entered into a data confidentiality
                      agreement or, which will:
                    </p>

                    <p>
                      1. imposes on the third party obligations which Prembly is
                      committed to,
                    </p>
                    <p>
                      2. gives Prembly the right to audit compliance with the
                      agreement; and
                    </p>
                    <p>
                      3. compel the third party to indemnify Prembly in the
                      event of any Data Breach through the fault of the third
                      party.
                    </p>
                  </span>
                </div>
              </div>
              <br />

              <div>
                <h4>3. Responsibilities and Roles Under the NDPR</h4>
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  <p>3.1 </p>
                  <span>
                    <p>Management/Board</p>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>1.</p>
                      <p>
                        The Management shall also be directly responsible for
                        the implementation of this policy.
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>2.</p>
                      <p>
                        The Management shall be responsible for developing and
                        encouraging good information handling practices within
                        Prembly with responsibilities are set out in individual
                        job descriptions.
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>3.</p>
                      <p>
                        The Management shall appoint a DPO who shall be of the
                        senior management team. The DPO must be, in the opinion
                        of the Board, suitably qualified and experienced and
                        shall be appointed to take responsibility for Prembly’s
                        compliance with this policy on a day-to-day basis.
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>4.</p>
                      <p>
                        The Management shall engage a DPCO to conduct an audit
                        of its privacy and data protection practices annually
                        and ensure that it is filed with NITDA before the
                        official deadline for each year.
                      </p>
                    </div>
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  <p>3.2 </p>
                  <span>
                    <p>DPO</p>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>1.</p>
                      <p>
                        The DPO shall be accountable to the Board of Prembly for
                        the management of Personal Data within Prembly and for
                        ensuring compliance with this Policy, the data
                        protection regulations and good ethical practice
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>2.</p>
                      <p>
                        Staff are responsible for ensuring that any Personal
                        Data about them and supplied by them to Prembly is
                        accurate and up-to-date.
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>3.</p>
                      <p>
                        This DPO <br />
                        shall submit a report to the Board quarterly which shall
                        state:
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>4.</p>
                      <p>
                        The DPO shall be responsible for procedures under the
                        NDPR such as the Subject Access Request Procedure and
                        are the first point of call for the Staff seeking
                        clarification on any aspect of data protection
                        compliance.
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>5.</p>
                      <p>
                        Where necessary, the DPO shall organise specific
                        training and awareness in relation to specific roles for
                        the Staff generally.
                      </p>
                    </div>
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  <p>3.3 </p>
                  <span>
                    <p>Staff</p>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>1.</p>
                      <p>
                        Compliance with this policy shall be the responsibility
                        of all Staff of Prembly who process or come in contact
                        with Personal Data by the virtue of their employment or
                        contract.
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>2.</p>
                      <p>
                        Staff are responsible for ensuring that any Personal
                        Data about them and supplied by them to Prembly is
                        accurate and up-to-date.
                      </p>
                    </div>
                  </span>
                </div>
              </div>
              <br />

              <div>
                <h4>4. Purpose of Data Processing</h4>
                <p>
                  We only use your personal data for legitimate business
                  reasons. This includes:
                </p>

                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <p>4.1</p>
                    <span>
                      <p>
                        <p style={{ paddingBottom: "0px", paddingTop: "0px" }}>
                          Administration and Data Verification
                        </p>
                        When we process personal information provided either by
                        form, website, API or our application for our
                        verification process, we will process the personal data
                        solely for the purpose of carrying out the personal
                        identity verification service.
                      </p>
                    </span>
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <p>4.2</p>
                    <span>
                      <p>
                        <p style={{ paddingBottom: "0px", paddingTop: "0px" }}>
                          Managing customer relationships
                        </p>
                        We may ask for feedback about our products and services
                        and share this with certain members of our staff to
                        improve our offering. We might also use notes from
                        conversations we have with customers online, by
                        telephone or in-person to customise products and
                        services for customers.
                      </p>
                    </span>
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <p>4.3</p>
                    <span>
                      <p>
                        <p style={{ paddingBottom: "0px", paddingTop: "0px" }}>
                          Improving and developing products and services
                        </p>
                        Analysing how our customer use our products and services
                        helps us understand more about our customer and shows us
                        where we can improve. For instance, we analyse the
                        results of our marketing activities to measure their
                        effectiveness and the relevance of our campaigns.
                      </p>
                    </span>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <p>4.4</p>
                    <span>
                      <p>
                        <p style={{ paddingBottom: "0px", paddingTop: "0px" }}>
                          Preventing and detecting fraud and data security
                        </p>
                        We have a duty to protect personal data and to prevent,
                        detect and contain data breaches. We may use certain
                        information (e.g. name, account number, age,
                        nationality, IP address, etc.) to quickly and
                        efficiently detect a particular crime and the person
                        behind it.
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <br />

              <div>
                <h4>5. Data Protection Principles</h4>
                <p>
                  All processing of Personal Data must be conducted in
                  accordance with the data protection principles contained in
                  this Policy. Prembly’s policies and procedures shall be
                  designed to ensure compliance with the principles.
                </p>

                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    <p>5.1</p>
                    <span>
                      <p>
                        Personal Data must be processed lawfully and
                        transparently
                        <br />
                        Lawful – you must identify a lawful basis before you can
                        process Personal Dats <br /> Transparently – the NDPR
                        includes rules on giving privacy information to Data
                        Subjects in Section 9. These are detailed and specific,
                        placing an emphasis on making privacy notices
                        understandable and accessible. The information must be
                        communicated to the Data Subject in an intelligible form
                        using clear and plain language.
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p>1.</p>
                          <p>
                            {" "}
                            All Personal Data of Customers shall be obtained
                            with the consent of the Customer and the consent
                            must be recorded.
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p>2.</p>
                          <p>
                            Where it is economically inconvenient to obtain the
                            consent of the Data Subject, the DPO shall ensure
                            that the data is obtained in furtherance of any of
                            the following:
                            <span>
                              <p>
                                a. performance of a contract with the Data
                                Subject;
                                <br />
                                b. complying with any legal obligation on
                                Prembly
                                <br />
                                c. performing a task in the interest of the
                                public;
                              </p>
                            </span>
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p>3.</p>
                          <p>
                            {" "}
                            Notwithstanding the above, no sensitive Personal
                            Data shall be processed except with the consent of
                            the Data Subject.
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p>4.</p>
                          <p>
                            Where a Data Subject request for any information on
                            the processing of the Personal Data either through a
                            Data Subjects Request Procedure or otherwise, the
                            specific information that shall be provided to the
                            Data Subject and the report shall include the
                            following:
                            <span>
                              <p>
                                a.the identity and the contact details of
                                Prembly (where Prembly is the Controller) and
                                any of the Prembly representative;
                                <br />
                                b.the contact details of the DPO;
                                <br />
                                c. the purposes of the processing for which the
                                Personal Data are intended as well as the legal
                                basis for the processing;
                                <br />
                                d. the period for which the Personal Data will
                                be stored;
                                <br />
                                e. the existence of the rights to request
                                access, rectification, erasure or to object to
                                the processing, and the conditions (or lack of)
                                relating to exercising these rights, such as
                                whether the lawfulness of previous processing
                                will be affected;
                                <br />
                                f. the categories of Personal Data concerned;
                                <br />
                                g. the recipients or categories of recipients of
                                the Personal Data, where applicable;
                                <br />
                                h. where applicable, that the controller intends
                                to transfer Personal Data to a recipient in a
                                foreign country and the level of protection
                                afforded to the data;
                                <br />
                                i. any further information necessary to
                                guarantee fair processing.
                              </p>
                            </span>
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p>5.</p>
                          <p>
                            {" "}
                            Where Prembly operates as a Processor under any
                            agreement with a third party and a Data Subject
                            request for any information on the processing of the
                            Personal Data, the DPO shall
                            <span>
                              <p>
                                a. notify the Controller of the request of the
                                Customer; and <br />
                                b. notify the Data Subject of the position of
                                Prembly as the Processor and provide the Data
                                Subject of the details of the Controller.
                              </p>
                            </span>
                          </p>
                        </div>
                      </p>
                    </span>
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <p>5.2</p>
                    <span>
                      <p>
                        Personal Data can only be collected for specific,
                        explicit and legitimate purposes
                        <br />
                        1. Personal Data obtained for specified purposes must
                        not be used for a purpose that differs from those
                        originally collected for
                        <br />
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p> 2.</p>
                          <p>
                            Where the Personal Data is required to process any
                            other purpose other than the purpose it was
                            collected for, the DPO or the staff shall attempt to
                            obtain the consent of the Data Subject or process it
                            with other lawful bases (where it is economically
                            impossible to obtain the consent).
                          </p>
                        </div>
                      </p>
                    </span>
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <p>5.3</p>
                    <span>
                      <p>
                        Personal Data must be adequate, relevant and limited to
                        what is necessary for processing
                        <br />
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p> 1.</p>
                          <p>
                            The DPO is responsible for ensuring that Prembly
                            does not collect information that is not strictly
                            necessary for the purpose for which it is obtained.
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p> 2.</p>
                          <p>
                            All data collection forms (electronic or
                            paper-based), including data collection requirements
                            in new information systems, must include a link to
                            Prembly’s privacy statement and must be approved by
                            the DPO before use.
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p> 3.</p>
                          <p>
                            The DPO will ensure that on an annual basis all data
                            collection methods are reviewed by internal
                            audits/external audits to ensure that collected data
                            continues to be adequate, relevant and not
                            excessive.
                          </p>
                        </div>
                      </p>
                    </span>
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <p>5.4</p>
                    <span>
                      <p>
                        <p>
                          Personal Data must be accurate and kept up to date
                          with every effort to erase or rectify without delay
                          <span
                            style={{
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <p> 1.</p>
                            <p>
                              Personal Data that is stored by Prembly must be
                              reviewed and updated as necessary. No Personal
                              Data should be kept unless it is reasonable to
                              assume that it is accurate.
                            </p>
                          </span>
                          <span
                            style={{
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <p> 2.</p>
                            <p>
                              The DPO is responsible for ensuring that all staff
                              are trained on the importance of collecting
                              accurate data and maintaining it
                            </p>
                          </span>
                          <span
                            style={{
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <p> 3.</p>
                            <p>
                              It is also the responsibility of the Company to
                              ensure that data held by Prembly is accurate and
                              up to date. Completion of a registration or
                              application form by a Data Subject will include a
                              statement that the data contained therein is
                              accurate at the date of submission.
                            </p>
                          </span>
                          <span
                            style={{
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <p> 4.</p>
                            <p>
                              Staff, customers and other Data Subject should be
                              required to notify Prembly of any changes in
                              circumstance to enable personal records to be
                              updated accordingly. It is the responsibility of
                              Prembly to ensure that any notification regarding
                              change of circumstances is recorded and acted
                              upon.
                            </p>
                          </span>
                          <span
                            style={{
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <p> 5.</p>
                            <p>
                              The DPO is responsible for ensuring that
                              appropriate procedures and policies are in place
                              to keep Personal Data accurate and up to date,
                              taking into account the volume of data collected,
                              the speed with which it might change and any other
                              relevant factors.stances is recorded and acted
                              upon.
                            </p>
                          </span>
                          <span
                            style={{
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <p> 6.</p>
                            <p>
                              On an annual basis, the DPO will review the
                              retention dates of all the Personal Data processed
                              by Prembly, by reference to the data inventory,
                              and will identify any data that is no longer
                              required in the context of the registered purpose.
                              This data will be securely deleted/destroyed in
                              line with the relevant policy.
                            </p>
                          </span>
                          <span
                            style={{
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <p> 7.</p>
                            <p>
                              The DPO is responsible for responding to requests
                              for rectification from Data Subjects within
                              twenty-four calendar days (Subject Access Request
                              Procedure). This can be extended for an additional
                              twenty days for complex requests but after the
                              Data Subject has been informed of the delay and
                              the reason. Where DPO decides not to comply with
                              the request, the DPO must respond to the Data
                              Subject to explain the reasoning and inform them
                              of their right to complain to the supervisory
                              authority or seek a judicial remedy.
                            </p>
                          </span>
                          <span
                            style={{
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <p> 8.</p>
                            <p>
                              Where third-party organisations may have been
                              passed inaccurate or out-of-date Personal Data,
                              the DPO is responsible for informing the
                              third-party that the information is inaccurate
                              and/or out of date and is not to be used to inform
                              decisions about the individuals concerned; and for
                              passing any correction of the Personal Data to the
                              third party where this is required.
                            </p>
                          </span>
                        </p>
                      </p>
                    </span>
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <p>5.5</p>
                    <span>
                      <p>
                        <p>
                          Personal Data must be kept in a form such that the
                          Data Subject can be identified only as long as is
                          necessary for processing.
                        </p>
                        <span
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p> 1.</p>
                          <p>
                            Where Personal Data is retained beyond the
                            processing date, it will be minimised, encrypted or
                            pseudonymised in order to protect the identity of
                            the Data Subject in the event of a data breach.
                          </p>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p> 2.</p>
                          <p>
                            In line with the Retention of Records Procedure and,
                            once its retention date is passed, it must be
                            securely destroyed as set out in this procedure.
                          </p>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p> 3.</p>
                          <p>
                            The DPO must specifically approve in writing any
                            data retention that exceeds the retention periods
                            defined in Retention of Records Procedure and must
                            ensure that the justification is clearly identified
                            and in line with the requirements of the data
                            protection regulation.
                          </p>
                        </span>
                      </p>
                    </span>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <p>5.6</p>
                    <span>
                      <p>
                        <p>
                          Personal Data must be kept in a form such that the
                          Data Subject can be identified only as long as is
                          necessary for processing.
                        </p>
                        <span
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p> 1.</p>
                          <p>
                            The DPO will carry out a risk assessment where any
                            department introduces a new service, product or
                            procedure, taking into account all the circumstances
                            of Prembly’s controlling or processing operations.
                          </p>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p> 2.</p>
                          <p>
                            The DPO will carry out a risk assessment where any
                            department introduces a new service, product or
                            procedure, taking into account all the circumstances
                            of Prembly’s controlling or processing operations.
                            In determining appropriateness, the DPO should also
                            consider the extent of possible damage or loss that
                            might be caused to individuals if a security breach
                            occurs, the effect of any security breach on
                            Prembly, and any likely reputational damage
                            including the possible loss of customer trust.
                          </p>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p> 3.</p>
                          <p>
                            When assessing appropriate technical measures, the
                            DPO will consider the following:
                            <p>
                              a. Password protection;
                              <br />
                              b. Automatic locking of idle terminals;
                              <br />
                              c. Removal of access rights for USB and other
                              memory media;
                              <br />
                              d. Virus checking software and firewalls;
                              <br />
                              e. Role-based access rights including those
                              assigned to temporary Staff;
                              <br />
                              f. Encryption of devices that leave the
                              organisations premises such as laptops;
                              <br />
                              g. Security of local and wide area networks;
                              <br />
                              h. Privacy-enhancing technologies such as
                              pseudonymisation and anonymisation;
                              <br />
                              i. Identifying appropriate international security
                              standards relevant to Prembly.
                            </p>
                          </p>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p> 4.</p>
                          <p>
                            When assessing appropriate organisational measures
                            the DPO will consider the following:
                            <p>
                              a. the appropriate training levels throughout
                              Prembly;
                              <br />
                              b. measures that consider the reliability of
                              employees (such as references etc.);
                              <br />
                              c. the inclusion of data protection in employment
                              contracts; d. identification of disciplinary
                              action measures for data breaches;
                              <br />
                              e. monitoring of staff for compliance with
                              relevant security standards;
                              <br />
                              f. electronic and paper-based records;
                              <br />
                              g. adoption of a clear desk policy; <br /> h.
                              storing of paper-based data in lockable fire-proof
                              cabinets;
                              <br />
                              i. restricting the use of portable electronic
                              devices outside of the workplace;
                              <br />
                              j. restricting the use of employee’s personal
                              devices being used in the workplace; <br />
                              k. adoption of a clear desk policy;
                              <br />
                              l. storing of paper-based data in lockable
                              fire-proof cabinets; <br /> m. the imposition of
                              contractual obligations on the importing
                              organisations to <br /> n. take appropriate
                              security measures when transferring data to
                              foreign countries.
                            </p>
                          </p>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p>5.</p>
                          <p>
                            These controls have been selected based on
                            identified risks to Personal Data, and the potential
                            for damage or distress to Data Subjects.
                          </p>
                        </span>
                      </p>
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <p>5.7</p>
                  <span>
                    <p>
                      <p>
                        The Controller must be able to demonstrate compliance
                        with the NDPR’s other principles (accountability)
                        <span
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p> 1.</p>
                          <p>
                            The accountability principle in Section 2.1 (3)
                            requires you to demonstrate that you comply with the
                            principles and states explicitly that this is your
                            responsibility.
                          </p>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <p> 2.</p>
                          <p>
                            The Prembly will implement data protection policies,
                            technical and organisational measures, as well as
                            adopting techniques such as data protection by
                            design, DPIAs, breach notification procedures and
                            incident response plans.
                          </p>
                        </span>
                      </p>
                    </p>
                  </span>
                </div>
              </div>

              <div>
                <h4>6. Data Subjects’ rights</h4>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>6.1</p>
                  <p>
                    Data Subjects have the following rights as it relates to the
                    record of Personal Data and its processing to:
                    <div>
                      <span
                        style={{
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <p>1.</p>
                        <p> withdraw their consent at any time;</p>
                      </span>

                      <span
                        style={{
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <p>2.</p>
                        <p>
                          {" "}
                          make Data Subject Access Requests regarding the nature
                          of information held and to whom it has been disclosed;
                        </p>
                      </span>

                      <span
                        style={{
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <p>3.</p>
                        <p>
                          {" "}
                          prevent processing likely to cause damage or distress;
                        </p>
                      </span>

                      <span
                        style={{
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <p>4.</p>
                        <p>prevent processing purposes of direct marketing.</p>
                      </span>
                      <span
                        style={{
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <p>5.</p>
                        <p>
                          be informed about the mechanics of the automated
                          decision-making process thatwill significantly affect
                          them.
                        </p>
                      </span>
                      <span
                        style={{
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <p>6.</p>
                        <p>
                          not have significant decisions that will affect them
                          taken solely by an automated process.
                        </p>
                      </span>

                      <span
                        style={{
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <p>7.</p>
                        <p>
                          sue for compensation if they suffer damage by any
                          contravention of the NDPR.
                        </p>
                      </span>

                      <span
                        style={{
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <p>8.</p>
                        <p>
                          take action to rectify, block, erased their Personal
                          Data which includes their right to be forgotten, or to
                          destroy inaccurate data.
                        </p>
                      </span>

                      <span
                        style={{
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <p>9.</p>
                        <p>
                          request the supervisory authority to assess whether
                          any provision of the NDPR has been contravened.{" "}
                        </p>
                      </span>

                      <span
                        style={{
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <p>10.</p>
                        <p>
                          have Personal Data provided to them in a structured,
                          commonly used and machine-readable format, and the
                          right to have that data transmitted to another
                          controller.
                        </p>
                      </span>

                      <span
                        style={{
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <p>11.</p>
                        <p>
                          object to any automated profiling that is occurring
                          without consent.
                        </p>
                      </span>
                    </div>
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>6.2</p>
                  <p>
                    Prembly will ensure that Data Subjects can exercise their
                    rights.
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>1.</p>
                      <p>
                        Data Subjects may make data access requests as described
                        in the Subject Access Request Procedure; this procedure
                        also describes how Prembly will ensure that its response
                        to the data access request as provided in this Policy.{" "}
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>2</p>
                      <p>
                        {" "}
                        Prembly will provide a procedure for Data Subjects to
                        complain about the processing of their Personal Data,
                        the handling of a Data Subject Access Request and
                        appeals on how complaints have been handled in the
                        Complaints Procedure
                      </p>
                    </span>
                  </p>
                </div>
              </div>

              <div>
                <h4>7. Consent</h4>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>7.1</p>
                  <p>
                    Consent will mean explicitly and freely given affirmation
                    which is a specific, informed and unambiguous indication of
                    the Data Subject’s wishes either by a statement or by clear
                    affirmative action, and his agreement to the processing of
                    the Personal Data relating to him or her.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>7.2</p>
                  <p>
                    There must be some active communication from the Data
                    Subject to demonstrating active consent. Consent cannot be
                    inferred from non-response to a communication. The
                    Controller must be able to demonstrate and record the
                    consent that was obtained for the processing operation.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>7.3</p>
                  <p>
                    Where Prembly provides online services to children, parental
                    or custodial authorisation must be obtained. This
                    requirement applies to children under the age of 16.
                  </p>
                </div>
              </div>

              <div>
                <h4>8. Security of data</h4>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>8.1</p>
                  <p>
                    All Staff are responsible for ensuring that any Personal
                    Data in the possession of Prembly, is kept securely and is
                    not, under any conditions, disclosed to any third party
                    without written authorization of the DPO.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>8.2</p>
                  <p>
                    The DPO shall not issue any authorization unless the third
                    party has been specifically authorised by Prembly to receive
                    that information and has entered into a confidentiality
                    agreement that conforms with the requirements of this
                    Policy.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>8.3</p>
                  <p>
                    All Personal Data should only be made accessible to Staff
                    who need to use it, and access may only be granted in line
                    with the Access Control Policy. All Personal Data should be
                    treated with the highest security and must be kept:
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        // marginTop: "-10px",
                      }}
                    >
                      <p>1.</p>
                      <p>in a lockable room with controlled access;</p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>2</p>
                      <p>in a locked drawer or filing cabinet;</p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>3</p>
                      <p>
                        if computerised, encrypted and password protected in
                        line with corporate requirements
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>4</p>
                      <p>in the Access Control Policy; or</p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>5</p>
                      <p>
                        stored on (removable) computer media which are
                        encrypted.
                      </p>
                    </span>
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>8.4</p>
                  <p>
                    Personal Data may only be deleted or disposed of in line
                    with the Retention of Records Procedure. Manual records that
                    have reached their retention date are to be shredded and
                    disposed of as confidential waste. Hard drives of redundant
                    PCs are to be removed and immediately destroyed before
                    disposal.
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>8.5</p>
                  <p>
                    Processing of Personal Data out of office presents a
                    potentially greater risk of loss, theft or damage to
                    Personal Data. Staff must be specifically authorised to
                    process data out of office.
                  </p>
                </div>
              </div>

              <div>
                <h4>9. Security of data</h4>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>9.1</p>
                  <p>
                    Prembly must ensure that Personal Data is not disclosed to
                    unauthorised third parties which includes family members,
                    friends, and government bodies. All Staff should exercise
                    caution when asked to disclose Personal Data to a third
                    party and must not disclose the Personal Data without
                    written authorization from the DPO.
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>9.2</p>
                  <p>
                    Prembly shall consider whether the disclosure of any
                    Personal Data is relevant to, and necessary for, the conduct
                    of Prembly’s business
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>9.3</p>
                  <p>
                    Where any unauthorised disclosure of Personal Data to a
                    third party has been discovered:
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "px",
                      }}
                    >
                      <p>1</p>
                      <p>
                        the Staff who discovered the unauthorised disclosure
                        should immediately notify the DPO;
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>2</p>
                      <p>
                        the DPO should investigate the claim and prepare a
                        report on the claim of the unauthorised breach to the
                        Management within 12 hours;
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <p>3</p>
                      <p>
                        the Management will consider the report of the DPO and
                        where the Management is convinced that there has been a
                        breach, should file a report to NITDA within 48 hours.
                      </p>
                    </span>
                  </p>
                </div>
              </div>

              <div>
                <h4>10. Retention and disposal of data</h4>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>10.1</p>
                  <p>
                    Prembly shall not keep Personal Data in a form that permits
                    identification of Data Subjects than is necessary, in
                    relation to the purpose(s) for which the data was originally
                    collected.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>10.2</p>
                  <p>
                    Prembly may store Personal Data for longer periods if the
                    Data will be processed solely for archiving purposes in the
                    public interest, scientific or historical research purposes
                    or statistical purposes, subject to the implementation of
                    appropriate technical and organisational measures to
                    safeguard the rights and freedoms of the Data Subject.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>10.3</p>
                  <p>
                    The retention period for each category of Personal Data will
                    be set out in the Retention of Records Procedure along with
                    the criteria used to determine this period including any
                    statutory obligations Prembly has to retain the Data.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>10.4</p>
                  <p>
                    Prembly’s Retention of Records Procedures will apply in all
                    cases.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>10.5</p>
                  <p>
                    Personal Data must be disposed of securely in accordance
                    with the principle of data security – processed in an
                    appropriate manner to maintain security, thereby protecting
                    the rights and privacy of Data Subjects. Any disposal of
                    data will be done in accordance with the secure disposal
                    procedure contained in this Policy.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>10.6</p>
                  <p>
                    The Retention schedule below will govern the period that
                    Records will be retained in accordance with this Policy.
                    Prembly must comply with data protection laws, contractual
                    or regulatory obligations which require that records must
                    not be kept longer than is necessary for the purpose for
                    which it was collected.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>10.7</p>
                  <p>The Data Retention Schedule is organized as follows:</p>
                </div>

                <div style={{ width: "100%", padding: "20px 0px" }}>
                  <table
                    style={{
                      width: "100%",
                    }}
                  >
                    <tr
                      style={{
                        height: "50px",
                      }}
                    >
                      <th
                        style={{
                          padding: " 0 30px",
                          fontWeight: "400",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        S/N
                      </th>
                      <th
                        style={{
                          padding: "0 20px",
                          fontWeight: "400",
                          color: "#000000",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Record Type
                      </th>
                      <th
                        style={{
                          padding: "0 10px",
                          fontWeight: "400",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Retention Period
                      </th>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                        fontSize: "14px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        1
                      </td>
                      <td
                        style={{
                          padding: "0 20px",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Verified Data
                      </td>
                      <td
                        style={{
                          padding: "0 20px",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Immediate deletion upon transfer to the client
                      </td>
                    </tr>
                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                        fontSize: "14px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        2
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Accounting and Finance
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        7 years
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        3
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Contracts
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        7 years after termination/expiry
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        4
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Corporate Records
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        6 years
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        5
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Correspondence and Internal Memoranda
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        6 years
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        6
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Electronic Documents
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        6 years
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        7
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Grant Record
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        6 years after expiration
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        8
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Insurance Record
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        7 years
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        9
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Legal Files and Papers
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Permanent
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        10
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Miscellaneous
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        6 years
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        11
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Payroll Documents
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        6 years
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        12
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Pension Documents
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        6 years
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        13
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Personnel Records
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        6 years
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        14
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Property Records
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Permanent
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        15
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Tax Records
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        10 years
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        16
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        General Correspondence
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        6 years after completion
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        17
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        General Correspondence
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        10 years
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        18
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Corporate Policies
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        6 years after cancellation or expiry
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        19
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Employment records
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        6 years after termination
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        20
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Health & Safety Records
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        6 years
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        21
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Corporate Policies
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        6 years after cancellation or expiry
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        21
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Intellectual Property documents
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Permanent
                      </td>
                    </tr>

                    <tr
                      style={{
                        color: "#000000",
                        height: "50px",
                      }}
                    >
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        22
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        Membership records
                      </td>
                      <td
                        style={{
                          padding: "0 30px",
                          fontWeight: "300",
                          color: "#000000",
                          fontSize: "14px",
                        }}
                      >
                        6 years from membership expiry
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div>
                <h4>11. Destruction of Records</h4>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>11.1</p>
                  <p>
                    Destruction of Records is a critical component of our
                    practice. Once the retention period has elapsed, the Records
                    are either reviewed, archived, returned, anonymized or
                    confidentially destroyed depending on their purpose,
                    importance and legal requirements. When a Record has reached
                    its designated destruction date, the DPO shall refer to the
                    retention register to determine the next action to be taken.
                    Not all data or records are expected to be deleted upon
                    expiration; sometimes it is sufficient to anonymise the
                    data, return the Record or to archive Records for a further
                    period.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>11.2</p>
                  <p>
                    Records will not be kept after the retention period unless:
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>1</p>
                      <p>
                        the Record is the subject of a pending litigation,
                        administrative or regulatory proceedings. In these
                        circumstances, destruction will be delayed until such
                        regulatory proceedings have been completed, at which
                        time a new retention period will be created.
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>2</p>
                      <p>
                        the Record has long-term value for Identity Pay’s
                        statutory or regulatory obligations.
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>3</p>
                      <p>
                        Identity Pay determines that such Record will be
                        permanently stored, provided that no legal, regulatory
                        or contractual breach will occur by the permanent
                        retention of such record.
                      </p>
                    </span>
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>11.3</p>
                  <p>
                    Prembly is committed to the secure and safe disposal of any
                    Record in accordance with our contractual and legal
                    obligations. Accordingly, when the Records have been
                    retained until the end of their retention period, they can
                    be destroyed in the following ways:
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>1</p>
                      <p>
                        Hardcopy of documents will be destroyed by shredding or
                        burning.
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>2</p>
                      <p>
                        Non-sensitive information can be thrown in a normal
                        trash bin and properly disposed of.
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>3</p>
                      <p>
                        Electronic Records will be deleted/erased and cleared
                        from recycling bins.
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>4</p>
                      <p>
                        Electronic equipment or systems containing Records will
                        be destroyed. Where a specific Record is stored in one
                        single media such as a CD Rom and cannot be erased, such
                        media used to store the Record will be physically
                        destroyed. However, where the media contains other
                        Records which are not due for deletion, and it is
                        impossible to separately erase such Record due for
                        deletion, the retention period shall be extended to such
                        period when all the Records in that media are due for
                        deletion/destruction, and Identity Pay shall either
                        format or physically destroy the media.
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>5</p>
                      <p>
                        Destruction of electronic Records should render them
                        non-recoverable even using forensic data recovery
                        techniques.
                      </p>
                    </span>
                  </p>
                </div>
              </div>

              <div>
                <h4>12. Data transfers</h4>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>12.1</p>
                  <p>
                    Prembly may not export Personal Data to any foreign country
                    unless there is an appropriate level of protection for the
                    rights of the Data Subjects.
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>12.2</p>
                  <p>
                    Prembly may only transfer Data to a third party in a foreign
                    country on the occurrence of any of the following:
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>1</p>
                      <p>
                        Where Prembly has adopted an approved binding corporate
                        rule with the third party;
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>2</p>
                      <p>
                        Where Prembly has adopted approved model contract
                        clauses for the transfer of Data to the third party; or
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>3</p>
                      <p>
                        the Data Subject has explicitly consented to the
                        proposed transfer, after having been informed of the
                        possible risks of such transfers for the Data Subject
                        due to the absence of an adequacy decision and
                        appropriate safeguards.
                      </p>
                    </span>
                  </p>
                </div>
              </div>

              <div>
                <h4>13. Information asset register/data inventory</h4>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>13.1</p>
                  <p>
                    Prembly is aware of any risks associated with the processing
                    of particular types of Personal Data.
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>1</p>
                      <p>business processes that use Personal Data;</p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>2</p>
                      <p>source of Personal Data;</p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>3</p>
                      <p>the volume of Data Subjects;</p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>4</p>
                      <p>description of each item of Personal Data;</p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>5</p>
                      <p>processing activity;</p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>6</p>
                      <p>
                        maintains the inventory of data categories of Personal
                        Data processed;
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>7</p>
                      <p>
                        documents the purpose(s) for which each category of
                        Personal Data is used;
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>8</p>
                      <p>
                        recipients, and potential recipients, of the Personal
                        Data;
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>9</p>
                      <p>the role of the Prembly throughout the data flow;</p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>10</p>
                      <p>key systems and repositories;</p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>11</p>
                      <p>any data transfers; and</p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>12</p>
                      <p>and all retention and disposal requirements.</p>
                    </span>
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <p>13.2</p>
                  <p>
                    Prembly is aware of any risks associated with the processing
                    of particular types of Personal Data
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>1</p>
                      <p>
                        Prembly assesses the level of risk to individuals
                        associated with the processing of their Personal Data.
                        DPIA Procedure is carried out in relation to the
                        processing of Data by Prembly or processing by third
                        parties on behalf of Prembly.
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>2</p>
                      <p>
                        Prembly shall manage any risks identified by the risk
                        assessment in order to reduce the likelihood of a
                        non-conformance with this policy.
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>3</p>
                      <p>
                        Where a type of processing involving the use of new
                        technologies (and taking into account the nature, scope,
                        context and purposes of the processing) is likely to
                        result in a high risk to the privacy of the Data
                        Subject, Prembly shall, prior to the processing, carry
                        out a DPIA on the proposed processing operations. A
                        single DPIA may address a set of similar processing
                        operations that present similar high risks.
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>4</p>
                      <p>
                        Where, as a result of a DPIA it is clear that Prembly is
                        about to commence processing of Personal Data that could
                        cause damage and/or distress to the Data Subjects, the
                        decision as to whether or not Prembly may proceed must
                        be escalated for review to the Department Head.The DPO
                        shall, if there are significant concerns, either as to
                        the potential damage or distress, or the quantity of
                        Data concerned, escalate the matter to the Management.
                      </p>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "-1px",
                      }}
                    >
                      <p>5</p>
                      <p>
                        Appropriate controls will be selected and applied to
                        reduce the level of risk associated with processing Data
                        to an acceptable level.
                      </p>
                    </span>
                  </p>
                </div>
                <p>
                  <strong>
                    This Policy was approved by the Board of Directors on
                    [04-08-2022]
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cant-find-answer">
        <div className="container">
          <div className="col-md-10 col-lg-12 mx-auto py-5">
            <div className="cant-find">
              <h3>Can’t find the answer you are looking for?</h3>
              <div className="">
                <a
                  className="btn btn-sales  me-1"
                  href="https://calendly.com/premblysalesteam/45mins"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LatestF />
      <Footer />
    </div>
  );
}
