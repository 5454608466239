
export const avatardatas = [
  {
    id: 1,
    comment:
      "Your verification solution have been good. We appreciate the timely updates and resolutions we receive.",
    name: "Eno",
    small: "Product Manager -- Credpal",
  },

  {
    id: 2,
    comment:
      "Your verification solution have been good. We appreciate the timely updates and resolutions we receive.",
    name: "Kehinde",
    small: "Founder/CEO -- ETIJAR",
  },

  {
    id: 3,
    comment:
      "Your verification solution have been good. We appreciate the timely updates and resolutions we receive.",
    name: "Eno",
    small: "Software Engineer -- Bundle",
  },

  {
    id: 4,
    comment:
      "Your verification solution have been good. We appreciate the timely updates and resolutions we receive.",
    name: "Kehinde",
    small: "Founder/CEO -- ETIJAR",
  },

  {
    id: 5,
    comment:
      "Your verification solution have been good. We appreciate the timely updates and resolutions we receive.",
    name: "Eno",
    small: "Software Engineer -- Bundle",
  },

  {
    id: 6,
    comment:
      "Your verification solution have been good. We appreciate the timely updates and resolutions we receive.",
    name: "Yusuf",
    small: "Founder/CEO -- ETIJAR",
  },
];

export const identity = [
  {
    id: 1,
    comment:
      "Your verification solution have been good. We appreciate the timely updates and resolutions we receive.",
    name: "Bundle",
    small: "Product Manager -- Credpal",
  },

  {
    id: 2,
    comment:
      "Your verification solution have been good. We appreciate the timely updates and resolutions we receive.",
    name: "Lendigo",
    small: "Founder/CEO -- ETIJAR",
  },

  {
    id: 3,
    comment:
      "Your verification solution have been good. We appreciate the timely updates and resolutions we receive.",
    name: "Eno",
    small: "Software Engineer -- Bundle",
  },

  // {
  //     id: 4,
  //     comment: "Your verification solution have been good. We appreciate the timely updates and resolutions we receive.",
  //     name: 'Kehinde',
  //     small: 'Founder/CEO -- ETIJAR',
  // },

  // {
  //     id: 5,
  //     comment: "Your verification solution have been good. We appreciate the timely updates and resolutions we receive.",
  //     name: 'Eno',
  //     small: 'Software Engineer -- Bundle',
  // },

  // {
  //     id: 6,
  //     comment: "Your verification solution have been good. We appreciate the timely updates and resolutions we receive.",
  //     name: 'Yusuf',
  //     small: 'Founder/CEO -- ETIJAR',
  // }
];

export const identitys = [
  {
    id: 1,
    comment:
      "I still recommended your service to a product manager two weeks ago. I think you've got a great service. Plus, the support service is exceptional",
    name: "Credpal",
    small: "Product Manager -- Credpal",
  },

  {
    id: 2,
    comment:
      "Identitypass verification product has been great so far. My team and I enjoy the services we receive and we don’t have compliants.",
    name: "Lendigo",
    small: "Lendigo Software Engineer",
  },

  {
    id: 3,
    comment:
      "Your verification solutions have been good. We appreciate the timely updates and resolutions we receive.",
    name: "Bundle",
    small: "Software Engineer -- Bundle",
  },
];


export const identityT = [
  {
    id: 1,
    comment:
      "Identitypass verification product has been great so far. My team and I enjoy the services we receive and we don’t have compliants.",
    name: "Lendigo",
  },

  {
    id: 2,
    comment:
      "Keep up the great work, your solutions have been most innovative.",
    name: "ETIJAR",
    small: "Software Engineer -- Bundle",
  },

  {
    id: 3,
    comment:
      "Your verification solutions have been good. We appreciate the timely updates and resolutions we receive.",
    name: "Bundle",
    small: "Founder/CEO -- ETIJAR",
  },
];

export const QandA = [
  {
    id: 1,
    question:"How do Prembly products ensure compliance with industry regulations?",
    answer: " You can typically integrate Prembly products with your tech stack to provide a comprehensive security and compliance system.",
  },

  {
    id: 2,
    question:"Can I integrate Prembly’s products with my existing platform?",
    answer: "You can typically integrate Prembly products with your tech stack to provide a comprehensive security.",
  }, 

  {
    id: 3,
    question:"Can I integrate Prembly’s products with my existing platform?",
    answer: " You can typically integrate Prembly products with your tech stack to provide a comprehensive security and compliance system.",
  },

  {
    id: 4,
    question:"What kind of customer support is available for Prembly?",
    answer: " We offer 24/7 support. Our customer support channels include technical support, with email, phone, and online chat options.",
  },

  {
    id:5,
    question:'How often are your products updated to address new security vulnerabilities?',
    answer:'Our products are updated regularly, often with new security patches and updates released as needed to address newly discovered vulnerabilities.'
  },

  {
    id:6,
    question:'Can Prembly’s product be customized to meet the specific needs of my organization?',
    answer:'Yes, some of our products can be configured and customized to meet the specific needs of different organizations.'
  },

  {
    id:7,
    question:'How can I get a demo?',
    answer:'You can request a demo of the Prembly suites of products by contacting our sales team or sending a direct email to team@prembly.com'
  },


  {
    id:8,
    question:'What is the pricing structure for the product?',
    answer:'We are GDPR certified and adhere to the highest international data protection standards. '
  }


]


export const pastEevent = [
  {
    id:1,
    
  }
]

