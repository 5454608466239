import React from 'react'

export default function RadarCard({comment, name,}) {
  return (
    <div>
       <div  className='cardColor cardpinks'>
      <div className='card mb-3'>
        <div className="text-start ">
          <i class="ri-double-quotes-l ri-xl"></i>
          <div className=" text-justify feedback-title mt-3">
            <p>{comment}</p>
          </div>
          <div className="quote-left">
             <i class="ri-double-quotes-r ri-xl"></i>
          </div>
            <h4 className="pt-5">{name}</h4>
        </div>
      </div>
     </div>
    </div>
  )
}
